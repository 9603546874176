@mixin button-default {
  font-family: $font-stack-poppins;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  transition: .3s;

  &:hover {
    transition: .3s;
  }

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    background-color: $medium-gray;
    color:            $gray;
    cursor:           not-allowed;
  }
}

@mixin button-primary {
  @include button-default;
  background-color: $secondary-color;
  color: $white;
  mask: url('../../images/masks/button.svg') no-repeat top right;

  &:hover,
  &:focus {
    background-color: $secondary-color;
    color: $white;
  }
  &:hover {
    border-radius: 0;
  }
}

@mixin button-secondary {
  @include button-default;
  background-color: $light-gray;
  border-radius: 0;
  color: $primary-color;
  mask: none;

  &:hover,
  &:focus {
    background-color: $light-gray;
    color: $primary-color;
  }

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    border-radius: 0;
  }
}

@mixin primary-button-small {
  @include button-primary;
  border-bottom-right-radius: $g8-3x;
  font-size: rem-calc(13);
  padding: rem-calc(12) rem-calc(35) rem-calc(12) $g8-3x;

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    border-bottom-right-radius: $g8-3x;
  }
}

@mixin primary-button-regular {
  @include button-primary;
  border-bottom-right-radius: rem-calc(38);
  font-size: $medium-font-size;
  padding: rem-calc(20) rem-calc(35) rem-calc(20) $g8-3x;

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    border-bottom-right-radius: rem-calc(38);
  }
}

@mixin secondary-button-small {
  @include button-secondary;
  font-size: rem-calc(13);
  padding: rem-calc(12) $g8-3x;

  &:hover {
    border-bottom-right-radius: $g8-3x;
  }
}

@mixin secondary-button-regular {
  @include button-secondary;
  font-size: $medium-font-size;
  padding: rem-calc(20) $g8-4x;

  &:hover {
    border-bottom-right-radius: rem-calc(38);
  }
}

// Filter Buttons
@mixin button-filter-default {
  border: none;
  font-family: $font-stack-poppins;
  font-size: rem-calc(13);
  font-weight: 600;
  line-height: 120%;
  min-width: $g8-10x;
  text-align: center;
  transition: .3s;

  &:hover {
    transition: .3s;
    @include button-filter-corner

  }

  &.disabled, &[disabled],
  &.disabled:hover, &[disabled]:hover,
  &.disabled:focus, &[disabled]:focus {
    background-color: $medium-gray;
    color:            $gray;
    cursor:           not-allowed;
  }
}

@mixin button-filter-corner {
  &:after {
    border-color: transparent transparent $secondary-color transparent;
    border-style: solid;
    border-width: 0 0 rem-calc(12) rem-calc(12);
    bottom: 0;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    width: 0;
  }
}
