.invitation-container
  max-width: 1204px
  padding: 80px 80px 80px 80px
  gap: 40px
  border-radius: 14px 14px 14px 14px
  background-color: #FFFFFF
  border: 1px solid #FAFAFA
  margin: auto
  margin-top: 40px
  margin-bottom: 40px

  +breakpoint(small only)
    height: 100%
    margin: 10px 10px 10px 10px
    padding: 10px 10px 10px 10px

  &__warning
    padding-bottom: 20px
    padding-top: 0px
    background-color: #FAEBCC

    +breakpoint(small only)
      padding-bottom: 10px
      padding-top: 0px

  &__header
    font-size: 33px
    font-weight: 600
    line-height: 39.6px
    letter-spacing: -0.03em
    text-align: left
    color: #06243C

    +breakpoint(small only)
      font-size: $large-font-size

  &__greeting
    font-size: $medium-font-size
    font-weight: 300
    line-height: 25.6px
    letter-spacing: -0.01em
    text-align: left
    margin-top: 20px

    +breakpoint(small only)
      font-size: $small-font-size
      margin-top: 10px

  &__greeting-link
    font-size: $medium-font-size
    font-weight: 500
    line-height: 19.2px
    letter-spacing: -0.03em
    text-align: left
    color: #06243C

    +breakpoint(small only)
      font-size: $small-font-size
      margin-top: 10px
  
  &__horizontal_line
    max-width: 1044px
    border: 0.75px solid #D4D7DD
    margin-top: 20px

    +breakpoint(small only)
      margin-top: 10px

  &__message
    font-size: $medium-font-size
    font-weight: 300
    line-height: 25.6px
    letter-spacing: -0.01em
    text-align: left
    margin-top: 20px

    +breakpoint(small only)
      font-size: $small-font-size
      margin-top: 10px
