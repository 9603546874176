.why-card
  padding: $g8-2x 0
  text-align: center
  font-family: $font-stack-poppins

  &:not(:last-child)
    margin-bottom: $g8-5x

  @include breakpoint(medium)
    padding: $g8-4x
    width: 50%

    &:not(:last-child)
      margin-bottom: 0

  @include breakpoint(large)
    width: 20%

  &-wrapper
    +breakpoint(medium)
      width: 100%
      display: flex
      flex-wrap: wrap
      padding: 0px 50px 0px 50px

  img
    height: 120px
    width: 120px

  &__heading
    color: #06243C

    font-style: normal
    font-weight: 600
    line-height: 120%
    margin: rem-calc(20) 0

  &__intro
    font-family: $font-stack-poppins
    font-size: 12px
    font-weight: 400
    color: #6F7889