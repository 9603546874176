.info-show
  +breakpoint(large)
    margin-top: $g8-10x

    h3
      &:before
        +breakpoint(1360)
          left: -$g8-6x

        +breakpoint(1359 down)
          content: ''
          display: block
          height: $g8*1.3
          margin-bottom: $g8-5x
          position: relative
          width: $g8*13

  .back-link
    +breakpoint(medium down)
      margin-top: $g8-5x

  &__heading
    &--dark-pink
      +pseudo-heading-bar(map-get($accent-colors, dark-pink))

    &--pink
      +pseudo-heading-bar(map-get($accent-colors, pink))

    &--orange
      +pseudo-heading-bar(map-get($accent-colors, orange))

    &--yellow
      +pseudo-heading-bar(map-get($accent-colors, yellow))

    &--green
      +pseudo-heading-bar(map-get($accent-colors, green))

    &--light-blue
      +pseudo-heading-bar(map-get($accent-colors, light-blue))

    &--blue
      +pseudo-heading-bar(map-get($accent-colors, blue))

    &--purple
      +pseudo-heading-bar(map-get($accent-colors, purple))

  .video-container
    +breakpoint(medium)
      max-width: $g8*70

    video
      max-width: 100%
      outline: none

      +breakpoint(medium)
        max-width: $g8*70
