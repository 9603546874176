.p-programmes-enrolments,
.p-memberships,
.p-programmes-reregistrations,
.p-users-cpd__renew,
.p-events__registration,
.p-users-details
  .direct-debit-tab
    &__info
      border-left: solid rem-calc(5) $secondary-color
      padding-left: $g8-5x

    &__toggle-info
      &[data-toggle-active="false"]
        display: none

      &[data-toggle-active="true"]
        display: block

    &__payment-methods
      hr
        border-bottom: 1px solid $light-gray

      &--card
        background-color: $lightest-gray
        color: $black-3
        height: $g8*21
        padding: $g8-3x

      &--status, &--method
        .bold
          color: $gray

      &--status
        margin-bottom: $g8
        .unpaid
          color: $alert-color !important

.p-programmes-enrolments,
.p-memberships,
.p-programmes-reregistrations,
.p-users-cpd__renew
  .direct-debit-tab
    &__payment-methods
      &--card
        width: 100%

.p-users-details
  .my-details__page
    &--direct-debit
      blockquote
        +blockquote-success
        color: $black
        margin-bottom: $g8-6x
