.p-programmes__show
  .programme_modules_tab
    h1, h2, h3, h4, h5, h6
      font-family: $font-stack-poppins !important
      font-weight: normal
      margin-bottom: $g8-2x !important

    ul
      +unordered-list-orange

    ol
      +ordered-list-orange

    blockquote
      +blockquote-quote
