.membership-page
  position: relative
  font-family: $font-stack-poppins
  color: #06243C

  &__sub-heading
    font-family: $font-stack-poppins
    padding-top: 20px
    font-weight: 300
    font-size: 18px
    line-height: 28.8px
    text-align: center
    padding-bottom: 60px

    +breakpoint(small only)
      padding-top: 0px
      font-size: 12px
      padding-bottom: 10px

  &__types-heading
    font-family: $font-stack-poppins
    font-weight: 600
    font-size: 46px
    line-height: 55.2px
    text-align: center
    color: #06243C

    +breakpoint(small only)
      padding-top: 10px
      font-size: 22px

  &__flex-container
    display: flex
    flex-direction: row
    justify-content: center
    gap: 40px
    padding: 80px
    
    +breakpoint(medium only)
      flex-direction: column

    +breakpoint(small only)
      flex-direction: column
      justify-content: center
      align-items: center
      padding-top: 40px

  &__types-box
    width: 340px
    display: flex
    flex-direction: row
    justify-content: left
    gap: 40px

    +breakpoint(small only)
      flex-direction: column
      gap: 20px
      margin-left: 5px

  &__types-join-box
    width: 480px
    gap: 20px
    display: flex
    flex-direction: column
    justify-content: flex-start

    +breakpoint(small only)
      gap: 0px
      width: 100%
      margin-top: -20px

  &__types-join-box-heading
    font-family: $font-stack-poppins
    margin-top: calc(150px - (50px / 2))
    font-weight: 600
    font-size: 46px
    line-height: 55.2px
    text-align: left
    color: #06243C
    letter-spacing: -0.03em

    +breakpoint(small only)
      font-size: 22px
      margin-top: 10px

  &__types-join-box-sub-heading
    font-family: $font-stack-poppins
    margin-top: 2px
    font-weight: 500
    font-size: 18px
    line-height: 21.6px
    text-align: left

    +breakpoint(small only)
      font-size: 12px

  &__types-join-box-text
    font-family: $font-stack-poppins
    font-weight: 300
    font-size: 16px
    line-height: 25.6px
    text-align: left
    color: #06243C
    letter-spacing: -0.01em

    +breakpoint(small only)
      font-size: 12px
    
  &__types-join-button
    margin-top: 2px
    border-radius: 7px
    gap: 7px
    border: 2px solid #ED6E56
    padding: 15px 20px 20px 25px
    width: 137px
    height: 60px
    font-weight: 500
    font-size: 16px
    color: #ED6E56
    display: block
    background-image: url('../../images/icons/right-carousel-arrow-orange-small.svg')
    background-position: 82% 44%
    background-repeat: no-repeat

    +breakpoint(small only)
      padding-bottom: 10px

  &__looking-text
    font-family: $font-stack-poppins
    font-weight: 600
    font-size: 23px
    line-height: 27.3px
    text-align: center
    color: #06243C
    text-decoration: underline
    padding-top: auto
    padding-bottom: auto

    +breakpoint(small only)
      font-size: 12px

  &__looking-text:hover
    color: #ED6E56
    cursor: pointer

  &__click-text
    width: fit-content
    height: fit-content
    font-weight: 400
    font-size: 20px
    line-height: 32px
    text-align: center
    margin-bottom: 60px
    color: #06243C
    text-decoration: underline

    +breakpoint(small only)
      font-size: 10px
      margin-bottom: 10px

  &__click-text:hover
    color: #ED6E56

  &__join-button
    font-family: $font-stack-poppins
    padding: 18px 27px
    background-color: #ED6E56
    font-size: 18px
    display: inline-block
    color: #ffffff
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    width: 200px
    height: 60px

    +breakpoint(small only)
      padding: 14px 27px
      width: 100px
      height: 40px
      font-size: 12px

  &__join-button:focus
    background-color: #ED6E56
    color: white

  &__join-button:hover
    opacity: 0.75
    color: white

  &__link-container
    display: flex
    flex-direction: row
    justify-content: center

  &__tmp-component-container
    width: 90%
    margin-left: 5%
    height: 500px
    border: 1px red solid

  &__top-scroll
    font-family: $font-stack-poppins
    font-size: 20px

  &__tmp-our-members-work-with
    width: 100%
    height: 100px
    opacity: 0.6
    background-color: white

  &__tmp-types-content
    width: 400px
    height: 500px
    border: 1px red solid

  &__looking-text:hover #unhovered-right-arrow
    display: none

  &__looking-text:hover #hovered-right-arrow
    display: inline-block

  &__video-container
    width: 100%
    display: flex
    flex-direction: row
    gap: 40px
    padding: 80px
    background-color: #FAFAFA
    justify-content: center
    align-items: center

    +breakpoint(small only)
      padding: 10px 5% 10px
      flex-direction: column
      gap: inherit

  &__video
    width: 570px
    flex-shrink: 0

    +breakpoint(small only)
      width: 100%

  &__video-text
    display: flex
    flex-direction: column
    gap: 20px
    padding: 40px
    width: 570px

    +breakpoint(small only)
      width: 100%
    
  &__video-text-header-text
    font-family: $font-stack-poppins
    font-size: 46px
    font-weight: 600
    color: #06243C
    line-height: 55.2px
    letter-spacing: -0.03em

    +breakpoint(small only)
      font-size: 44px

  &__header-font-color
    color: #06243C

#unhovered-right-arrow
  display: inline-block
  padding-left: 10px

#hovered-right-arrow
  display: none
  padding-left: 10px

#unhovered-arrow
  display: block

#hovered-arrow
  display: none
