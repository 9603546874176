@use 'sass:math'

$loader-size: 200px
$loader-part-width: 12px
$loader-part-height: 24px
$loader-part-distance: 105px
$loader-animation-duration: 1s

#loader-anim
  width: $loader-size
  height: $loader-size
  background: white
  display: inline-block
  position: fixed
  left: 50%
  margin-left: math.div(-$loader-size, 2)
  top: 50%
  margin-top: math.div(-$loader-size, 2)
  z-index: 2000

  div
    @for $i from 1 through 12
      &:nth-child(#{$i})
        transform: translate3d(math.div($loader-size, 2) - math.div($loader-part-width, 2), math.div($loader-size, 2) - math.div($loader-part-distance, 2), 0) rotate(($i - 1) * 30deg)
        position: relative
        width: $loader-part-width
        height: $loader-part-distance
        position: absolute
    
        &:before
          content: ''
          width: $loader-part-width
          height: $loader-part-height
          background: #EE7560
          border-radius: 3px
          display: block
          opacity: 1
          animation: loader-anim $loader-animation-duration linear infinite
          animation-delay: -($loader-animation-duration - math.div($loader-animation-duration, 12) * $i)
          
@keyframes loader-anim
  from
    opacity: 1
  to
    opacity: 0
