/// Text
.bold { font-weight: 600; }

.border { border: 2px solid red }

.weight-normal { font-weight: normal !important; }

.weight-300 { font-weight: 300; }

.secondary-text { color: color(secondary) !important; }

.nowrap { white-space: nowrap; }

.uppercase { text-transform: uppercase; }

.underline { text-decoration: underline; }

.letter-spacing { letter-spacing: $letter-spacing; }

.hidden { visibility: hidden !important; }

.display-none { display: none !important; }

.display-block { display: block !important; }

.display-inline { display: inline !important; }

.opacity-2 { opacity: 0.2; }

.opacity-5 { opacity: 0.5; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Spacing
.g8-1x-padding-top { padding-top: $g8; }

.g8-1x-padding-bottom { padding-bottom: $g8; }

.g8-1x-padding-vertical {
  @extend .g8-1x-padding-top;
  @extend .g8-1x-padding-bottom;
}

.g8-2x-padding-top { padding-top: $g8-2x; }

.g8-2x-padding-bottom { padding-bottom: $g8-2x; }

.g8-2x-padding-vertical {
  @extend .g8-2x-padding-top;
  @extend .g8-2x-padding-bottom;
}

.g8-1x-padding-right { padding-right: $g8; }

.g8-1x-padding-left { padding-left: $g8; }

.g8-3x-padding { padding: $g8-3x; }

.g8-1x-margin-bottom { margin-bottom: $g8 }

.g8-2x-margin-top { margin-top: $g8-2x; }

.g8-2x-margin-bottom { margin-bottom: $g8-2x; }

.g8-3x-margin { margin: $g8-3x; }

.g8-3x-margin-bottom { margin-bottom: $g8-3x; }

.g8-4x-margin-bottom { margin-bottom: $g8-4x; }

.g8-4x-margin-top { margin-top: $g8-4x; }

.g8-4x-margin-vertical {
  @extend .g8-4x-margin-top;
  @extend .g8-4x-margin-bottom;
}

/// Colors
@each $color, $hex-value in $foundation-palette {
  .color--#{$color} {
    color: $hex-value;
  }
  .color--bg-#{$color} {
    background-color: $hex-value;
  }
}

.flex-content-right {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.collapse-margin { margin: 0px; }

.collapse-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.disabled{
  &:not(.excluded-disable) {
    cursor: not-allowed !important;
    * {
      cursor: not-allowed !important;
    }
  }
}

.fluid { width: 100% !important; }

/// Remove focus outline when using a mouse
.-fm,
.-fm:focus {
  outline:                     none !important;
  user-select:                 none;
  -webkit-tap-highlight-color: transparent;
  // -webkit-touch-callout:       none;
  // -ms-touch-action:            pan-y;
  // touch-action:                pan-y;
}

/// Prevent images from being downloaded
.image-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

// Titles

.title {
  &__rule {
    background: $white;
    height: rem-calc(2);
    position: relative;

    &.secondary {
      background: $light-gray;
    }
  }
}
