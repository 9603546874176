// Foundation Sass utilities
@import '~foundation-sites/scss/util/util';

/// Color

$foundation-palette: (
  primary:          #003977,
  secondary:        #FF8D00,

  success:          #88E186,
  warning:          #FF8D00,
  alert:            #FF5E5E,
  info:             #0089CF,

  light-primary:    #6688AD,
  lightest-primary: #CCD7E4,

  dark-blue:        #0089CF,
  light-blue:       #99D0EC,
  lightest-blue:    #CCE7F5,
  pale-blue:        #EAF8FB,

  light-secondary:  #FF800D,
  light-orange:     #FFD199,
  lightest-orange:  #FFE8CC,
  pale-orange:      #FFF5EA
);

$iob-color-palette: (
  blue:            #3E65BA,
  gold:            #E8DDC3,
  green:           #079775,
  grey:            #D4D7DD,
  navy:            #06243C,
  orange:          #ED6E56,
  peach:           #FFD9C0,
  strawberry:      #F27079,
  black:           #06243C,
  white:           #FFFFFF
);

$primary-color:   get-color(primary);
$secondary-color: get-color(secondary);

$light-primary:   get-color(light-primary);
$lightest-primary:get-color(lightest-primary);

$dark-blue:       get-color(dark-blue);
$light-blue:      get-color(light-blue);
$lightest-blue:   get-color(lightest-blue);
$pale-blue:       get-color(pale-blue);

$light-secondary: get-color(light-secondary);
$light-orange:    get-color(light-orange);
$lightest-orange: get-color(lightest-orange);
$pale-orange:     get-color(pale-orange);

$black:           #000;
$black-2:         #0E1828;
$black-3:         #07243D;
$white:           #FFF;
$gray:            #6A7C8B;

$darkest-gray:    #9EAABE;
$dark-gray:       #CDD3D8;
$medium-gray:     #D6D8DA;
$light-gray:      #EFEFF0;
$lightest-gray:   #F7F7F8;

$success-color:   get-color(success);
$warning-color:   get-color(warning);
$alert-color:     get-color(alert);
$info-color:      get-color(info);

$accent-colors: (
  pale-red:         #FFECE9,
  dark-pink:        #FF80A6,
  pink:             #F69A9A,
  orange:           #F6C07E,
  yellow:           #FFCF70,
  pale-green:       #EBFFEE,
  green:            #19C89E,
  light-blue:       #0AB7DE,
  blue:             #33A1D9,
  purple:           #A989D9
);

/// Layout

$g8: bl(1bu);

$g8-spaces: (
  0x:   0,
  half: $g8 * 0.5,
  qtr:  $g8 * 0.25,
  1x:   $g8,
  2x:   $g8 * 2,
  3x:   $g8 * 3,
  4x:   $g8 * 4,
  5x:   $g8 * 5,
  6x:   $g8 * 6,
  7x:   $g8 * 7,
  8x:   $g8 * 8,
  9x:   $g8 * 9,
  10x:  $g8 * 10
);

$g8-half: map-get($g8-spaces, half);
$g8-qtr:  map-get($g8-spaces, qtr);
$g8-2x:   map-get($g8-spaces, 2x);
$g8-3x:   map-get($g8-spaces, 3x);
$g8-4x:   map-get($g8-spaces, 4x);
$g8-5x:   map-get($g8-spaces, 5x);
$g8-6x:   map-get($g8-spaces, 6x);
$g8-7x:   map-get($g8-spaces, 7x);
$g8-8x:   map-get($g8-spaces, 8x);
$g8-9x:   map-get($g8-spaces, 9x);
$g8-10x:  map-get($g8-spaces, 10x);

$navigation-height: $g8-10x;

/// Fonts

$font-sizes: (
  mini:     rem-calc(8),
  tiny:     rem-calc(12),
  small:    rem-calc(14),
  medium:   rem-calc(16),
  default:  rem-calc(18),
  large:    rem-calc(20),
  xlarge:   rem-calc(28),
  xxlarge:  rem-calc(48),
  xxxlarge: rem-calc(60)
);

$mini-font-size:      map-get($font-sizes, mini);     // 8px
$tiny-font-size:      map-get($font-sizes, tiny);     // 12px
$small-font-size:     map-get($font-sizes, small);    // 14px
$medium-font-size:    map-get($font-sizes, medium);   // 16px
$default-font-size:   map-get($font-sizes, default);  // 18px
$large-font-size:     map-get($font-sizes, large);    // 20px
$xlarge-font-size:    map-get($font-sizes, xlarge);   // 28px
$xxlarge-font-size:   map-get($font-sizes, xxlarge);  // 48px
$xxxlarge-font-size:  map-get($font-sizes, xxxlarge); // 60px

/// Header

$header-styles: (
  small: (
    'h1': (font-size: 42px),                // 42px
    'h2': (font-size: 36px),                // 36px
    'h3': (font-size: $xlarge-font-size),   // 28px
    'h4': (font-size: $large-font-size),    // 20px
    'h5': (font-size: $default-font-size),  // 18px
    'h6': (font-size: $medium-font-size),   // 16px
  ),
  medium: (
    'h1': (font-size: $xxxlarge-font-size), // 60px
    'h2': (font-size: $xxlarge-font-size),  // 48px
    'h3': (font-size: $xlarge-font-size),   // 28px
    'h4': (font-size: $large-font-size),    // 20px
    'h5': (font-size: $default-font-size),  // 18px
    'h6': (font-size: $medium-font-size),   // 16px
  ),
);

$font-stack-poppins: (
  "Poppins",
  sans-serif
);

$font-stack-helvetica: (
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif,
);

$font-stack-consolas: (
  "Consolas",
  "monaco",
  monospace,
);

$primary-font:    $font-stack-poppins, $font-stack-helvetica;
$mono-font:       $font-stack-consolas;

// Global

$body-background:   #ffffff;
$body-font-color:   $black;
$body-font-family:  $primary-font;
$global-lineheight: 1.5;

$breakpoints: (
  small:      0,
  medium:     640px,
  nav-header: 925px,
  large:      1024px,
  xlarge:     1200px,
  xxlarge:    1440px,
  xxxlarge:   1681px
);

$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

/// Grid

$global-width: rem-calc(1280);
$grid-container: $global-width;

$grid-column-gutter: (
  small: 10px,
  medium: 10px,
  large: 10px
);

$grid-margin-gutters: (
  small: $g8-2x,
  medium: $g8-2x,
  large: $g8-2x
);

$grid-container-gutters: (
  small: $g8-4x,
  medium: $g8-6x,
  large: $g8*20,
  xxxlarge: 0
);

$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: $grid-container-gutters;
$grid-container-max: $global-width;

/// Prototype Separator
$global-prototype-breakpoints: true;
$prototype-box-shadow: rem-calc(2) rem-calc(2) rem-calc(4) lighten($medium-gray, 7%);
$prototype-separator-background: $light-gray;
$prototype-separator-height: rem-calc(6);
$prototype-separator-width: 3rem;

/// Inputs
$input-background: $light-gray;
$input-border: none;
$input-shadow: none;
$input-height: auto;
$input-padding: rem-calc(18) $g8-5x rem-calc(18) $g8-3x;

/// Cards
$card-box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1);

/// Letter Spacing
$letter-spacing: 0.2em;
