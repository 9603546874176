@import 'pages/about'
@import 'pages/accreditation'
@import 'pages/cfid'
@import 'pages/confirmations'
@import 'pages/generic'
@import 'pages/home'
@import 'pages/memberships'
@import 'pages/programme_general'
@import 'pages/programmes'
@import 'pages/glossary/glossary'
@import 'pages/search'
@import 'pages/sign-in'
@import 'pages/sign-up'
@import 'pages/sign-out'

@import 'pages/strategy'
@import 'pages/strategy/banner'
@import 'pages/strategy/ceo'
@import 'pages/strategy/pillars'
@import 'pages/strategy/values'
@import 'pages/strategy/video'

@import 'pages/enrolment/details-tab'
@import 'pages/enrolment/direct-debit-tab'
@import 'pages/enrolment/modules-tab'
@import 'pages/enrolment/payments-tab'
@import 'pages/enrolment/ucd-tab'

@import 'pages/events/events'
@import 'pages/events/events_off_canvas'
@import 'pages/events/events_show'
@import 'pages/events/events_tabs'
@import 'pages/events/events_gallery'

@import 'pages/info/membership'
@import 'pages/info/info_show'
@import 'pages/info/info_tabs'
@import 'pages/info/info'
@import 'pages/info/popular_questions_off_canvas'
@import 'pages/info/popular_questions'
@import 'pages/info/member_testimonial'

@import 'pages/news/news_index'
@import 'pages/news/news_show'

@import 'pages/programme/reregistrations'
@import 'pages/programme/apply'
@import 'pages/programme/details'
@import 'pages/programme/enrol'
@import 'pages/programme/modules'
@import 'pages/programme/overview'
@import 'pages/programme/programme'

@import 'pages/programme_areas/programme_areas'

@import 'pages/users/apa'
@import 'pages/users/correspondence'
@import 'pages/users/dashboard_empty_states'
@import 'pages/users/modules/module'
@import 'pages/users/modules/continuous_assessment'
@import 'pages/users/my-institute'
@import 'pages/users/my-details/banner'
@import 'pages/users/my-details/navigation'
@import 'pages/users/my-details/page'
@import 'pages/users/navigation'
@import 'pages/users/record_cpd_event'
@import 'pages/users/shopping'
@import 'pages/users/user-dashboard'
@import 'pages/users/user-ecpd'
@import 'pages/users/user-modules'
@import 'pages/users/_cpd_show_page'
@import 'pages/users/cpd_landing_page'
@import 'pages/users/_fee_reminder'
@import 'pages/users/_cpd-carousel'
@import 'pages/users/_cpd-gray-bar'
@import 'pages/users/_cpd-certificates'
@import 'pages/users/_my-ecpd'
@import 'pages/users/_cpd-my-hours'
@import 'pages/users/cpd_events'
@import 'pages/users/_hours-breakdown'
@import 'pages/users/_cpd_annual_returns'

@import 'pages/common/back_to_top'
@import 'pages/common/mission'
@import 'pages/common/search_message'

@import 'pages/designations'
@import 'pages/designations/invitation_section'

.page-container
  min-height: 100vh

.page-wrapper
  overflow-x: hidden
  position: relative
  //top: $g8-10x
