.module
  &__banner
    height: $g8-5x
    width: 100%

    +breakpoint(medium)
      margin-top: $g8*7.5

  &__title
    background: $gray

    .title
      align-items: center
      display: flex
      justify-content: flex-start
      padding: $g8-2x 0

      &__text
        color: $white

      &__rule

        +breakpoint(large)
          background: $white
          height: rem-calc(1)
          position: relative

  .back-button
    display: inline-block

    a
      color: $black-3
      margin-bottom: 0

  .tabs, .tabs-content
    border: none

  .tabs
    border: none
    height: $g8-8x
    margin-top: $g8-7x
    overflow-x: auto
    overflow-y: hidden
    white-space: nowrap

    +breakpoint(small only)
      background-color: $lightest-gray
      left: -$g8
      position: relative
      width: 100vw

    .tabs-title
      display: inline-block
      float: none
      outline: none

      +breakpoint(medium)
        padding: 0 $g8-7x 0 0

      +breakpoint(small only)
        padding: 0 5% 0 5%

      &.is-active a
        border-top: rem-calc(3) solid $secondary-color
        opacity: 1

      a
        background: none
        border-top: rem-calc(3) solid transparent
        color: $black-3
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-weight: bold
        opacity: 0.25
        outline: none
        padding: $g8-3x 0

        &:hover
          text-decoration: none

  .tabs-content
    .tabs-panel
      padding: 1rem 0

    .scroller__title::after
      border-top: 1px solid $light-gray
