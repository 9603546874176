.search-form
  .input__clear
    display: none
    margin-top: -5px

  &__input
    background: $white
    height: 50px
    opacity: 0
    overflow: hidden
    border-radius: 10px
    border: 1.5px solid #F2F3F5
    box-sizing: border-box
    +transition(all 0.2s ease-in-out)

    .input-container
      margin-bottom: 0

    +breakpoint(medium down)
      opacity: 1
      width: 100%
      position: relative

    label
      pointer-events: none

    input
      max-width: $g8*125
      width: 100%
      padding-right: $g8*9

  &__form
    display: flex
    justify-content: center
    align-items: center

  +breakpoint(medium down)
    width: 100%

  &[data-state="active"]
    .input__clear
      display: block

    +breakpoint(medium down)
      .search-form__icon
        display: none

    .search-form__input
      width: 100%
      opacity: 1
      +breakpoint(medium down)
        width: 100%
      label
        left: 5% !important
