// Standard HTML video tags
.video-container
  video
    max-width: 100%
    outline: none

// Embedded with iframe
.video-embed-container
  //height: 380px
  overflow: hidden
  position: relative
  width: 100%

  +breakpoint(small only)
    width: 100%


  &__vimeo
    height: 380px
    width: 580px
    overflow: hidden
    position: relative

  & iframe,
    object,
    embed
      //height: auto
      //right: 20px
      //position: absolute
      background-color: unset
      width: 560px
      +breakpoint(medium down)
        width: 100%
