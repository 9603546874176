.glossary
  background-color: $lightest-gray
  height: 100%

  &__filter-by
    &--container
      display: flex
      padding: 10px 20px 10px 20px
      margin: -15px 120px 30px 120px
      border-radius: 14px 14px 14px 14px
      background-color: $white
      width: auto
      +breakpoint(medium only)
        margin: -20px 30px 30px 30px
      +breakpoint(small only)
        margin: -10px 20px 30px 20px

    &__tag
      display: flex
      justify-content: center
      align-items: center
      padding: 10px 20px
      border: 2px solid map-get($iob-color-palette, navy)
      background-color: map-get($iob-color-palette, navy)
      border-radius: 100px 100px 100px 100px
      color: $white
      font-family: $font-stack-poppins
      font-size: 12px
      +breakpoint(medium only)
        font-size: 10px
        padding: 5px 10px
      +breakpoint(small only)
        font-size: 10px
        padding: 5px 10px
      &:focus, &:hover
        background-color: map-get($iob-color-palette, navy)
        color: $white

  &__loader
    width: auto

  &__search-container
    display: flex
    justify-content: center
    align-items: center
    +breakpoint(medium only)
      margin-bottom: 40px
    +breakpoint(small only)
      margin-bottom: 20px

  &__refine-by
    &--loader
      @extend .glossary__loader
      margin: 0px 80px 0px 80px
      background-color: $lightest-gray
      border-color: $lightest-gray
      +breakpoint(small only)
        margin: 0px 20px 0px 20px

  &__term
    &-details
      display: flex
      flex-direction: column
      margin: 0px 120px 40px 120px
      box-shadow: 0px 4px 20px 0px rgba(111, 120, 137, 0.10)
      padding: 0px 30px
      background-color: $white
      border-radius: 14px
      width: auto
      +breakpoint(medium only)
        margin: 0px 30px 40px 30px
      +breakpoint(small only)
        margin: 0px 20px 40px 20px

      & > :last-child
        border: none !important

      &--loader
        @extend .glossary__loader
        display: none
        border-radius: 14px
        margin: 0px 120px 40px 120px
        box-shadow: 0px 4px 20px 0px rgba(111, 120, 137, 0.10)
        +breakpoint(small only)
          margin: 0px 20px 40px 20px

  &__quick_links

    &--modal
      padding: 0px 40px 20px 40px
      border-radius: 14px
      background-color: $white
      overflow-y: scroll
      +breakpoint(large)
        height: 60vh
      +breakpoint(medium)
        height: 85vh

    &--loader
      @extend .glossary__loader
      border: none

    &--header
      top: 0
      display: flex
      position: -webkit-sticky
      position: sticky
      padding-top: 20px
      background-color: $white
      justify-content: space-between
      border-bottom: 1px solid map-get($iob-color-palette, grey)
      padding-bottom: 5px

    &--heading
      font-family: $font-stack-poppins
      color: map-get($iob-color-palette, navy)
      font-size: 24px
      font-style: normal
      font-weight: 600
      line-height: 120%
      letter-spacing: -0.72px

    &--exit-button
      color: map-get($iob-color-palette, grey)
      cursor: pointer
      font-family: $font-stack-poppins
      font-size: 32px
      font-weight: 300
      height: $g8-3x
      outline: none
      &:hover, &:focus
        color: map-get($iob-color-palette, orange)

      +breakpoint(medium down)
        line-height: $g8-4x
