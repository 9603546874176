.cpd-my-hours
  width: 100%

  &__flex-wrapper
    display: flex
    flex-direction: column
    gap: 60px
    justify-content: space-between
    padding: 0px 180px

    +breakpoint(small only)
      padding: 0px 15px
      gap: 30px

  &__header
    display: flex
    flex-direction: row
    gap: 30px
    align-items: stretch

    +breakpoint(small only)
      flex-direction: column

  &__total-hours-box
    display: flex
    flex-direction: column
    gap: 10px

  &__requirements-body-top
    display: flex
    flex-direction: column
    justify-content: center

  &__requirements-body-top-bar
    background-image: url('./../../images/unit_card_details/caps.svg')
    height: 12px
    background-size: contain
    background-repeat: no-repeat

  &__hours
    display: flex
    flex-direction: column
    gap: 60px
    justify-content: space-between

    +breakpoint(small only)
      gap: 30px

  &__hours-box-first
    display: flex
    flex-direction: column
    gap: 10px
    order: 1

    +breakpoint(small only)
      order: 2
      gap: 8px

  &__hours-box-second
    display: flex
    flex-direction: column
    gap: 10px
    order: 2

    +breakpoint(small only)
      order: 3

  &__category-hours-header
    font-weight: 600
    font-size: 33px
    letter-spacing: -0.03em
    line-height: 120%
    color: #06243C

    +breakpoint(small only)
      font-size: 16px

  &__category-hours-text
    font-weight: 300
    font-size: 16px
    line-height: 160%
    letter-spacing: -0.01em
    color: #6F7889

    +breakpoint(small only)
      font-size: 10px

  &__category-hours-complete
    font-weight: 600
    font-size: 16px
    line-height: 120%
    letter-spacing: 0.2em
    text-transform: uppercase
    color: #6F7889

    +breakpoint(small only)
      font-size: 10px

  &__requirements-header-body
    font-size: 16px
    font-weight: 300
    color: #6F7889
    line-height: 160%
    letter-spacing: -0.01em

    +breakpoint(small only)
      font-size: 10px

  &__requirements-header
    font-size: 33px
    font-weight: 600
    letter-spacing: -0.03em
    line-height: 120%
    color: #06243C

    +breakpoint(small only)
      font-size: 18px

  &__hours-total
    font-size: 33px
    font-weight: 600
    letter-spacing: -0.03em
    color: #06243C

    +breakpoint(small only)
      font-size: 16px

  &__year-selector
    flex: 1
    display: flex
    flex-direction: row
    gap: 30px
    justify-content: flex-end

    +breakpoint(small only)
      gap: 10px

  &__year-selector-arrow
    background-image: url('./../../images/icons/left-arrow.svg')
    width: 42px
    height: 64px
    background-size: contain
    background-repeat: no-repeat

    +breakpoint(small only)
      width: 30px
      height: 30px

  &__year-selector-arrow-right
    background-image: url('./../../images/icons/cpd-right-arrow.svg')
    width: 42px
    height: 64px
    background-size: contain
    background-repeat: no-repeat
    display: none

    +breakpoint(small only)
      width: 30px
      height: 30px

  &__year-selector-arrow-right:hover
    cursor: pointer

  &__year-selector-arrow:hover
    cursor: pointer

  &__year-selector-year
    background-color: #06243C
    border-radius: 100px
    width: 122px
    height: 64px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    +breakpoint(small only)
      width: 79px
      height: 29px
      padding: 5px 25px

  &__year-selector-year-text
    color: #FFFFFF

    +breakpoint(small only)
      font-size: 12px
      font-weight: 500
      line-height: 160%

  &__hours-complete
    font-size: 16px
    font-weight: 600
    letter-spacing: 0.2em
    line-height: 120%
    color: #6F7889
    text-transform: uppercase

    +breakpoint(small only)
      font-size: 10px


  &__hours-text
    font-size: 16px
    font-weight: 300
    letter-spacing: -0.01em
    line-height: 25.6px
    color: #6F7889

    +breakpoint(small only)
      font-size: 10px

  &__shortfall-returned-hours
    display: flex
    flex-direction: row
    gap: 20px
    order: 3

    +breakpoint(small only)
      order: 1
      flex-direction: column
      gap: 10px

  &__requirements-header-box
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0px 0px 20px
    gap: 20px

    +breakpoint(small only)
      gap: 10px

  &__requirements-body-box
    padding: 40px
    justify-content: center

    +breakpoint(small only)
      padding: 10px

  &__requirements-body
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    border-radius: 20px
    min-width: 570px
    background-color: #ffffff

    +breakpoint(small only)
      min-width: 260px

  &__requirements-body-header
    display: flex
    justify-content: space-between

  &__requirements-body-header-inner
    display: flex
    flex-direction: row
    gap: 100px

    +breakpoint(small only)
      gap: 22px

  &__total-hours-bar
    width: 100%
    border-radius: 40px
    border: 2px solid #C4C4C4
    background-color: #F2F3F5
    height: 49.19px

  &__total-hours-bar-progress
    width: 0px
    height: 100%
    border-radius: 40px 0px 0px 40px
    background-color: #ED6E56

  &__category-hours-bar
    width: 100%
    border-radius: 40px
    border: 2px solid #C4C4C4
    background-color: #F2F3F5
    height: 49.19px

  &__category-hours-bar-progress
    width: 0px
    height: 100%
    border-radius: 40px 0px 0px 40px
    background-color: #06243C

  &__requirements-body-header-content-left
    border-bottom: #ED6E56 solid 4px

  &__requirements-body-header-content-right
    border-bottom: #ED6E56 solid 4px

  &__requirements-body-header-text
    height: 79px
    font-weight: 500
    font-size: 20px
    line-height: 160%

    +breakpoint(small only)
      font-size: 10px
      height: 36px

  &__requirements-dropdown-row
    display: flex
    flex-direction: row
    padding: 10px

  &__requirements-dropdown-row-grey
    display: flex
    flex-direction: row
    padding: 10px
    background-color: #E9EBEF

  &__requirements-dropdown-row-bottom
    display: flex
    flex-direction: row
    padding-bottom: 30px

  &__requirements-dropdown-left-bold
    flex-grow: 1
    font-weight: 500
    font-size: 16px
    line-height: 16px
    letter-spacing: -0.01em

    +breakpoint(small only)
      font-size: 8px

  &__requirements-dropdown-left
    flex-grow: 1
    font-weight: 300
    font-size: 16px
    line-height: 16px
    letter-spacing: -0.01em

    +breakpoint(small only)
      font-size: 8px

  &__requirements-dropdown-right
    font-size: 16px
    font-weight: 300
    line-height: 16px
    letter-spacing: -0.01em

    +breakpoint(small only)
      font-size: 8px

  &__requirements-dropdown-container
    display: flex
    flex-direction: column
    gap: 20px
    display: none

  &__requirements-body-header-left
    width: 150px
    min-width: 150px

    +breakpoint(small only)
      width: 80px
      min-width: 80px

  &__requirements-body-header-right
    flex-grow: 0.3

  &__requirements-body-content-box
    display: flex
    flex-direction: row
    gap: 10px
    justify-content: space-between
    height: 130px
    align-items: center

    +breakpoint(small only)
      height: 75px

  &__my-hours
    font-size: 58px
    font-weight: 600
    flex: 1
    line-height: 69.6px
    letter-spacing: -0.03em
    color: #06243C

    +breakpoint(small only)
      font-size: 36px
      line-height: 120%

  &__requirements-body-content-name
    font-size: 23px
    font-weight: 600
    line-height: 120%
    width: 200px
    min-width: 200px

    +breakpoint(small only)
      width: 105px
      min-width: 80px
      font-size: 10px

  &__requirements-body-content-name-tick
    font-size: 23px
    font-weight: 600
    line-height: 120%
    flex-grow: 0.04

    +breakpoint(small only)
      flex-grow: 0.05
      font-size: 10px

  &__requirements-body-content-right-flex
    display: flex
    flex-direction: row

  &__requirements-body-content-middle
    display: flex
    justify-content: space-between
    gap: 145px
    position: relative

    +breakpoint(small only)
      gap: 40px !important

  &__requirements-body-content-middle-tick
    display: flex
    justify-content: space-between
    gap: 100px
    flex-grow: 0.03

    +breakpoint(small only)
      gap: 35px
      flex-grow: 0

  &__requirements-body-content-expander
    background-image: url('./../../images/icons/down-arrow.svg')
    justify-content: flex-end
    width: 26.67px
    height: 13.33px
    background-size: contain
    background-repeat: no-repeat
    flex-shrink: 0
    right: 10px
    position: relative

    +breakpoint(small only)
      background-image: url('./../../images/icons/small-down-arrow.svg')
      width: 10px
      height: 5px

  &__requirements-body-content-expander-wrapper
    display: flex
    flex-direction: row
    justify-content: flex-end
    flex-shrink: 1
    flex-grow: 0.3

  &__requirements-body-top-bar-bottom
    background-image: url('./../../images/icons/top-bit.svg')
    background-size: contain
    background-repeat: no-repeat
    flex-grow: 1

  &__requirements-body-top-bar-bottom-box
    display: flex
    flex-direction: column
    align-items: center
    padding: 0px 79px
    gap: 10px
    height: 10px

  &__requirements-body-content-middle-left
    justify-content: flex-start
    font-size: 23px
    font-weight: 600

    +breakpoint(small only)
      font-size: 10px

  &__requirements-body-content-middle-right
    justify-content: flex-end
    font-size: 18px
    color: #FF5E5E

    +breakpoint(small only)
      font-size: 6px

  &__requirements-body-content-middle-right-tick
    background-image: url('./../../images/icons/green-tick.svg')
    width: 52px
    height: 20px
    background-position: center
    background-repeat: no-repeat

    +breakpoint(small only)
      background-image: url('./../../images/icons/small-green-tick.svg')
      width: 34px
      height: 11px
      background-size: contain

  &__requirements

    +breakpoint(small only)
      padding-top: 10px

  &__requirements-body-content-line
    border-bottom: 1px solid #ADB5C2

    +breakpoint(small only)
      border-bottom: 0.75px solid #ADB5C2

  &__requirements-body-content-line:hover
    cursor: pointer

  &__requirements-body-content-line:last-child
    border-bottom: none

  &__carousel
    display: flex
    flex-direction: row-reverse
    justify-content: center
    align-items: center
    gap: 15px

  &__shortfall-returned-hours-warning-flex
    flex-grow: 1
    background-color: #FFD9C0
    border-radius: 40px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 10px 20px

    +breakpoint(small only)
      padding: 5px 20px

  &__shortfall-returned-hours-warning
    font-weight: 500
    font-size: 14px
    line-height: 160%
    color: #06243C

    +breakpoint(small only)
      font-size: 10px

  &__shortfall-returned-hours-hours
    background-color: #FFD9C0
    border-radius: 40px
    flex-grow: 0
    display: flex
    flex-direction: row
    padding: 10px 40px
    gap: 30px
    font-size: 14px

    +breakpoint(small only)
      padding: 5px 20px

  &__shortfall-returned-hours-hours-left
    font-weight: 600
    font-size: 14px
    line-height: 140%
    color: #06243C

    +breakpoint(small only)
      font-size: 10px
      flex-grow: 0

  &__shortfall-returned-hours-hours-right
    font-weight: 600
    font-size: 16px
    line-height: 120%
    color: #06243C

    +breakpoint(small only)
      font-size: 10px
      flex-grow: 1
      text-align: right

  &__top-cap-bar
    background-image: url('./../../images/unit_card_details/caps.svg')
    height: 12px
    background-repeat: no-repeat
    border-top-right-radius: 40px
    background-size: cover

  &__top-cap-bottom
    background-image: url('./../../images/icons/top-bit.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 11px
    margin-left: 43.3%
    margin-right: 43%
    position: relative
    top: -1px

  &__carousel-dots
    width: 8px
    height: 8px
    border-radius: 100px
    opacity: 0.25
    background-color: #06243C
