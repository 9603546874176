.scroller
  &--light
    // background-color: $white
    .scroller__arrows
      // background-color: $white
      img
        background-color: $white

  &--gray
    background-color: $lightest-gray
    .scroller__arrows
      background-color: $lightest-gray
      img
        background-color: $lightest-gray

  &--dark
    background-color: $black-3

    .scroller__title
      color: $white
      &::after
        border-top: 1px solid $white

    .scroller__arrows
      background-color: $black-3
      img
        background-color: $black-3

      &--left
        background-image: url('./../../images/icons/navigation-dark-left.svg')

        +breakpoint(xlarge)
          &:hover:not(.disabled)
            background-image: url('./../../images/icons/navigation-dark-hover-left.svg')

        &.disabled
          background-image: url('./../../images/icons/navigation-dark-disabled-left.svg')

      &--right
        background-image: url('./../../images/icons/navigation-dark-right.svg')
        margin-left: $g8-half

        +breakpoint(xlarge)
          &:hover
            background-image: url('./../../images/icons/navigation-dark-hover-right.svg')

        &.disabled
          background-image: url('./../../images/icons/navigation-dark-disabled-right.svg')
