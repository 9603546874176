.strategy
  &__page-flex
    display: flex
    flex-direction: column
    padding-top: 80px

  &__hovered-button
    display: none

  &__unhovered-button
    display: block
