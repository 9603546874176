.standard-carousel

  .swiper-pagination
    align-items: center
    display: flex
    height: rem-calc(20)
    justify-content: center
    padding-right: rem-calc(10)
    position: absolute
    top: 0.25rem

  span.swiper-pagination-bullet
    background-color: transparent
    border: 2px solid $primary-color
    height: rem-calc(12)
    width: rem-calc(12)

    &:not(:last-child)
      margin-right: rem-calc(18)

    &.swiper-pagination-bullet
      background-color: transparent
      opacity: 1

    &.swiper-pagination-bullet-active
      background-color: $primary-color
