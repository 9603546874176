.mobile-footer
  z-index: 100

  +breakpoint(small only)
    font-size: $tiny-font-size

  +breakpoint(medium only)
    font-size: $medium-font-size

  +breakpoint(large)
    display: none

  +breakpoint(medium down)
    background-color: $black-3
    bottom: 0
    height: $g8-8x
    position: fixed
    width: 100%

    &__logo
      +background-image(no-repeat, ($g8-6x $g8-6x), center, center)
      max-width: rem-calc(60)
      position: relative
      top: rem-calc(-13)

      +breakpoint(medium)
        max-width: $g8*13

      &--link
        bottom: rem-calc(-13)
        position: absolute
        width: 100%

    &__links
      +background-image(no-repeat, auto, center, center)

    &__text
      height: $g8-8x
      padding-top: $g8-3x

    &__icons
      padding-bottom: $g8-7x !important

    a
      color: $white
      padding-bottom: $g8-2x

      &.active
        border-bottom: rem-calc(5) solid $secondary-color

      &:not(.active)
        border-bottom: rem-calc(5) solid transparent

    .unread-messages
      position: relative

      &:after
        background: $secondary-color
        border-radius: 100%
        content: ''
        position: absolute
        display: inline-block
        height: rem-calc(10)
        top: $g8
        left: 55%
        width: rem-calc(10)

  &__drop-down-nav
    background: $gray
    bottom: 100%
    flex-direction: column
    justify-content: center
    list-style-type: none
    margin-bottom: 0
    margin-left: 0
    opacity: 0
    padding: $g8-4x $g8-2x
    position: absolute
    right: $g8-2x
    transition: .3s
    visibility: hidden
    width: $g8*25
    z-index: -1

    +breakpoint(374 down)
      width: $g8*20

    +breakpoint(420)
      right: $g8-4x

    +breakpoint(530)
      right: 15%

    +breakpoint(medium)
      left: 55%
      width: $g8*25

    li
      text-align: center
      &:not(:last-child)
        padding-bottom: $g8-2x

    a
      border-bottom: none
      color: $white
      padding-top: 0

      &:focus,
      &:active,
      &:hover
        color: $secondary-color

.mobile-footer__drop-down:active .mobile-footer__drop-down-nav,
.mobile-footer__drop-down:hover .mobile-footer__drop-down-nav
  opacity: 1
  transition: .3s
  visibility: visible
