.tall-card
  +background-image(no-repeat, cover, center, center)
  backface-visibility: hidden
  height: rem-calc(450)
  position: relative
  transition: .3s
  width: rem-calc(300)

  &:hover
    background-size: cover
    transition: .3s

  &__overlay
    background-image: linear-gradient(0deg, lighten($black, 10%) 0%, transparent 70%)
    height: 100%

  &__content
    bottom: 0
    left: 0
    padding: rem-calc(20)
    position: absolute
    right: 0
    top: 0

  .inner-link
    display: inline-block
    margin-left: rem-calc(20)
    margin-top: rem-calc(15)

  &__title
    bottom: 0
    color: $white
    left: 0
    padding: 0 rem-calc(30) rem-calc(30) rem-calc(20)
    position: absolute
    right: 0

    h3
      font-size: $xlarge-font-size
      line-height: rem-calc(34)

    p
      font-size: $medium-font-size
      line-height: rem-calc(20)
      letter-spacing: $letter-spacing
      text-transform: uppercase
      margin-top: rem-calc(12)

    .quote-mark
      +background-image(no-repeat, contain, center, center)
      background-image: url('../../images/backgrounds/quote-mark.svg')
      height: rem-calc(25)
      position: relative
      top: rem-calc(-20)
      width: rem-calc(25)

  &__secondary-title
    color: $white
    font-size: rem-calc(17)
    font-weight: normal
    letter-spacing: $letter-spacing
    line-height: rem-calc(20)
    text-transform: uppercase

  &__programme
    align-items: flex-start
    color: $white
    display: flex
    margin-top: rem-calc(23)

  &__programme-badge
    width: rem-calc(30)

  &__programme-title
    font-size: $tiny-font-size
    font-weight: normal
    letter-spacing: $letter-spacing
    line-height: $g8-2x
    opacity: .7
    padding-left: rem-calc(15)
    padding-right: $g8-5x
    text-transform: uppercase

  &__hours
    position: relative

    img
      height: rem-calc(60)
      position: absolute
      right: 0
      top: 0
      width: rem-calc(60)
