.mission
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 10px
  padding: 80px
  +breakpoint(small only)
    padding: 20px

  &__header
    font-size: 46px
    color: rgba(6, 36, 60, 1)
    font-weight: 600
    text-align: center
    +breakpoint(small only)
      font-size: 20px

  &__body
    font-size: 20px
    font-style: italic
    color: rgba(111, 120, 137, 1)
    font-weight: 300
    text-align: center
    +breakpoint(small only)
      font-size: 14px
