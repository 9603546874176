.module-news-card
  background: $light-gray
  color: $black-3

  .title
    font-size: rem-calc(20)

  &__body
    max-height: rem-calc(200)
    min-height: rem-calc(200)
    overflow: hidden
    +transition(all 0.3s ease-in-out)

    &.has-overflow
      position: relative

      &:after
        background: $light-gray
        background: linear-gradient(180deg, rgba(239,239,240,0) 0%, rgba(239,239,240, 0.9) 100%)
        bottom: rem-calc(-3)
        content: '\A'
        height: $g8-8x
        left: 0
        pointer-events: none
        position: absolute
        width: 100%
        +transition(all 0.3s ease-in-out)

    &.expanded
      max-height: rem_calc(1000)

      &.has-overflow
        &:after
          height: 0

    &__inner
      p, span
        color: $black-3 !important
        font-family: $font-stack-poppins !important
        font-size: $small-font-size !important

  .news-toggle
    color: $secondary-color
    cursor: pointer
    display: inline-flex
    font-weight: bold
