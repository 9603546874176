.hero-image-container
    //min-height: 620px
    width: 100%
    border-radius: 0px 0px 100px 0px
    padding-top: 50px

.hero-container
    display: flex
    flex-direction: row
    justify-content: center
    align-content: center
    flex-wrap: wrap
    min-height: 640px
    padding-top: 50px
.hero-text
    padding: 10px
    background: transparent
    //position: absolute
    width: 40%
    min-height: 400px
    //margin-right: 50%
    //top: 5%
    +breakpoint(medium down)
        width: 100%
        top: 1.2%
        left: 0
        text-align: center
        margin-right: 0
    & h4
        font-style: normal
        font-weight: 600
        font-size: 58px
        line-height: 120%
        letter-spacing: -0.03em
        color: #ffffff
        padding-bottom: 10px
    & p
        font-style: normal
        font-weight: 300
        font-size: 16px
        line-height: 160%
        letter-spacing: -0.01em
        color: #ffffff
    &__button
        display: flex
        flex-direction: row
        align-items: center
        padding: 20px 30px
        width: 195px
        height: 64px
        left: 0px
        background: #ED6E56 url('./../../images/icons/arrow_right.svg') no-repeat right
        background-position: 80% 50%
        border-radius: 7px
        color: #ffffff
        margin-bottom: 5px
        +breakpoint(medium down)
            margin: 0 auto
        &:hover
            color: #ffffff
        &:focus
            color: #ffffff
    &__rating
        //display: inline flex
        margin-top: 20px

        & .star-rating
            width: 150px
            height: 30px
            +breakpoint(medium down)
                margin: 15px auto
            & .star-filled
                background: url('./../../images/icons/star_filled.svg') no-repeat
                width: 24px
                height: 24px
                padding-right: 30px
            & .star-empty
                background: url('./../../images/icons/star_empty.svg') no-repeat
                width: 24px
                height: 24px
                padding-right: 30px
        & ul
            padding: 0
            margin: 0
            & li:not(.empty)
                list-style: none
                display: inline-block
                background: url('./../../images/icons/star_filled.svg') no-repeat
                width: 24px
                height: 24px
                padding-right: 30px

            & li:is(.empty)
                list-style: none
                display: inline-block
                background: url('./../../images/icons/star_empty.svg') no-repeat
                width: 24px
                height: 24px
                padding-right: 30px

.card-wrapper
    position: relative
    //top: 5%
    //right: 10%
    width: 45%
    min-height: 405px
    +breakpoint(medium down)
        position: relative
        right: 0
        width: 100%
.hero-card
    //display: flex
    //flex-direction: column
    align-items: flex-start
    padding: 25px 0px 0px 0px
    background: transparent
    position: relative
    width: 90%
    min-height: 400px
    // right: 10%
    // top: 5%
    +breakpoint(medium down)
        position: relative
        width: 90%
        margin: 20px auto
        right: 0
        top: auto
    &__card-item
        position: relative
        display: flex
        flex-direction: column
        //justify-content: flex-start
        flex-wrap: nowrap
        padding: 40px
        //width: 90%
        min-height: 270px
        background: #FFFFFF url('./../../images/icons/card_pattern.png') no-repeat top
        box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
        border-radius: 14px
        +breakpoint(medium down)
            margin: 0 auto
            flex-direction: column
            text-align: center
            background: #FFFFFF url('./../../images/icons/card_pattern.png') repeat-x top
        &__circle
            width: 60px
            height: 60px
            background: rgba(237, 110, 86, 0.15)
            border-radius: 50%
            margin-bottom: 20px
            +breakpoint(medium down)
                margin: 0 auto
                margin-bottom: 20px
            &__logo
                width: 46px
                height: 46px
                margin: 7px auto
                background: #ED6E56 url('./../../images/programme_categories/category_shield.svg') no-repeat center center
                border-radius: 50%
        &__button
            position: absolute
            top: 45px
            right: 10px
            width: auto
            height: 25px
            background: rgba(237, 110, 86, 0.15)
            color: #ED6E56
            border-radius: 20px
            font-style: normal
            font-weight: 500
            line-height: 2em
            text-align: center
            font-size: 12px
            padding: 10px
            &:hover
                color: #ED6E56
                cursor: default
            +breakpoint(medium down)
                margin: 0 auto
                right: 10px
        & p
            font-style: normal
            font-weight: 500
            font-size: 16px
            line-height: 120%
            letter-spacing: -0.03em
            color: #6F7889
        & h4
            font-style: normal
            font-weight: 600
            font-size: 33px
            line-height: 120%
            letter-spacing: -0.03em
            color: #06243C
        &__discover
            position: absolute
            top: 300px
            font-style: normal
            font-weight: 500
            //width: 180px
            font-size: 16px
            line-height: 120%
            color: #ED6E56
            //background: transparent url('./../../images/icons/arrow_right_discover.svg') no-repeat right
            //background-position: 100% 50%
            +breakpoint(medium down)
                margin: 0 auto
                //position: fixed
                top: 300px
                left: 50%
                transform: translateX(-50%)


.card-wrapper .swiper-container
    max-width: 420px

.card-wrapper .swiper-pagination
    display: flex
    justify-content: center
    align-items: flex-end
    position: relative
    bottom: 19px
    margin-top: -30px !important
    +breakpoint(nav-header)
      padding: 0

    span.swiper-pagination-bullet
      background-color: transparent
      height: 10px
      width: 10px
      margin: 8px
      &.swiper-pagination-bullet
        background-color: gray
        opacity: 1

      &.swiper-pagination-bullet-active
        background-color: white

    +breakpoint(nav-header)
        margin-top: 0


