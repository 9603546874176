.cpd-header
  background-color: $black-3
  color: $dark-gray
  position: relative

  +breakpoint(nav-header)
    margin-top: $g8-8x

  &__graphic
    +background-image(no-repeat, cover, left, center)
    background-image: url('./../../images/backgrounds/cpd-decoration.svg')
    height: $g8-6x
    left: 0
    position: absolute
    right: 0
    top: 0
    width: 100%

    +breakpoint(xlarge)
      +background-image(repeat-x, contain, top, center)

  &__text
    padding-top: $g8-4x
    padding-bottom: $g8-4x

    &--designation
      +breakpoint(small only)
        margin-bottom: $g8-2x

    &--line
      border-top: 1px solid $light-gray

    &--status
      padding-left: $g8-2x

  &__title
    padding-top: $g8-5x

  &__title-text
    display: flex
    flex: 1 1

  +breakpoint(small only)
    &__navigation--button a
      width: 100%

.cpd_section
  h3, h4
    color: $black-3
    font-size: $large-font-size

.cpd_section__hours
      .cell
        .grid-x
          .cell
            .paginated-scroller
              .paginated-scroller__wrapper
                .swiper-container
                  .swiper-wrapper
                    .swiper-slide
                      width: 560px !important
                      +breakpoint(small only)
                        width: 410px !important
