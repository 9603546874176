.my-institute
  padding-top: $g8-3x
  padding-bottom: $g8-10x

  +breakpoint(nav-header)
    padding-top: $g8-10x

  .scroller
    &__standard-card, &__unit-card
      @include overflow-left-hidden

    &__standard-card
      .standard-card__title
        min-height: 0

    &__title::after
      border-top: 1px solid $light-gray
      top: rem-calc(12)

    &__title, h3
      font-size: $large-font-size

    &__wrapper--top
      margin-bottom: $g8

    .button__secondary--small
      +breakpoint(small only)
        width: 100%

  &__cpd-overview, &__modules, &__events
    .title_line
      h4
        margin-bottom: $g8
        margin-right: $g8-3x
        margin-top: $g8

      .border_line
        border-bottom: 1px solid $light-gray

  &__cpd-overview
    .overview
      background-color: $lightest-gray
      color: $black-3
      margin-bottom: $g8-2x
      margin-left: -$g8-2x
      margin-right: -$g8-2x
      padding: $g8-2x

      +breakpoint(medium)
        margin-left: 0
        margin-right: 0

      &-text
        border-top: 1px solid $dark-gray
        margin-top: $g8
        padding-top: $g8-2x

      .completion_date
        font-size: $medium-font-size

      .days_to_finish
        font-size: $tiny-font-size

        +breakpoint(small only)
          margin-bottom: $g8-2x

  &__modules
    +breakpoint(medium down)
      margin-top: $g8-3x

  &__member-card
    height: $g8*38

    +breakpoint(medium)
      max-width: $g8*39
      margin-right: $g8-2x

  &__career-block-wrapper
    margin-bottom: $g8-6x

    +breakpoint(large)
      margin-bottom: 0

  &__career-block
    background-color: $light-primary
    color: $white
    margin-left: -$g8-2x
    margin-right: -$g8-2x
    padding: $g8-10x $g8-2x $g8-5x $g8-2x
    position: relative

    +breakpoint(medium)
      align-items: center
      display: flex
      height: 100%
      margin-left: 0
      margin-right: 0
      padding: $g8-2x $g8-3x $g8-2x $g8-10x

      .grid-x
        width: 100%

  &__career-block-content

    +breakpoint(large)
      padding: 0 $g8-4x 0 $g8-2x

    &__buttons
      align-items: center
      display: flex
      justify-content: flex-end
      margin: $g8-2x 0

      p
        font-size: $tiny-font-size
        margin-bottom: 0
        margin-right: $g8-2x

        +breakpoint(large)
          margin-bottom: $g8-2x

      .button
        padding: $g8 $g8-4x
        margin-bottom: 0

      +breakpoint(large)
        align-items: flex-start
        flex-direction: column
        margin-bottom: 0

  &__career-block-form

    p
      font-size: $small-font-size

    &__buttons
      align-items: center
      display: flex
      justify-content: space-between

      +breakpoint(large)
        justify-content: space-between

      a
        color: $white
        font-size: $tiny-font-size
        font-weight: 600
        margin-right: $g8-2x

      .button
        margin: 0

  &__career-block-graphic
    +background-image(no-repeat, cover, center, center)
    background-image: url('./../../images/backgrounds/career-resources-decorator-mobile.svg')
    height: $g8-7x
    left: 0
    position: absolute
    top: 0
    width: 100%

    +breakpoint(medium)
      background-image: url('./../../images/backgrounds/career-resources-decorator.svg')
      height: 100%
      width: $g8-7x

  &__button
    margin-left: $g8*16
    margin-top: $g8-half
    position: absolute
    z-index: 3

  &__past-schemes
    .accordion.blue-right
      .accordion-title
        height: $g8-5x

      .accordion-title::before
        right: $g8
        margin-top: $g8-half

      .accordion-item
        .accordion-title::before
          content: '+ Show Past CPD schemes'

      .accordion-item.is-active
        .accordion-title::before
          content: '- Hide Past CPD schemes'

      .accordion-content
        background-color: $gray
        color: $white
        font-size: $tiny-font-size
        padding: $g8-3x

        ul
          +unordered-list-orange
          font-size: $small-font-size

          li
            line-height: $small-font-size

  .js-dismissal-container
    +breakpoint(medium down)
      &:first-child
        margin-top: -$g8-3x

  &__notification
    background-color: $lightest-gray
    margin-bottom: $g8

    +breakpoint(small only)
      border-left: rem-calc(5) solid $alert-color
      padding: $g8-3x $g8-2x $g8-2x $g8-2x

    +breakpoint(medium)
      border-bottom: rem-calc(5) solid $alert-color
      padding: $g8 $g8-4x

    +breakpoint(medium down)
      margin-left: -$g8-2x
      margin-right: -$g8-2x

    &--text
      color: $black-3
      font-size: $tiny-font-size

      +breakpoint(small only)
        margin-bottom: $g8-3x

      +breakpoint(medium)
        padding-right: $g8-2x

    &--dismiss
      padding-right: $g8-3x

      +breakpoint(small only)
        padding-right: $g8

  &__certificate
    background-color: $lightest-gray
    margin-bottom: $g8

    +breakpoint(small only)
      border-left: rem-calc(5) solid $alert-color
      padding: $g8-3x $g8-2x $g8-2x $g8-2x

    +breakpoint(medium)
      border-bottom: rem-calc(5) solid $alert-color
      padding: $g8 $g8-4x

    +breakpoint(medium down)
      margin-left: -$g8-2x
      margin-right: -$g8-2x

    &--text
      color: $black-3
      font-size: $tiny-font-size

      +breakpoint(small only)
        margin-bottom: $g8-3x

      +breakpoint(medium)
        padding-right: $g8-2x

    &--dismiss
      padding-right: $g8-3x

      +breakpoint(small only)
        padding-right: $g8
