.p-events__show
  color: $white

  .grid-container:not(.footer.grid-container)
    +breakpoint(medium down)
      padding: 0

  .events-show
    &__header-wrap
      +background-image(repeat, contain, center, center)
      padding-top: 100px
      padding-bottom: 100px
      background-image: linear-gradient(to bottom, transparent, rgba($black-3, 0.9) 95%), url('../../images/backgrounds/grid-background-hero-small.svg')

      +breakpoint(large)
        +background-image(no-repeat, cover, center, center)
        background-image: linear-gradient(to bottom, transparent, rgba($black-3, 0.9) 70%), url('../../images/backgrounds/grid-background-hero.svg')
        padding-top: rem-calc(200)

      .grid-container
        padding: 0

    &__title, &__content--links, &__off-canvas
      +breakpoint(large down)
        padding: 0 $g8-2x

    &__tabs
      .tabs
        overflow-x: auto
        overflow-y: hidden

    &__hero-image-wrapper
      position: relative

    &__hero-image
      +background-image(no-repeat, cover, center, center)
      border-radius: 100%
      height: $g8*52
      left: rem-calc(-100)
      position: relative
      top: rem-calc(-40)
      width: $g8*52

      +breakpoint(medium)
        height: $g8*65
        width: $g8*65

      +breakpoint(large)
        height: $g8*88
        left: -$g8*30
        position: absolute
        top: -$g8*60
        width: $g8*88

      &:after
        @include center-element
        border-radius: 100%
        border: 1px solid rgba(247, 247, 248, 1)
        content: ''
        height: $g8*56
        width: $g8*56

        +breakpoint(medium)
          height: $g8*69
          width: $g8*69

        +breakpoint(large)
          height: $g8*92
          width: $g8*92

    &__title
      position: relative
      text-shadow: rem-calc(1) rem-calc(2) rem-calc(2) $black-3

      h1, h6
        line-height: 120%

      h6
        color: $dark-gray
        font-size: $small-font-size

    &__details
      background-color: $gray

      .details-container
        padding: $g8-5x $g8-4x

        h3
          line-height: 120%

        h6
          font-size: $small-font-size
          font-weight: 300

        p
          margin-bottom: 0

      .mapouter,
      .gmap_canvas,
      iframe
        height: rem-calc(260)
        width: 100%

    &__content
      border-top: 1px solid $light-gray
      padding-top: $g8-5x

      .disabled-input
        height: auto
        margin-bottom: $g8-6x
        padding: $g8-2x

        &__value
          color: $gray

      &--button
        +breakpoint(large)
          margin-left: $g8-4x

        .button.disabled
          cursor: not-allowed
          pointer-events: none

      &--links
        margin-bottom: $g8-4x
        text-align: center

        +breakpoint(large)
          text-align: right

        a
          color: $white

        .button
          margin-bottom: 0
          width: 100%

          +breakpoint(medium)
            width: 75%

          +breakpoint(large)
            min-width: $g8*22

    &__info
      padding: 0
      position: relative

      hr
        margin: $g8-4x auto

    &__related
      padding: $g8-6x $g8-2x

      +breakpoint(large)
        padding: $g8-6x 0

  .sharing-bar
    margin-bottom: $g8-6x

    +breakpoint(large)
      padding-left: 0

  .reveal.small
    background-color: $white

  .reveal.full
    background-color: $black-3
    padding: $g8-2x

    +breakpoint(medium)
      padding: $g8-4x

    .close-button
      color: $white
      font-size: $g8-6x
      right: $g8-2x
      top: $g8-2x

      +breakpoint(large)
        right: $g8-4x
        top: $g8-4x

    .full-image
      +background-image(no-repeat, contain, center, center)
      height: 100%
      width: 100%
