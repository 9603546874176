ul.styled-list
  font-family: $font-stack-poppins
  font-size: $g8-2x
  list-style: none
  margin: rem-calc(10) 0
  padding: 0

  li
    margin-left: $g8-5x
    position: relative

    &:not(:last-child)
      margin-bottom: $g8-2x

  li::before
    border-radius: 100%
    border: 2px solid $white
    content: ''
    height: rem-calc(12)
    left: -$g8-5x
    position: absolute
    top: rem-calc(5)
    width: rem-calc(12)
