.filtersOffCanvas
  &#programmesFiltersOffCanvas,
  &#eventsFiltersOffCanvas,
  &#pastEventsFiltersOffCanvas,
  &#newsFiltersOffCanvas
    background-color: $white
    z-index: 301

    &.position-right
      transform: translateX(100vw)
      width: 100vw

      &.is-open
        transform: translate(0, 0)

      &.is-closed
        width: 0

    .top-bar
      align-items: center
      background-color: $white
      box-shadow: 0px 3px 10px rgba(14, 24, 40, 0.1)
      display: flex
      position: -webkit-sticky
      position: sticky
      top: 0
      width: 100%
      z-index: 302

      .top-bar-left
        flex: 1 0
        font-family: $font-stack-poppins
        font-size: rem-calc(36)

      .top-bar-right
        flex: 0 1 auto
        font-family: $font-stack-poppins
        font-size: rem-calc(13)
        font-weight: 600

      .top-bar-left, .top-bar-right
        .menu
          background-color: $white

          .menu-text
            padding-right: 0
            font-weight: normal

    .filters-content
      margin: $g8-5x 0

      p
        padding-left: $g8-half

      .cell
        padding: 0 $g8-half
