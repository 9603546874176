.meganav-main
  padding-bottom: 80px
  padding-top: 20px
  width: 100%
  position: absolute
  left: 0
  background-color: #FDFDFD
  padding-left: 5%
  padding-right: 5%
  z-index: -1
  +breakpoint(medium down)
    display: flex
    flex-direction: column
    //margin-top: 10px
    //padding-top: 45px

.discover-meganav-columns
  font-size: 12px
  float: left
  width: 25%
  padding: 10px
  color: #6F7889
  +breakpoint(medium down)
    float: none
    width: 100%
    text-align: center
    padding: 0px
  & h4
    font-size: 26px
    font-weight: 600
    color: black
    margin-bottom: 10px
    +breakpoint(medium down)
      cursor: pointer
      margin-bottom: $g8
      color: #6F7889
  &.active h4
    color: #06243C
  & a
    color: #6F7889
    font-weight: 500 !important
  & li
    list-style: none
    padding-top: 6px
    & p
      font-weight: 500 !important
      font-size: 16px !important
      margin-bottom: 0.4rem !important
  & ul
    width: 100%
    text-align: left
    height: 38px
    border: none
    padding: 10px 15px
    border-radius: 25px
    margin: 10px 0px
    display: inline-block
    cursor: pointer
    text-decoration: none
    margin: 0px
    padding: 0px
    background-color: #FDFDFD
  .meganav-columns__content
    +breakpoint(medium down)
      width: 100%
      ul
        text-align: center
  &:not(.active) .meganav-columns__content
    +breakpoint(medium down)
      display: none
    
  & li:after
    content: ""
    display: block
    height: 100%
    border-bottom: 1px solid #D4D7DD
    //margin-top: 10px
    //margin-bottom: 10px
  & li:last-of-type::after
    display: none
