.library-search-card
  height: $g8*30
  width: 100%

  +breakpoint(medium)
    max-width: $g8*49

  +breakpoint(large)
    height: $g8*43

  &__orange
    background-color: $pale-orange

  &__bottom
    padding: 0 $g8-2x $g8-2x 0

  &__text
    padding: $g8-4x

    +breakpoint(large)
      padding-left: $g8*11

  &__title
    color: $black-3
    display: inline-block

    +breakpoint(large)
      display: block

  &__icon
    +background-image(no-repeat, auto, center, center)
    border-radius: 100%
    background-color: $secondary-color
    background-image: url('../../images/icons/icon-search-white.svg')
    height: $g8-7x
    width: $g8-7x

  &__graphic
    +background-image(no-repeat, cover, top, center)
    background-image: url('../../images/backgrounds/part-time-feature-banner-bottom.svg')
    height: $g8-7x
    width: 100%

    +breakpoint(large)
      background-image: url('../../images/backgrounds/part-time-feature-banner.svg')
      height: $g8*43
      position: absolute
      width: $g8-7x
