#infoOffCanvas
  background-color: $lightest-gray
  z-index: 301

  &.position-right
    transform: translateX(100vw)
    width: 100vw

    &.is-open
      transform: translate(0, 0)

    &.is-closed
      width: 0

    +breakpoint(medium)
      transform: translateX(50vw)
      width: 50vw

  .top-bar
    box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1)
    padding: $g8-3x $g8-3x

  .top-bar,.menu
    background-color: $lightest-gray

  .top-bar-left, .top-bar-right
    flex: 0 0 auto

  .menu-text
    font-weight: 600
    padding: 0

    .close
      span
        font-size: $g8-3x

  .info-content
    padding: $g8-5x $g8-3x

    hr
      margin: $g8-4x auto

    h3
      line-height: 120%

    p
      margin-bottom: 0

    .details-container
      +breakpoint(medium down)
        margin-bottom: $g8-5x

.info
  padding-top: 150px
  &__off-canvas
    box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1)
    margin-bottom: $g8-7x

    .title-bar
      background-color: $lightest-gray
      color: $primary-color
      padding: $g8-2x $g8-3x

    .title-bar-right
      font-size: $g8-3x
.info-show 
  padding-top: 150px
