.programmes-card-small
  &__governance-and-compliance
    +small-programmes-card-detail(map-get($iob-color-palette, gold), $black-3)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/governance_and_compliance_small_card_detail.svg')

  &__culture
    +small-programmes-card-detail(map-get($iob-color-palette, strawberry), $white)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/culture_small_card_detail.svg')

  &__digital-and-innovation
    +small-programmes-card-detail(map-get($iob-color-palette, blue), $white)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/digital_and_innovation_small_card_detail.svg')

  &__financial-planning
    +small-programmes-card-detail(map-get($iob-color-palette, orange), $white)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/financial_planning_small_card_detail.svg')

  &__funds
    +small-programmes-card-detail(map-get($iob-color-palette, green), $white)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/funds_small_card_detail.svg')

  &__executive-education
    +small-programmes-card-detail(map-get($iob-color-palette, grey), $black-3)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/executive_education_small_card_detail.svg')

  &__retail-banking
    +small-programmes-card-detail(map-get($iob-color-palette, peach), $black-3)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/retail_banking_small_card_detail.svg')

  &__risk-management, &__credit-and-lending
    +small-programmes-card-detail(map-get($iob-color-palette, navy), $white)

    .programmes-card-small__detail
      background-image: url('./../../images/programme_cards_small_details/risk_management_small_card_detail.svg')
