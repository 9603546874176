.unit-card
  &__light-blue
    +unit-card-detail($pale-blue, $g8*12, -$g8, 1.2, $g8-3x, $dark-gray, $black-3)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/compliance_unit_card_detail.svg')

  &__blue
    +unit-card-detail($dark-blue, $g8*12, $g8, 1.2, $g8-3x, $dark-gray, $white)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/funds_unit_card_detail.svg')

  &__navy
    +unit-card-detail($primary-color, $g8*12, $g8, 1.2, $g8-3x, $gray, $white)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/risk_unit_card_detail.svg')

  &__light-orange
    +unit-card-detail($pale-orange, $g8*12, $g8, 1.2, $g8-3x, $dark-gray, $black-3)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/culture_unit_card_detail.svg')

  &__orange
    +unit-card-detail($lightest-orange, $g8*12, $g8, 1.2, $g8-3x, $dark-gray, $black-3)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/leadership_programmes_unit_card_detail.svg')

  &__light-grey
    +unit-card-detail($lightest-gray, $g8*12, $g8, 1.2, $g8-3x, $gray, $black-3)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/financial_planning_unit_card_detail.svg')

  &__grey
    +unit-card-detail($lightest-primary, $g8*12, $g8, 1.2, $g8-3x, $gray, $black-3)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/digital_and_innovation_unit_card_detail.svg')

  &__dark-grey
    +unit-card-detail($gray, $g8*17, $g8, 1.2, $g8-3x, $light-gray, $white)

    .unit-card__detail
      background-image: url('./../../images/unit_card_details/retail_banking_unit_card_detail.svg')
