.glossary-header
  display: flex
  flex-direction: column
  margin-top: 5rem
  height: 320px
  padding: 100px 120px 0px 120px
  gap: 80px
  border-radius: 0px 0px 100px 0px
  background-position: left 0% bottom 50%
  background-size: cover
  +breakpoint(medium only)
    padding: 30px 30px 30px 40px
  +breakpoint(small only)
    height: 415px
    padding: 30px 30px 30px 40px

  &--content
    display: flex
    justify-content: space-between
    +breakpoint(medium only)
      flex-direction: column
      align-items: center
    +breakpoint(small only)
      flex-direction: column
      align-items: center

    &__text
      +breakpoint(medium)
        padding: 20px 0px 20px 0px

  & h4
    font-family: $font-stack-poppins
    text-shadow: 0px 1px 5px rgba(6, 36, 60, 0.3), 0px 10px 10px rgba(6, 36, 60, 0.07)
    font-weight: 600
    color: white
    font-size: 40px
    +breakpoint(small only)
      font-size: 33px

  & p
    font-family: $font-stack-poppins
    text-shadow: 0px 1px 5px rgba(6, 36, 60, 0.3), 0px 10px 10px rgba(6, 36, 60, 0.07)
    font-weight: 600
    color: white
    font-size: 24px
