.disabled-input
  background-color: $light-gray
  cursor: not-allowed
  height: $g8*7.5
  margin-bottom: $g8-2x
  padding: rem-calc(2) $g8-3x $g8-2x $g8-3x

  &__placeholder
    color: $darkest-gray
    font-size: 0.7em

  &__value
    color: rgba($darkest-gray, 0.7)

    a
      color: $primary-color
