.footer
  background-color: $black-2
  color: rgba($white, .5)
  font-size: $medium-font-size
  font-style: normal
  font-weight: 600
  padding: $g8-10x 0
  text-align: center

  +breakpoint(medium)
    padding: $g8-10x

  +breakpoint(large)
    padding: $g8*15 0
    text-align: left

  h4
    color: $white
    margin-bottom: $g8-2x

  &__link--grey
    color: rgba($white, .5)

    &:hover
      color: $secondary-color

  ul
    list-style: none
    margin: 0
    padding: 0

    li
      &:last-child
        margin-bottom: $g8-5x

      a
        color: rgba($white, .5)

      p
        margin-bottom: $g8-2x
        display: inherit

  &__col
    &:not(:last-child)
      margin-bottom: $g8-5x
      +breakpoint(small only)
        margin: 0 auto 2.5rem auto

    &--1
      margin-right: 2%
      li
        margin-bottom: rem-calc(10)
    &--2
      margin-right: 2%
      li
        margin-bottom: rem-calc(10)
    &--3
      margin-right: 2%
      li
        margin-bottom: rem-calc(10)
    &--4
      margin-right: 2%
      li
        margin-bottom: rem-calc(10)


  &__col-user-tools
    align-items: center
    border-bottom: 1px solid $gray
    display: flex
    justify-content: space-between
    flex-direction: column
    margin-bottom: $g8-5x
    padding-bottom: $g8-5x
    padding-right: $g8-5x
    +breakpoint(large)
      align-items: flex-start
      border-bottom: none
      flex-direction: column
      padding-right: 0
    +breakpoint(medium down)
      padding-right: 0
    &__icons
      padding-right: $g8-5x
      padding-bottom: $g8-5x
      +breakpoint(medium down)
        padding-right: 0
      display: flex
      &__facebook
        background-image: url('./../../images/icons/facebook.svg')
        height: 38px
        width: 38px
        background-repeat: no-repeat
        background-size: cover
        margin-right: $g8-2x
      &__twitter
        background-image: url('./../../images/icons/twitter.svg')
        height: 38px
        width: 38px
        background-repeat: no-repeat
        background-size: cover
        margin-right: $g8-2x
      &__instagram
        background-image: url('./../../images/icons/instagram.svg')
        height: 38px
        width: 38px
        background-repeat: no-repeat
        background-size: cover
        margin-right: $g8-2x
      &__linkedin
        background-image: url('./../../images/icons/linkedin.svg')
        height: 38px
        width: 38px
        background-repeat: no-repeat
        background-size: cover
        margin-right: $g8-2x
    a
      font-size: $medium-font-size

  &__col-connect
    margin-bottom: $g8-5x

  &__col-contact
    img
      display: block
      margin: $g8-4x auto
      +breakpoint(large)
        margin: $g8-4x 0

  &__col-company-details
    width: 60%

    img
      margin-bottom: $g8-6x

    p
      color: rgba($white, .3)
      font-size: $tiny-font-size
      letter-spacing: 0.02em
      line-height: 140%
  &__logo
      height: $g8-10x
      width: rem-calc(200)
      background-image: url('./../../images/logos/logo-white.svg')
      background-repeat: no-repeat
      margin-bottom: $g8-6x
  &__legal
      width: 100%
      display: flex
      float: left
      flex-wrap: wrap
      justify-content: space-between
      text-align: center
      &__right
        +breakpoint(large)
          display: flex
          flex: 1
          padding-bottom: $g8-6x
          flex-direction: row
          justify-content: flex-end

          ul li
            display: inline
            padding-right: $g8-6x
            padding-bottom: $g8-6x
      &__left
        +breakpoint(large)
          display: flex
          flex: 1
          justify-content: flex-start










