#toast-container
  div
    width: unset
    opacity: 1

#toast-container.toast-top-center
  top: 20px
  div
    width: 90%
    opacity: 1
