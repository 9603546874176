@mixin programmes-card__blurb
  width: 190px
  padding: 10px 20px 0px 0px
  +breakpoint(xlarge)
    width: 250px
    padding: 10px 20px 10px 0px

@mixin programmes-card__header_image($background-image)
  background-image: url($background-image)
  height: 10px
  width: 220px
  border-top-left-radius: 9999px
  border-top-right-radius: 9999px
  +breakpoint(xlarge)
    width: 280px

@mixin programmes-card__header_icon($icon-image)
  height: 50px
  width: 50px
  background-image: url($icon-image)
  background-size: contain
  box-shadow: unset

.programmes-card__governance-and-compliance,
.programmes-card__governance,
.programmes-card__gold
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/compliance_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/compliance_card_cap.png')

  & .icon
    @include programmes-card__header_icon('images/icons/programme_icon_gold.png')

.programmes-card__culture,
.programmes-card__strawberry
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/culture_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/culture_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_strawberry.png')

.programmes-card__digital-and-innovation,
.programmes-card__blue
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/digital_and_innovation_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/digital_and_innovation_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_blue.png')

.programmes-card__financial-planning,
.programmes-card__orange
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/financial_planning_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/financial_planning_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_orange.png')

.programmes-card__funds,
.programmes-card__esg,
.programmes-card__green
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/funds_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/funds_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_green.png')

.programmes-card__executive-education,
.programmes-card__leadership-programmes,
.programmes-card__grey
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8*3.75, white)

    &__detail
      background-image: url('./../../images/programme_cards/leadership_programmes_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/leadership_programmes_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_grey.png')

.programmes-card__retail-banking,
.programmes-card__peach
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), contain, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/retail_banking_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/retail_banking_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_orange.png')

.programmes-card__risk-management,
.programmes-card__credit-and-lending,
.programmes-card__risk,
.programmes-card__compliance,
.programmes-card__navy
  .programmes-card
    +programmes-card-detail(map-get($iob-color-palette, white), cover, $g8-3x, white)

    &__detail
      background-image: url('./../../images/programme_cards/risk_card_detail.svg')

    &__blurb
      @include programmes-card__blurb

  & .header-image
    @include programmes-card__header_image('./../../images/programme_cards/risk_card_cap.png')

  & .icon
    @include programmes-card__header_icon('./../../images/icons/programme_icon_navy.png')

.news-events__buttons
  .button
    +breakpoint(small only)
      width: 100%
.awarding_body
  padding-bottom: 8px
  display: flex
  flex-direction: column
  & img
      width: 50px
      height: auto
  background-size: 40px
  background-position: left center
  background-repeat: no-repeat
  padding-left: 50px
  padding-top: 20px
  margin-left: 50%
  & p
      font-weight: 500
      font-size: 18px
      line-height: 120%
      letter-spacing: -0.03em
      color: #6F7889
  & span
      font-size: 16px
      line-height: 150%
      color: #6F7889
