.my-details
  &__banner
    background-color: $black-3
    border-bottom: rem-calc(5) solid $secondary-color

    +breakpoint(nav-header)
      margin-top: $g8-8x

  &__banner-graphic
    +background-image(no-repeat, cover, center, center)
    background-image: url('./../../images/backgrounds/cpd-decoration.svg')
    height: $g8-5x

  &__banner-text
    align-items: flex-start
    color: $white
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: $g8-5x 0

    +breakpoint(medium)
      align-items: center
      flex-direction: row

    .member-number
      font-size: $small-font-size
      margin-top: $g8-2x

      +breakpoint(medium)
        margin: 0

    &-cell:nth-child(2)
      align-items: center
      display: none
      flex-grow: 1
      position: relative

      +breakpoint(medium)
        display: flex

      span
        background-color: $light-gray
        height: 1px
        left: 0
        margin: 0 auto
        position: absolute
        right: 0
        width: 95%

  &__title
    padding-top: $g8-5x

  &__title-text
    display: flex
    flex: 1 1
  
  +breakpoint(small only)
    &__navigation--button a
      width: 100%
      
  &__title-closing-button
    display: flex
    flex-flow: row wrap

.remove-top-margin
  margin-top: 0 !important