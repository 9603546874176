.p-info__index
  .info
    &__questions-column
      background-color: $lightest-gray
      border-radius: rem-calc(10)
      box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1)
      height: 100%

    &__popular-questions
      +breakpoint(large)
        padding: $g8-4x

      hr
        border-bottom: 1px solid $light-gray

      &--category
        font-size: $small-font-size
        line-height: $g8-5x

      &--card
        &:not(:last-child)
          border-bottom: solid $light-gray 1px
          margin-bottom: $g8-4x

        p, a
          font-size: $tiny-font-size

      &--dark-pink
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, dark-pink)

      &--pink
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, pink)

      &--orange
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, orange)

      &--yellow
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, yellow)

      &--green
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, green)

      &--light-blue
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, light-blue)

      &--blue
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, blue)

      &--purple
        .info__popular-questions--category
          border-bottom: solid rem-calc(10) map-get($accent-colors, purple)
