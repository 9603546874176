.search_message
  flex: 1 0 0
  padding-bottom: 40px

  &__title
    color: map-get($iob-color-palette, navy)
    text-align: center
    font-family: $font-stack-poppins
    font-size: 24px
    font-style: normal
    font-weight: 500
    line-height: 160%

  &__subtitle
    color: map-get($iob-color-palette, navy)
    text-align: center
    font-family: $font-stack-poppins
    font-size: 16px
    font-style: normal
    font-weight: 300
    line-height: 160%
