table
  color: $black-3
  border: 2px solid transparent

  td, th
    text-align: left

  th
    font-weight: 600

  tbody tr:nth-child(even)
    background-color: $lightest-gray

  tbody
    background-color: transparent

  tr:not(:first-child)
    td:first-child
      max-width: $g8*25
      width: $g8*25

    td
      max-width: $g8*19
      width: $g8*19

.markdown-table
  overflow-x: scroll
  word-wrap: break-word

  +breakpoint(large)
    overflow: visible
