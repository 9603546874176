.p-users-cpd__show
  .tool-tip
    position: relative

.tool-tip
  margin: 0 0.5rem

  +breakpoint(medium)
    align-items: center
    display: inline-flex
    position: relative

  &__icon
    background-color: $info-color
    border-radius: 100%
    color: $white
    cursor: help
    display: inline-block
    font-size: $small-font-size
    height: rem-calc(20)
    text-align: center
    width: rem-calc(20)

  &:hover &__content
    left: 0
    opacity: 1
    position: absolute
    top: $g8*8.75
    transition: .3s
    visibility: visible

    +breakpoint(medium)
      left: $g8*6.25
      position: absolute
      top: $g8

  &__content
    background-color: $gray
    color: $white
    left: 0
    opacity: 0
    position: absolute
    top: $g8-10x
    transition: .3s
    visibility: hidden
    z-index: 99

    +breakpoint(medium)
      left: $g8*7.5
      position: absolute
      top: 50%
      transform: translate(0, -50%)
      width: $g8*52
      z-index: 99

    &-arrow
      border-bottom: rem-calc(20) solid $gray
      border-left: rem-calc(10) solid transparent
      border-right: rem-calc(10) solid transparent
      height: 0
      left: 50%
      position: absolute
      top: rem-calc(-20)
      transform: translate(-50%, 0)
      width: 0

      +breakpoint(medium)
        border-bottom: rem-calc(10) solid transparent
        border-right: rem-calc(20) solid $gray
        border-top: rem-calc(10) solid transparent
        left: rem-calc(-15)
        top: 50%
        transform: translate(-50%, rem-calc(-9))

  &__content-info
    display: flex
    flex-direction: column

    +breakpoint(medium)
      flex-direction: row

  &__content-info__text
    font-size: $tiny-font-size
    padding: rem-calc(60) rem-calc(20) rem-calc(20) rem-calc(20)
    position: relative

    +breakpoint(medium)
      padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(60)

    &:before
      background-image: url('../../images/backgrounds/tool-tip-decorator-mobile.svg')
      +background-image(no-repeat, cover, center, center)
      bottom: 0
      content: ''
      height: auto
      left: 0
      position: absolute
      top: 0
      width: 100%
      height: rem-calc(47)

      +breakpoint(medium)
        background-image: url('../../images/backgrounds/tool-tip-decorator.svg')
        +background-image(no-repeat, cover, center, center)
        bottom: 0
        content: ''
        height: auto
        left: -1px
        position: absolute
        top: 0
        width: rem-calc(47)

    p:last-child
      margin-bottom: 0

/*
 * Tool-tip repositioning for the check out page
/*

.additional-info__toggles
  position: relative

  .tool-tip
    display: inline-block

    +breakpoint(medium)
      position: initial

  .tool-tip__content
    top: 100% !important
    transform: translate(0, 10%)

    +breakpoint(medium)
      left: 50% !important
      right: 0 !important
      top: 100% !important
      transform: translate(-50%, 10%)

    &-arrow
      +breakpoint(medium)
        border-bottom: rem-calc(20) solid $gray
        border-left: rem-calc(10) solid transparent
        border-right: rem-calc(10) solid transparent
        height: 0
        left: 50%
        position: absolute
        top: rem-calc(-29)
        transform: translate(-50%, 0)
        width: 0
