.cfid-pages
  font-style: normal
  font-weight: normal
  margin-top: 80px

  .category-header
    margin-bottom: 50px

    p
      color: #ffffff

  .grid-container
    .cifd-text
      font-weight: 300
      font-size: 14px

    .cifd-btn
      text-align: right
      margin-bottom: 30px

  .cfid-banner
    height: 200px
    background-color: #efefef
    margin-bottom: 30px

    h2
      color: #ED6E57
      padding-top: 20px

  .cfid-filters
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    margin: 0 -10px

    .sel
      //margin: 0 10px
      width: 33%
      padding: 5px
      +breakpoint(small only)
        width: 100%

      .cfid-select
        font-size: 12px

  *,
  *:before,
  *:after
    box-sizing: border-box

  .cfid-profiles
    padding: 1rem 0
    margin: 0 -5px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-content: flex-start
    justify-content: flex-start
    +breakpoint(small only)
      //padding-left: 30px

    .person
      height: 263px
      border-bottom: 2px solid #ED6E57
      display: block
      //width: 140px
      +breakpoint(small only)
        height: 307px

      &:hover
        opacity: 0.5

    //.mix,
    //.gap
    //  display: inline-block
    //  vertical-align: top


    .mix
      background: #fff
      //border-radius: 2px
      //margin-bottom: 1rem
      //position: relative
      //width: 160px
      //height: 300px
      width: 16.66666%
      padding: 0 5px
      margin: 0 0 20px 0
      //width: 100%
      float: left
      +breakpoint(small only)
        width: 50%

      .cifd-member-img
        height: 180px
        //width: 140px
        width: 100%
        background-position: top center
        background-repeat: no-repeat
        background-size: cover
        +breakpoint(small only)
          height: 224px

      .cifd-member-name
        color: #ED6E57
        font-size: 12px
        font-weight: 600
        padding-top: 5px

      .cifd-area
        font-weight: 300
        font-size: 12px
        color: #000000

    .mix:before
      content: ''
      display: inline-block
      padding-top: 10%

  .cfid-show-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: flex-start
    padding-top: 40px

    .cfid-show-left
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      align-items: flex-start
      width: 50%
      padding-left: 50px
      +breakpoint(small only)
        width: 95%
        padding-left: 0

      .larger-image
        width: 220px
        height: 274px
        background-position: top center
        background-repeat: no-repeat
        background-size: contain

      .left-text
        position: relative
        left: 20px
        width: 229px
        height: 274px
        border-bottom: 2px solid #ED6E57
        +breakpoint(small only)
          height: 212px

        h3
          color: #ED6E57
          margin-bottom: 30px
          +breakpoint(small only)
            margin-bottom: 20px
            font-size: 19px

        .cifd-linkedin
          background-image: url('./../../images/icons/linkedin-logo.png')
          height: 38px
          width: 38px
          background-repeat: no-repeat
          background-size: cover
          position: absolute
          bottom: 0
          left: 0

    .cfid-show-right
      font-size: 14px
      font-weight: 300
      width: 50%
      +breakpoint(small only)
        width: 100%

      a
        color: #ED6E57
        font-weight: 300



