.input-container
  position: relative
  margin-bottom: 1em

  &.small
    margin: 1em 0

    .input
      display: flex

    label
      position: static
      color: $black
      display: flex
      align-items: center
      margin-right: $g8-2x

    select, input
      display: flex

    .input-message
      position: absolute
      right: 0

  &.focused:not(.file-field)
    label
      font-size: 0.7em
      min-height: 1.1rem
      left: rem-calc(23)

    &.small
      label
        font-size: 1em

    input, select
      border: $input-border
      background-color: $light-gray
      box-shadow: none

    .input__clear
      background: url("../../images/icons/icon-cancel-black.svg") center center no-repeat
      cursor: pointer
      height: rem-calc(20)
      position: absolute
      right: rem-calc(20)
      top: rem-calc(20)
      width: rem-calc(20)
      z-index: 1
      @include transition(transform 0.3s ease-in-out)

      &.hidden
        display: none

      &:hover
        transform: rotate(90deg)

  &.valid
    input, textarea
      border-bottom: rem-calc(3) solid $success-color !important

    select
      color: $black

  &.has-message,
  &.error

    .input-message
      color: $alert-color
      max-height: rem-calc(100)
      padding: $g8 0 0 0

    input, textarea
      border-bottom: rem-calc(3) solid $alert-color

  .input-message
    font-size: $tiny-font-size
    max-height: 0
    overflow: hidden
    text-align: right
    @include transition(all 0.3s ease-in-out)

  ::-webkit-input-placeholder
    color: $darkest-gray

  ::-moz-placeholder
    color: $darkest-gray

  :-ms-input-placeholder
    color: $darkest-gray

  :-moz-placeholder
    color: $darkest-gray

  select
    color: $darkest-gray
    margin: 0

  input
    margin: 0
    @include transition(0.1s ease-in-out)

    &:disabled
      cursor: not-allowed

  label
    align-items: center
    background: none
    color: #06243C
    display: flex
    font-size: 0.9em
    left: 45px
    line-height: 1rem
    min-height: 50px
    position: absolute
    top: 0
    white-space: pre-line
    @include transition(all 0.1s ease-in-out)

    &::after
      content: ''
      font-size: 0
      min-height: inherit

  &.checkbox
    +triangle-checkbox

    label
      color: $black
      font-size: $medium-font-size
      left: 0
      margin-left: 0
      position: relative
      top: 0

  &.file-field
    .file
      display: flex
      justify-content: flex-start

      input
        border: none
        display: none
        height: 0
        opacity: 0
        padding: 0
        width: 0

      label
        color: black
        display: flex
        flex-grow: initial
        font-size: $medium-font-size
        min-height: 0
        position: initial
        width: 100%
        display: flex
        justify-content: space-between

        &::after
          content: none !important

      .input-selector
        align-items: center
        background-color: $light-gray
        color: $primary-color
        display: flex
        font-family: $font-stack-poppins
        font-size: $small-font-size
        font-weight: 600
        justify-content: center
        min-width: rem-calc(140)
        padding: $g8-2x $g8-2x
        text-align: center
        transition: 0.3s
        white-space: nowrap
        margin-left: 1rem

        &:hover
          border-bottom-right-radius: $g8-3x
          cursor: pointer
          transition: 0.3s

    .file-container
      align-items: center
      display: flex
      justify-content: space-between

      .button
        font-family: $font-stack-poppins
        font-size: $small-font-size
        font-weight: 600
        margin-bottom: 0
        padding: 0

  .top-bar input
    width: 100% !important
    max-width: 100% !important

.string
  & .optional
    padding-left: 50px

.search_query input
  border-bottom: 0px !important
