.testimonial
  display: flex
  justify-content: center
  align-items: flex-end
  background-color: #F2F3F5
  padding-bottom: 70px
  align-items: center

  & .swiper-wrapper
    padding-bottom: 40px

    .swiper-container
      height: auto

    .slide-content
      display: flex
      flex-direction: row

      +breakpoint(small only)
        justify-content: center
        align-items: center
        flex-direction: column-reverse

    .slide-media
      padding-left: 10%
      padding-right: 25px
      width: 30%
      display: flex
      z-index: 1000

      & img
          border-radius: 20px
          height: 380px !important
          width: auto
          +breakpoint(small only)
            height: 250px !important
            width: auto

      +breakpoint(small only)
        width: 95%
        padding-right: 5%
        padding-left: 5%
        justify-content: center

  & .swiper-pagination
    display: flex
    justify-content: center
    align-items: flex-end
    position: relative
    bottom: 10px

    +breakpoint(nav-header)
      padding: 0

    span.swiper-pagination-bullet
      background-color: transparent
      height: 10px
      width: 10px
      margin: 8px

      &.swiper-pagination-bullet
        background-color: gray
        opacity: 1

      &.swiper-pagination-bullet-active
        background-color: black

  +breakpoint(nav-header)
    margin-top: 0

    .swiper-container

      +breakpoint(large)
        height: 100%

    .swiper-slide

      &.swiper-slide-active.swiper-slide-duplicate-next.swiper-slide-duplicate-prev
        height: auto !important

      &.swiper-slide-active
        height: auto !important

      &:before
        bottom: 0
        content: ''
        display: block
        left: 0
        position: absolute
        right: 0
        top: 0

        +breakpoint(small only)

  .slide-details
    color: black
    padding-left: 60px
    width: 70%

    +breakpoint(small only)
      width: 90%
      padding-left: 5%
      padding-right: 5%

  .testimonial-text
    font-weight: 500
    padding-left: 20px
    padding-right: 20px
    display: flex
    flex-direction: column
    height: 100%
    & h6
      margin-bottom: 30px
      font-weight: 500
      font-size: 16px
      margin-top: 30px

    +breakpoint(large down)
      padding-right: 0%

    +breakpoint(small only)
      width: 90%
      text-align: center
      padding-left: 5%
      padding-right: 5%
      align-items: center

      & h6
        padding-bottom: 20px

  .star-rating
    height: 25px
    margin-bottom: 20px
    position: relative

    & .star
      width: 24px
      height: 24px
      background-image: url('./../../images/icons/Star.png')
      background-repeat: no-repeat
      background-position: center center

    +breakpoint(small only)
      background-position: top center

  .testimonial-quote
    list-style: none
    margin: 0 0 20px 0
    padding: 0px
    font-family: Merriweather !important
    font-style: italic
    font-weight: 300
    font-size: 28px
    line-height: 140%
    color: #06243C
    & p
      margin-bottom: 0

    +breakpoint(small only)
      font-size: 19px
      & p
        padding-bottom: 20px
        padding-top: 20px

  .testimonial-member
    margin-bottom: 0px
    list-style: none
    margin: 0px
    padding: 0px
    & p
      margin-bottom: 0

    +breakpoint(small only)
      & p
        padding-bottom: 30px

  .testimonial-iframe
    border-radius: 25px
    width: 550px
    height: 412px
