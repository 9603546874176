.cpd-progress-bar
  background-color: $light-primary
  height: $g8-5x
  margin-bottom: $g8-2x
  position: relative

  progress[value]
    -webkit-appearance: none
    appearance: none
    background-color: $light-primary
    border: none
    height: $g8-5x
    width: 100%

  progress[value]::-webkit-progress-bar
    background-color: $light-primary

  progress[value]::-webkit-progress-value
    background-color: $primary-color

  progress[value]::-ms-fill
    color: $primary-color

  progress::-ms-fill
    border: none

  p
    color: $white
    left: $g8-2x
    margin: 0
    position: absolute
    top: $g8

.progress-bar__links
  align-items: center
  display: flex
  padding-left: $g8-2x
  margin-bottom: $g8-2x

  a
    margin-bottom: 0

  &-title
    color: $gray
    font-size: $small-font-size
    font-weight: 600

  &-divider
    display: flex
    flex: 2
    justify-content: center
    padding: 0 $g8

    span
      background-color: $dark-gray
      height: 1px
      width: 100%
