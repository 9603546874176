.strategy-video
  justify-content: center
  display: flex
  flex-direction: row
  padding: 80px
  gap: 20px
  +breakpoint(medium down)
    flex-direction: column
    padding: 20px

  &__header
    font-weight: 600
    font-size: 46px
    color: rgba(6, 36, 60, 1)
    +breakpoint(small only)
      font-size: 26px

  &__subtitle
    font-size: 18px
    font-weight: 500
    color: rgba(111, 120, 137, 1)
    +breakpoint(small only)
      font-size: 16px

  &__text
    font-weight: 300
    font-size: 16px
    color: rgba(111, 120, 137, 1)
    +breakpoint(small only)
      font-size: 14px

    &-container
      padding: 40px
      gap: 20px
      display: flex
      flex-direction: column
      max-width: 650px
      +breakpoint(medium down)
        text-align: center
        padding: 15px
        gap: 10px

  &__embed
    padding: 56.25% 0 0 0
    position: relative

    &-container
      overflow: hidden
      position: relative
      +breakpoint(medium)
        width: 500px
      +breakpoint(medium only)
        margin: auto
