.gray-card
  background-color: $lightest-gray
  box-shadow: $card-box-shadow
  padding: $g8-3x
  color: $black-3
  position: relative

  .link_right
    color: $primary-color

  p
    font-size: $tiny-font-size
