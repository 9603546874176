.fee-reminder
  width: 400px
  height: 493px
  max-width: 400px
  max-height: 493px

  &__flex-wrapper
    display: flex
    flex-direction: column
    box-shadow: 0px 10px 50px rgba(6, 36, 60, 0.03), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 3px 4px rgba(6, 36, 60, 0.07)
    border-radius: 13px
    flex-grow: 0

    +breakpoint(small only)
      min-height: none
      max-height: none

  &__top-cap
    display: flex
    flex-direction: column
    justify-content: center

  &__top-cap-bar
    background-image: url('./../../images/unit_card_details/caps.svg')
    height: 12px
    background-repeat: no-repeat
    border-top-right-radius: 40px

  &__top-cap-bottom
    background-image: url('./../../images/icons/top-bit.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 10px
    margin-left: 32%
    margin-right: 33%

  &__content-body
    font-weight: 300
    font-size: 18px
    line-height: 28.8px
    letter-spacing: -0.02em
    padding-top: 20px
    color: #6F7889

    +breakpoint(small only)
      padding-top: 0px

  &__content-top-left
    font-weight: 600
    font-size: 36px
    line-height: 43.2px
    letter-spacing: -0.03em
    padding-bottom: 20px
    color: #06243C
    padding-top: 40px

    +breakpoint(small only)
      display: none

  &__content-top-right
    background-image: url('./../../images/icons/light-bulb.svg')
    flex-grow: 0
    width: 86.62px
    height: 88px
    flex-shrink: 0

  &__content-bottom-button
    font-family: 'Poppins'
    font-style: normal
    background-color: #ED6E56
    padding: 20px 20px 20px 30px
    border-radius: 7px
    font-size: 16px
    line-height: 120%
    font-weight: 500
    color: #FFFFFF

  &__content-bottom
    display: flex
    flex-direction: row
    justify-content: flex-end
    padding-top: 37px

    +breakpoint(small only)
      padding-top: 0px

  &__content-flex
    display: flex
    flex-direction: column
    padding: 34.7071px 52.0607px 52.0607px
    gap: 20px

    +breakpoint(small only)
      padding: 30px 40px 30px 40px
      gap: 0px

    &--empty
      padding-bottom: 170px

  &__content-top
    display: flex
    flex-direction: row

    +breakpoint(small only)
      justify-content: flex-end

  &__carousel-buttons-flex
    display: flex
    flex-direction: row
    justify-content: center
    padding-top: 8px
    gap: 8px

  &__carousel-buttons
    flex-grow: 0
    width: 8px
    height: 8px
    border-radius: 100px
    background-color:#06243C
    opacity: 0.25

    &--active
      opacity: 1

  &__content-padding
    display: none
    color: #06243C

    +breakpoint(small only)
      display: block
      font-weight: 600
      font-size: 36px
      line-height: 43.2px
      letter-spacing: -0.03em
      padding-bottom: 20px
