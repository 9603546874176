@mixin unit-card-detail($background, $height, $right, $scale, $translate, $border-top, $text-color) {
  .unit-card__top, .unit-card__bottom {
    background-color: $background;
  }

  .unit-card__detail {
    height: $height;
    right: $right;
    transform: scale($scale);
  }

  &:hover .unit-card__detail {
    transform: translateX($translate) scale($scale);
  }

  .unit-card__top--grid {
    &::before {
      border-top: 1px solid $border-top;
    }
  }

  .unit-card__text,
  .unit-card__name {
    color: $text-color;
  }
}

@mixin programmes-card-detail($background, $background-size, $right, $border-top, $text-color:$white) {

  background-color: $background;

  .programmes-card__detail {
    background-color: $background;
    background-size: $background-size;
    height: $g8*16.25;
    right: $right;
    transform: scale(1.2);
    width: 100%;
  }

  &:hover .programmes-card__detail {
    transform: translateX($g8-3x) scale(1.2);
  }

  .programmes-card__top--grid {
    &::before {
      //border-top: 1px solid $border-top;
    }
  }

  .programmes-card__category, .programmes-card__degree,
  .programmes-card__name, .programmes-card__text, h3, h6 {
    color: $text-color;
  }
}

@mixin small-programmes-card-detail($background, $text-color) {
  background-color: $background;
  color: $text-color;

  .programmes-card-small__left {
    a {
      color: $text-color;
    }
  }

  .programmes-card-small__detail {
    background-color: $background;
  }
}
