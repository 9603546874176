.p-pages__accreditation,
.p-pages__re_accreditation,
.p-pages__accreditation-success,
.p-pages__re-accreditation-success
  .header,
  .footer
    display: none

  .page-wrapper
    top: 0

.accreditation
  +breakpoint(small)
    padding: $g8-4x $g8-3x $g8-10x $g8-3x

  +breakpoint(medium)
    padding: $g8-4x $g8-6x $g8-10x $g8-6x

  +breakpoint(large)
    padding: $g8*8 0

  &__form-logo
    display: none

    img
      height: $g8-8x

    .logos
      display: inline-block

      &:first-child
        margin-right: $g8-10x
        position: relative

        &::after
          background-color: $medium-gray
          bottom: 0
          content: ''
          position: absolute
          right: -$g8-5x
          top: 0
          width: 1px

    +breakpoint(medium)
      display: block
      padding-bottom: $g8-6x

    +breakpoint(large)
      padding-bottom: $g8-8x
      padding-left: $g8-7x

  &__heading
    margin-bottom: $g8*11
    +pseudo-heading-bar(map-get($accent-colors, yellow))

    +breakpoint(large)
      margin-bottom: $g8*13
      padding-left: $g8*11

      &::before
        left: $g8-5x

  &__form
    +breakpoint(large)
      padding: 0 $g8-7x 0 $g8*11

    form
      padding: 0

    .form__error
      background: map-get($accent-colors, pale-red)
      border: rem_calc(1) solid $input-error-color
      color: $input-error-color

      &.hidden
        display: none

  &__form-section
    padding-bottom: $g8-3x

    +breakpoint(large)
       padding-bottom: $g8-5x

    .padding-right
      +breakpoint(large)
        padding-right: $g8-3x

    select
      height: rem-calc(59)

    &--designations
      .input-container.checkbox
        margin-bottom: $g8+$g8-half
        min-height: $g8-4x

    &--declaration
      label.boolean
        line-height: $g8-3x

  &__form-heading
    +orange-heading-underline

  &__form-text
    color: $gray

    &.has-error
      color: $alert-color

  textarea
    margin-bottom: 0

  &__form-helper-text
    color: $gray
    font-size: $tiny-font-size
    line-height: $g8-3x
    margin-bottom: $g8-2x
    position: relative
    top: rem-calc(-13)

  .data-protection-notice__field
    height: $g8*40

  &__decoration
    +background-image(no-repeat, cover, left, center)
    background-image: url('./../../images/backgrounds/accreditation-decoration-mobile.svg')
    bottom: 0
    height: $g8-5x
    left: 0
    position: absolute
    right: 0
    width: 100%

    +breakpoint(large)
      background-image: url('./../../images/backgrounds/accreditation-decoration.svg')
      background-position: top center
      height: 105%
      width: $g8-5x

  &__next
    border-left: 2px solid $medium-gray
    font-weight: 300
    margin-top: $g8-3x
    padding-left: $g8-3x

    p
      font-size: $small-font-size

  &__further-details
    display: none
    background-color: $gray
    padding: $g8-4x

    &-heading
      color: $white
      font-size: $small-font-size
      font-weight: 300
      letter-spacing: $letter-spacing
      margin-bottom: $g8-5x
      text-transform: uppercase

    &-text
      color: $white
      font-size: $tiny-font-size
      line-height: $g8-3x
      margin-bottom: $g8-2x
      position: relative
      top: rem-calc(-13)

    .toggle
      color: $white
      margin-top: $g8-3x
      margin-bottom: $g8-3x

      .toggle-message
        display: none !important

  .custom-input-spacer
    padding-bottom: rem-calc(7)
