.hero
  .swiper-container
    height: auto

.hero-carousel
  margin-top: $navigation-height

  .swiper-pagination
    align-items: flex-start
    display: flex
    height: $g8-5x
    justify-content: flex-end
    padding-right: $g8-3x
    position: absolute
    top: $g8-3x

    +breakpoint(nav-header)
      align-items: center
      flex-direction: column
      height: 100%
      justify-content: center
      left: $g8-5x
      padding: 0
      width: $g8-5x

    span.swiper-pagination-bullet
      background-color: transparent
      border: 2px solid $white
      height: $g8*1.5
      width: $g8*1.5

      +breakpoint(nav-header)
        width: $g8-3x
        height: $g8-3x

      &.swiper-pagination-bullet
        background-color: transparent
        opacity: 1

      &:not(:last-child)
        margin-bottom: $g8-6x

      &.swiper-pagination-bullet-active
        background-color: $white

  +breakpoint(nav-header)
    margin-top: 0

  .swiper-container
    background-color: $black-3

    +breakpoint(large)
      height: 100%

  .swiper-slide
    +background-image(no-repeat, cover, center, center)
    height: auto

    &.swiper-slide-active.swiper-slide-duplicate-next.swiper-slide-duplicate-prev
      height: auto !important

    &.swiper-slide-active
      height: auto !important

    &:before
      +background-image(no-repeat, cover, top, center)
      background-image: linear-gradient(158.37deg, $black -6.62%, rgba($black, 0) 86.45%)
      bottom: 0
      content: ''
      display: block
      left: 0
      position: absolute
      right: 0
      top: 0

      +breakpoint(small only)
        background-image: linear-gradient(158.37deg, $black -6.62%, rgba($black, 0) 86.45%)

  .slide-details
    align-items: center
    color: $white
    display: flex
    position: relative
    padding: $g8-6x $g8-2x

    +breakpoint(nav-header)
      padding: $g8-10x $g8-4x

    &__text
      flex: 0 0 auto

      +breakpoint(medium)
        padding: $g8-7x 0

      h1
        line-height: 120%

      p
        font-size: $medium-font-size
        font-style: normal
        font-weight: 300
        line-height: $g8-3x
        margin: $g8-4x 0

        +breakpoint(large)
          font-size: $large-font-size
          line-height: $g8-4x
          margin: $g8-5x 0

      a
        margin-bottom: $g8-3x
        margin-top: $g8-3x

      .button
        min-width: $g8*18

    &__image
      border-radius: 100%
      border: 2px solid rgba(247, 247, 248, .2)
      display: none
      padding: $g8-2x

      img
        padding: $g8-2x

      +breakpoint(large)
        display: block
        max-height: $g8*63
        max-width: $g8*63

    &__forground
      +background-image(no-repeat, cover, center, center)
      border-radius: 100%
      height: $g8*63
      max-width: none
      width: $g8*63
