.p-programmes-enrolments
  .page-wrapper
    top: 0 !important
    overflow: hidden

  .top-bar
    background-color: $white

    +breakpoint(large)
     background-color: transparent

  .primary-navigation
    display: none

  .primary-navigation-trigger
    display: none

  .logo
    background-image: url('./../../images/logos/logo-color.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-white.svg') !important

  .logo-secondary
    background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

  .close-button
    display: none

  .sign-up-feature
    background:  url('../../images/backgrounds/signup-background.jpg')
    +background-image(no-repeat, cover, left, center)
    height: 100vh

  .scrollable
    height: 100vh
    overflow-y: scroll

    +breakpoint(medium down)
      .padding-y-nav-height
        padding-top: $g8*13

  .footer
    display: none
