.course-card
  display: flex
  flex-direction: column
  align-items: flex-start
  box-shadow: $card-box-shadow
  padding: $g8-3x
  position: relative
  box-sizing: border-box
  height: $g8*26
  $block: &

  &__graphic
    +background-image(no-repeat, cover, center, center)
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: $g8-6x

  &__title
    padding-top: $g8
    padding-left: $g8-6x
    max-width: 100%

  &__cta
    position: absolute
    bottom: 4rem
    right: 1rem

  &__course_name
    h4
      font-family: $font-stack-poppins
      font-style: normal
      font-weight: 300
      font-size: $small-font-size
      letter-spacing: $letter-spacing

  &__button-position
    position: absolute
    bottom: $g8-2x
    right: $g8-2x

  .button-round
    align-items: center
    border-radius: 100%
    display: inline-flex
    height: $g8-6x
    justify-content: center
    width: $g8-6x
    @include transition(all 0.3s ease-in-out)

    &:after
      display: block
      content: ''
      width: $g8-3x
      height: $g8-3x
      background-size: contain
      background-repeat: no-repeat
      background-position: center center

    +breakpoint(medium)
      &:hover
        transform: rotate(180deg)
        @include transition(all 0.3s ease-in-out)

  &--add
    background-color: $pale-orange
    height: $g8*37.5

    +breakpoint(large)
      height: $g8*42.25

    #{$block}__graphic
      background-image: url('../../images/backgrounds/module-banner-add-desktop.svg')

    #{$block}__button-position
      .button-round
        background-color: $secondary-color

        &:after
          background-image: url('../../images/icons/add-icon.svg')
          background-size: 80%

  &--re-register
    background-color: $pale-blue
    height: $g8*37.5

    +breakpoint(large)
      height: $g8*31.25

    .course-card__graphic
      background-image: url('../../images/backgrounds/module-banner-register-desktop.svg')

    #{$block}__button-position
      .button-round
        background-color: $primary-color

        &:after
          background-image: url('../../images/icons/re-register-icon.svg')
          position: relative
          right: -2px
