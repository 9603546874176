.data-protection-notice__heading
  +orange-heading-underline
  margin-bottom: $g8-4x

.data-protection-notice ::-webkit-scrollbar
  width: $g8

.data-protection-notice ::-webkit-scrollbar-track
  background: $medium-gray

.data-protection-notice ::-webkit-scrollbar-thumb
  background: $secondary-color

.data-protection-notice ::-webkit-scrollbar-corner
  background-color: $medium-gray

.data-protection-notice__field
  background-color: $lightest-gray
  height: $g8*24
  overflow-y: scroll
  padding: $g8-3x
  scroll-behavior: smooth
  text-align: left

.data-protection-notice__field p, .data-protection-notice__field ul
  font-size: $tiny-font-size
  line-height: $g8-2x+$g8-half

.data-protection-notice__field ul
  margin-bottom: $g8-2x

.verifyContainer
  margin: $g8-2x 0
  position: relative

abbr
  display: none

input[type='checkbox'][disabled] ~ label
  color: $darkest-gray
  cursor: default
  opacity: 0.3
  pointer-events: none

.data-protect-input label
    line-height: $g8-3x !important
