.cookie-bar
  background-color: $gray
  border-top: rem-calc(4) solid $light-orange
  bottom: 0
  color: $white
  position: fixed
  width: 100%
  z-index: 101

  &__grid
    +breakpoint(medium only)
      text-align: center

  &__link
    color: $dark-gray

  .cell
    padding: $g8 0

    +breakpoint(large)
      padding: 0 $g8-2x

  p, a
    font-size: $tiny-font-size
