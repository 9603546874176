.cpd-landing-cards

  &__wrapper
    display: flex
    gap: 10px
    flex-direction: column

  &__card-image
    width: 240px
    height: 152px

  &__card-image-1
    background-image: url('./../../../javascript/images/backgrounds/cpd_card_1.svg')

  &__card-image-2
    background-image: url('./../../../javascript/images/backgrounds/cpd_card_2.svg')

  &__card-image-3
    background-image: url('./../../../javascript/images/backgrounds/cpd_card_3.svg')

  &__card-image-4
    background-image: url('./../../../javascript/images/backgrounds/cpd_card_4.svg')

  &__card-image-5
    background-image: url('./../../../javascript/images/backgrounds/cpd_card_5.svg')

  &__top
    display: flex
    flex-direction: row
    gap: 10px

    +breakpoint(small only)
      flex-direction: column

  &__top-text
    flex-grow: 1
    color: #6F7889
    font-weight: 400
    font-size: 12px
    line-height: 150%

  &__top-button-wrapper
    display: flex
    flex-direction: row
    gap: 10px
    flex-grow: 0

  &__top-button-responsive
    display: none

    +breakpoint(small only)
      display: block
      flex-grow: 1

  &__top-button-left-disabled
    background-image: url('../../images/icons/cpd-disabled-left-arrow.svg')
    border-radius: 100px
    width: 40px
    height: 40px
    text-align: center
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)

  &__top-button-right-disabled
    background-image: url('../../images/icons/cpd-disabled-right-arrow.svg')
    border-radius: 100px
    width: 40px
    height: 40px
    text-align: center
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)

  &__top-button-left
    background-image: url('../../images/icons/cpd-enabled-left-arrow.svg')
    border-radius: 100px
    width: 40px
    height: 40px
    text-align: center
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)

  &__top-button-right
    background-image: url('../../images/icons/cpd-enabled-right-arrow.svg')
    border-radius: 100px
    width: 40px
    height: 40px
    text-align: center
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)

  &__top-button-left:hover
    cursor: pointer

  &__top-button-right:hover
    cursor: pointer

  &__carousel
    display: flex
    flex-direction: row
    gap: 20px
    padding: 10px
    overflow-y: hidden
    scroll-behavior: smooth
    scrollbar-width: none
    -ms-overflow-style: none
    scrollbar-width: none

  &__carousel::-webkit-scrollbar
    display: none

  &__card
    display: flex
    flex-direction: column
    align-items: flex-start
    max-width: 240px
    border-radius: 14px 14px 14px 14px
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)

    &:hover
      cursor: pointer
      color: rgb(0, 49, 102)

  &__card-body
    display: flex
    flex-direction: column
    gap: 10px
    padding: 10px 30px 10px 30px

  &__card-body-title
    font-size: 18px
    letter-spacing: -0.03em
    line-height: 120%
    font-weight: 500

  &__card-body-summary
    font-size: 12px
    line-height: 150%
    font-weight: 400
    color: #6F7889

  &__card-body-go-flex
    display: flex
    flex-direction: row

  &__card-body-go-padder
    flex-grow: 1
    order: 1

  &__card-body-go
    color: #ED6E56
    flex-grow: 0
    order: 2
    font-size: 16px
    letter-spacing: -0.03em
    line-height: 19.2px
    font-weight: 500

  &__dots-container
    display: flex
    flex-direction: row
    justify-content: center
    align-content: center
    gap: 15px

    +breakpoint(small only)
      display: none

  &__scroller-dot
    width: 8px
    height: 8px
    background-color: #06243C
    opacity: 0.25
    border-radius: 25px
