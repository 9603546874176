.cpd-annual-returns
  font-family: 'Poppins'
  color: #06243C

  &__flex-wrapper
    display: flex
    flex-direction: column
    gap: 60px
    padding: 0px 180px

    +breakpoint(small only)
      padding: 0px 10px
      gap: 10px

  &__header-text
    font-size: 58px
    font-weight: 600
    color: #06243C

    +breakpoint(small only)
      font-size: 36px

  &__content-wrapper
    display: flex
    flex-direction: column
    gap: 30px
    box-shadow: 0px 3px 4px rgba(6, 36, 60, 0.07), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    border-radius: 14px 14px 30px 30px
    justify-content: center
    align-items: center
    background-color: #ffffff

    +breakpoint(small only)
      gap: 0px
      border-radius: 14px 14px 10px 10px

  &__top-bar
    height: 20px
    width: 100%
    background-image: url('../../images/backgrounds/grey-top-bar.svg')
    border-radius: 0px 10px 0px 0px

  &__content-body-form
    display: flex
    flex-direction: column
    gap: 30px
    padding: 20px 50px

    +breakpoint(small only)
      gap: 0px
      padding: 10px

  &__content-body
    display: flex
    flex-direction: column
    gap: 30px
    padding: 20px 50px

    +breakpoint(small only)
      gap: 20px
      padding: 10px

  &__content-body-top-text-mobile
    display: inline
    font-size: 16px
    font-weight: 300

    +breakpoint(small only)
      font-size: 10px
      display: block

  &__content-body-top-text
    font-size: 14px
    font-weight: 300

    +breakpoint(small only)
      font-size: 10px

  &__content-body-heading-box
    display: flex
    flex-direction: row
    gap: 100px
    height: 42px

  &__content-body-heading-box-form
    display: flex
    flex-direction: row
    height: 42px

  &__content-body-heading-box-left
    border-bottom: 4px solid #ED6E56
    font-weight: 500
    font-size: 14px
    flex-grow: 2

    +breakpoint(small only)
      font-size: 12px

  &__content-body-heading-box-right
    display: flex
    flex-direction: row
    justify-content: flex-end
    border-bottom: 4px solid #ED6E56
    flex-grow: 2

  &__content-body-heading-box-right-text
    font-weight: 500
    font-size: 14px

    +breakpoint(small only)
      font-size: 12px

  &__content-body-white-bar-form
    background-color: #ffffff
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 15px 10px

  &__content-body-grey-bar-form
    background-color: #F2F3F5
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 15px 10px

  &__content-body-white-bar
    background-color: #ffffff
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 15px 10px
    gap: 100px

  &__content-body-grey-bar
    background-color: #F2F3F5
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 15px 10px
    gap: 100px

  &__content-body-left
    font-weight: 500
    font-size: 14px
    flex-grow: 2
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__content-body-right
    font-weight: 500
    font-size: 14px
    flex-grow: 2
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__content-body-left-form
    font-weight: 500
    font-size: 14px
    flex-grow: 0
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__content-body-right-form
    justify-content: right
    display: flex
    flex-direction: row
    font-weight: 500
    font-size: 14px
    color: #06243C
    flex-grow: 0

  &__content-body-bottom-text
    font-weight: 300
    font-size: 14px

    +breakpoint(small only)
      font-size: 10px

  &__button-container
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: flex-start

  &__button
    color: #ffffff
    background-color: #ED6E56
    border-radius: 6.2px
    padding: 12px 25px
    font-weight: 500

    +breakpoint(small only)
      padding: 10px
      width: 100%
      justify-content: center
      display: flex

  &__button:disabled
    background-color: $medium-gray
    cursor: not-allowed !important
    color: #ffffff !important

  &__button:hover
    cursor: pointer
    color: #003977

  &__content-body-top-text-bottom
    font-weight: 600
    font-size: 14px

    +breakpoint(small only)
      font-size: 12px

  &__content-body-top-text-container
    display: flex
    flex-direction: column
    gap: 15px

  &__content-body-heading-box-text
    font-weight: 500
    font-size: 14px
    border-bottom: 4px solid #ED6E56

    +breakpoint(small only)
      font-size: 12px

  &__content-body-heading-box-text-left
    font-weight: 500
    font-size: 14px

    +breakpoint(small only)
      font-size: 12px

  &__content-body-top-bar
    background-color: #6F7889
    display: flex
    flex-direction: row
    padding: 15px 10px
    justify-content: space-between

  &__content-body-bottom-container
    display: flex
    flex-direction: row
    gap: 20px

    +breakpoint(small only)
      padding: 10px

  &__content-body-bar-left
    display: flex
    flex-direction: row
    justify-content: flex-start
    flex-grow: 2

  &__content-body-bar-left-form
    display: flex
    flex-direction: row
    justify-content: flex-start
    width: 33.33%

  &__content-body-bar-right
    display: flex
    flex-direction: row
    justify-content: flex-end
    flex-grow: 2

  &__content-body-bar-right-form
    display: flex
    flex-direction: row
    justify-content: flex-end
    width: 33.33%

  &__content-body-top-bar-text
    font-weight: 500
    font-size: 14px
    color: #ffffff

    +breakpoint(small only)
      font-size: 12px

  &__content-body-top-bar-text-right
    font-weight: 500
    font-size: 14px
    color: #ffffff

  &__content-body-table
    display: flex
    flex-direction: column
    gap: 20px

  &__checkbox
    width: 30px
    height: 30px
    background-color: #E5E5E5
    border-radius: 7px

  &__checkbox-text
    color: #06243C
    font-weight: 300
    font-size: 14px

    +breakpoint(small only)
      font-size: 8px

  &__confirmation
    flex-direction: column
    padding: 20px 40px
    gap: 25px

    +breakpoint(small only)
      padding: 20px
      gap: 22px

  &__confirmation-content
    display: flex
    flex-direction: row
    justify-content: space-between
    gap: 40px

    +breakpoint(small only)
      flex-direction: column
      gap: 28px

  &__confirmation-content-left
    display: flex
    flex-direction: column
    gap: 10px

  &__confirmation-content-right
    display: flex
    flex-direction: column
    gap: 10px

  &__confirmation-button-container
    display: flex
    flex-direction: row
    justify-content: flex-end

  &__confirmation-content-middle
    border: 3px solid #ED6E56

  &__content-body-heading-box-left-form
    display: flex
    flex-direction: row
    width: 33.33%
    border-bottom: 4px solid #ED6E56
    justify-content: flex-start

  &__content-body-heading-box-middle-form
    display: flex
    flex-direction: row
    width: 33.33%
    justify-content: flex-end

  &__content-body-heading-box-right-form
    display: flex
    flex-direction: row
    width: 33.33%
    justify-content: flex-end

  &__confirmation-content-left-heading
    font-size: 20px
    font-weight: 500
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__confirmation-content-left-text
    font-size: 16px
    font-weight: 300
    color: #06243C

    +breakpoint(small only)
      font-size: 10px

  &__confirmation-content-right-heading
    font-size: 20px
    font-weight: 600
    color: #06243C

    +breakpoint(small only)
      font-size: 14px

  &__content-body-top-text-form
    font-weight: 300
    font-size: 12px

    +breakpoint(small only)
      display: flex
      flex-direction: column
      gap: 7px

  &__content-body-right-wrapper
    display: flex
    flex-direction: row
    justify-content: flex-end

  &__annual-warning-container
    flex-direction: column
    margin-left: 2px solid orange
    padding: 20px

  &__cancel
    display: flex
    flex-direction: row
    justify-content: center
    width: 49%
    border-radius: 6.2px
    align-items: center
    color: #ED6E56
    padding: 12px
    border: 1.7px solid #ED6E56

  &__cancel:hover
    color: #003166
    cursor: pointer

  &__buttons-container
    justify-content: space-between

  &__submit-anyway
    border-radius: 6.2px
    background-color: #ED6E56
    width: 49%
    padding: 18px 27px
    color: #ffffff

  &__submit-anyway:hover
    color: #003166
    cursor: pointer

  &__disabled-submit-anyway
    background-color: #EBEBE4
    pointer-events: none

  &__try-again
    padding: 18px 27px
    gap: 6.2px
    background-color: #ED6E56
    font-size: 14px
    color: #ffffff
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    width: 100%

  &__try-again:hover
    color: #003166
    cursor: pointer
