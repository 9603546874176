.p-programmes, p-programmes_lookup_error
  .programmes
    &__search
      &__heading
        font-style: normal
        font-weight: 300
        font-size: 16px
        line-height: 160%
        letter-spacing: -0.01em
        color: #6F7889
        margin-left: 12px

    .filter
      span
        font-size: rem-calc(13)
        font-weight: 600
        font-family: $font-stack-poppins

      img
        margin-left: $g8
        margin-bottom: rem-calc(5)

    &.grid-container
      margin-top: 7rem

      +breakpoint(medium)
        margin-top: 7rem

    &__title
      margin-bottom: $g8-7x

    section
      margin-bottom: $g8-10x

    .is-active:hover
      padding: 10px 25px

    .button
      margin-bottom: $g8

    section
      &.programmes__filters
        margin-bottom: $g8-6x
        .cell
          padding: 0 $g8-half

  .sign-up-feature
    height: 50vh

    +breakpoint(large)
      height: 100vh

    &.application-success,
    &.enrolment-success,
    &.member-success
      &:before
        +background-image(no-repeat, cover, left, center)
        bottom: 0
        content: ''
        display: block
        left: 0
        position: absolute
        right: 0

        +breakpoint(small)
          background-image: url('../../images/backgrounds/grid-background-hero-small.svg')
          height: 50vh

        +breakpoint(large)
          background-image: url('../../images/backgrounds/grid-background-hero.svg')
          height: 100vh
          width: 50%

    &.application-success,
    &.enrolment-success
      .sign-up-feature__content
        margin: 0 0 15% $g8-2x

        +breakpoint(large)
          margin: 0 0 rem-calc(150) 0

    &.member-success
      .sign-up-feature__content
        margin: 0 0 5% $g8-2x

        +breakpoint(large)
          margin: 0 0 rem-calc(150) 0

    &.sign-up-success
      +background-image(no-repeat, cover, left, center)
      background-image: url('../../images/backgrounds/application-cancellation-background.jpg')
      position: relative

      &:after
        +background-image(no-repeat, cover, center, center)
        background-image: url('../../images/backgrounds/grid-background-hero.svg')
        bottom: 0
        content: ''
        left: 0
        position: absolute
        right: 0
        top: 0

      h1
        padding: 0 0 20% 20%
        z-index: 999

    &.application-success,
    &.enrolment-success
      background: url('../../images/backgrounds/application-success-background.jpg') left center /cover

    &.member-success
      background: url('../../images/backgrounds/member-success-background.jpg') left center /cover

    &.application-cancellation
      background: url('../../images/backgrounds/application-cancellation-background.jpg') left center /cover
      position: relative

      &:after
        @extend .sign-up-success, :after

    &__content
      h1
        hyphens: auto
        line-height: 120%
        margin-bottom: 0

      h3
        margin: $g8-7x 0 $g8-3x 0

    &__decoration
      +background-image(no-repeat, cover, left, center)
      background-image: url('./../../images/backgrounds/modal-decorator-mobile.svg')
      height: $g8-6x
      position: absolute
      top: $g8-10x
      width: 100%

      +breakpoint(large)
        background-image: url('./../../images/backgrounds/success-side-banner.svg')
        background-position: top center
        height: 100%
        right: 0
        top: 0
        width: $g8-6x

.awarding-body-logo
  width: auto

#awarding-body
  display: flex
  align-items: center
