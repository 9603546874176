.p-news__index
  .page-wrapper
    top: 0

  .slide-details__text
    padding-top: $g8-9x

  .filters
    +breakpoint(small only)
      padding: 0 $g8-2x

    .cell
      padding: 0 $g8-half
