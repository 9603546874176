.glossary-actions
  border-radius: 14px 14px 50px 14px
  display: flex
  padding: 40px
  align-items: flex-start
  gap: 20px
  align-self: stretch
  background-color: $white
  +breakpoint(medium only)
    gap: 5px
    justify-content: stretch
  +breakpoint(small only)
    flex-direction: column
    align-items: center
    padding: 20px 20px 40px 20px
    gap: 10px

  &__button
    display: flex
    align-items: center
    border-radius: 7px
    padding: 20px 20px 20px 30px
    border: 2px solid map-get($iob-color-palette, orange)
    height: 50px
    width: 217.5px

    &--download
      @extend .glossary-actions__button
      color: map-get($iob-color-palette, orange)
      background: url(./../../images/icons/down-arrow-with-line.svg) 90% 50% no-repeat $white
      &:focus, &:hover
        color: map-get($iob-color-palette, orange)
        background: url(./../../images/icons/down-arrow-with-line.svg) 90% 50% no-repeat $white


    &--find-out
      @extend .glossary-actions__button
      color: $white
      background: url(./../../images/icons/arrow_right.svg) 80% 50% no-repeat map-get($iob-color-palette, orange)
      &:focus, &:hover
        color: $white
        background: url(./../../images/icons/arrow_right.svg) 80% 50% no-repeat map-get($iob-color-palette, orange)
