.cpd-event
  color: #06243C

  &__page-wrapper
    display: flex
    flex-direction: column
    gap: 60px
    padding: 0px 180px

    +breakpoint(small only)
      padding: 0px 10px
      gap: 10px

  &__top-text
    display: flex
    flex-direction: column
    gap: 20px

    +breakpoint(small only)
      gap: 10px

  &__top-text-header
    font-size: 58px
    font-weight: 600
    color: #06243C

    +breakpoint(small only)
      font-size: 36px

  &__top-text-body-weak
    font-weight: 300
    font-size: 16px
    color: #6F7889

    +breakpoint(small only)
      font-size: 10px

  &__top-text-body-strong
    font-weight: 600
    font-size: 16px
    display: inline
    color: #6F7889

    +breakpoint(small only)
      font-weight: 300
      font-size: 10px

  &__content-flex
    display: flex
    flex-direction: row
    gap: 50px
    min-width: 710px

    +breakpoint(small only)
      flex-direction: column
      gap: 40px
      min-width: 230px
      padding-top: 30px

  &__content-left
    box-shadow: 0px 3px 4px rgba(6, 36, 60, 0.07), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    border-radius: 8.8px 8.8px 31.6px 8.8px
    padding: 0px 0px 5px 0px
    gap: 17.75px
    display: flex
    flex-direction: column
    width: 50%
    height: fit-content
    background-color: #ffffff

    +breakpoint(small only)
      width: auto
      min-width: 277px

  &__top-bar-left
    height: 20px
    background: url('../../images/backgrounds/cpd-grey-top-bar.svg')
    border-radius: 0px 8.8px 0px 0px
    background-size: cover

  &__top-bar-right
    height: 20px
    background: url('../../images/backgrounds/cpd-orange-top-bar.svg')
    border-radius: 0px 8.8px 0px 0px
    background-size: cover

  &__content-left-container
    padding: 0px 27px 0px 27px
    display: flex
    flex-direction: column
    gap: 17.75px
    min-width: 280px

  &__content-left-header
    font-weight: 600
    font-size: 21.3px
    color: #06243C

    +breakpoint(small only)
      font-size: 18px

  &__content-left-component
    a
      color: #ffffff
      border-radius: 6.2px
      background-color: #ED6E56

    a:hover
      border-radius: 6.2px
      cursor: pointer
      color: #003166
      background-color: #ED6E56

  &__content-right
    box-shadow: 0px 3px 4px rgba(6, 36, 60, 0.07), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    border-radius: 12.4275px 12.4275px 44.384px 12.4275px
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, #FFFFFF 100%)
    padding: 0px 0px 25px
    gap: 17.75px
    display: flex
    flex-direction: column
    width: 50%
    height: fit-content
    min-width: 480px

    +breakpoint(small only)
      width: auto
      min-width: 230px

  &__content-right-container
    padding: 0px 26.63px
    display: flex
    flex-direction: column
    gap: 17.75px

  &__content-right-header
    font-weight: 600
    font-size: 21.3px
    color: #06243C

    +breakpoint(small only)
      font-size: 18px

  &__content-right-body
    font-weight: 300
    font-size: 14.2px

    +breakpoint(small only)
      font-size: 8px

  &__content-right-components
    display: flex
    flex-direction: row
    gap: 17.75px

    +breakpoint(small only)
      justify-content: space-between

  &__content-right-accreditation
    padding: 18px 27px
    gap: 6.2px
    background-color: #ED6E56
    font-size: 14px
    color: #ffffff
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    width: 50%

    +breakpoint(small only)
      padding: 10px
      font-size: 10px
      white-space: pre-wrap

  &__content-right-re-accreditation
    padding: 18px 27px
    gap: 6.2px
    border: 1.7px solid #ED6E56
    font-size: 14px
    color: #ED6E56
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    width: 50%
    display: flex
    justify-content: center

    +breakpoint(small only)
      padding: 10px
      font-size: 10px
      white-space: pre-wrap

  &__search-button
    background-color: #ED6E56
    border-radius: 6.2px
    padding: 13.3px 17.7px 13.3px 26.6px
    font-weight: 500
    font-size: 14px
    color: #ffffff

    +breakpoint(small only)
      width: 100% !important

  &__record-container
    display: flex
    flex-direction: row

    #cpd_event_by_ref_number_ref_number
      background: none
      border: 0.949793px solid #C4C4C4
      border-radius: 4.4px

    #cpd_event_by_ref_number_year
      background: none
      border: 0.949793px solid #C4C4C4
      border-radius: 4.4px


  &__record-year
    border-radius: 4.4px
    flex-grow: 1

    label:first-of-type
      left: 10px
      color: #6F7889
      font-size: 10px
      font-weight: 400

  &__record-number
    border-radius: 4.4px
    flex-grow: 1

    label:first-of-type
      left: 10px
      color: #6F7889
      font-size: 10px
      font-weight: 400

  &__record-dash
    padding: 0px 7px
    top: 18px
    position: relative

  &__accreditation-code
    color: #06243C
    font-weight: 300
    font-size: 14px
    padding-bottom: 17.75px

    +breakpoint(small only)
      font-size: 12px

  &__form-bold
    font-size: 14px
    font-weight: 500
    color: #06243C
    display: inline
    +breakpoint(small only)
      font-size: 10px
    .header
      padding-bottom: 35px
      font-size: 10px

  &__form-light
    font-size: 14px
    font-weight: 300
    color: #06243C
    display: inline
    +breakpoint(small only)
      font-size: 10px

  &__form-content
    display: flex
    flex-direction: column
    gap: 35px
    justify-content: space-between
    +breakpoint(small only)
      gap: 20px

  &__form-container
    #cpd_record_event_date_day
      background-color: white
      border-radius: 7px
      border: 1.5px solid #F2F3F5
      padding-left: 0px
      padding-right: 35%
      color: #6F7889
      +breakpoint(small only)
        width: 100%
        text-align: right
        padding-right: 41%
        font-size: 14.5px

    #cpd_record_event_date_month
      background-color: white
      border-radius: 7px
      border: 1.5px solid #F2F3F5
      padding-left: 0px
      padding-right: 35%
      color: #6F7889
      +breakpoint(small only)
        width: 100%
        text-align: right
        padding-right: 36%
        font-size: 14.5px

    #cpd_record_event_date_year
      background-color: white
      border-radius: 7px
      border: 1.5px solid #F2F3F5
      padding-left: 0px
      padding-right: 20%
      color: #6F7889
      +breakpoint(small only)
        width: 100%
        padding-right: 33%
        text-align: right
        font-size: 14.5px

    #cpd_record_event_venue_label
      background-color: white
      border-radius: 7px
      border: 1.5px solid #F2F3F5
      color: #6F7889

  &__date-box
    flex-flow: row
    .grid-x
      flex-flow: row
    .xlarge-2
      width: fit-content
      padding-right: 10px
    .date-selector__day
      width: 22%
      +breakpoint(small only)
        width: 26%
    .date-selector__month
      +breakpoint(small only)
        width: 30% !important
    .date-selector__year
      +breakpoint(small only)
        width: 30% !important
    select
      background-image: url('../../images/icons/orange_down_arrow.svg')

  &__event-box
    width: fit-content !important
    padding-right: 10px

  &__searchable-dropdown
    +breakpoint(small only)
      width: 82%
      display: flex
      flex-direction: row
    .searchable-dropdown
      width: 85% !important
      +breakpoint(small only)
        width: 100% !important
    label
      left: 10%
      top: 6px
    input
      background-image: url('../../images/icons/orange_down_arrow.svg')
      background-position: right 1rem top 1.65rem
  &__searchable-dropdown:first-child
    +breakpoint(small only)
      flex-grow: 2

  &__buttons > a
    background-color: white
    border: 1.77536px solid #ED6E56
    border-radius: 6.2px
    color: #ED6E56
    padding: 17.75px 0px
    width: 40%

    +breakpoint(small only)
      width: 45%

  &__buttons > a:hover
    cursor: pointer
    color: #003166
    border-bottom-right-radius: 6.2px
    background-color: #ffffff !important

  &__buttons > a:focus
    background-color: #ffffff !important

  &__buttons > input
    background-color: #ED6E56
    border: 1.77536px solid #ED6E56
    border-radius: 6.2px !important
    color: white
    padding: 17.75px 0px
    width: 40% !important
    height: fit-content

    +breakpoint(small only)
      width: 45% !important

  &__buttons > input:focus
    background-color: #ED6E56

  &__buttons > input:hover
    cursor: pointer
    color: #003166
    background-color: #ED6E56

  &__select-venue
    flex-flow: row

#cpd_event_by_ref_number_supplier
  background: none
  border: 0.949793px solid #C4C4C4
  border-radius: 4.4px
  padding-left: 24px

#cpd_event_by_ref_number_event_name
  background: none
  border: 0.949793px solid #C4C4C4
  border-radius: 4.4px
