.searchable-dropdown
  position: relative
  width: 100%

  .input-container
    &.has-message,
    &.error
      margin-bottom: $g8-6x
      .input-message
        position: absolute
        right: 0

  input
    background-color: $input-background
    background-image: url('./../../images/icons/dropdown-caret.svg')
    background-position: right $g8-3x top $g8-3x
    background-repeat: no-repeat
    background-size: rem-calc(12)
    border: $input-border
    box-shadow: $input-shadow
    height: $input-height
    margin-bottom: 0
    padding: $input-padding
    position: relative
    transition: none !important

    &:focus
      background: $input-background
      border: $input-border
      box-shadow: $input-shadow

    &.no-icon
      background-image: none

.searchable-items
  background-color: $lightest-gray
  border-style: none
  left: 0
  max-height: rem-calc(422)
  overflow-y: scroll
  position: absolute
  right: 0
  top: 100%
  z-index: 99

  &__item
    background-color: $input-background
    cursor: pointer
    padding: $input-padding
    position: relative

    &:not(:last-child)
      &:before
        border: 1px solid $medium-gray
        bottom: rem-calc(-2)
        content: ''
        position: absolute
        width: 90%
        z-index: 100

    &--active, &:hover
      background-color: $dark-blue
      color: $white
      z-index: 101
