.form-step-wrapper
  -ms-overflow-style: none
  flex-wrap: nowrap
  overflow-x: scroll
  scrollbar-width: none

.form-step-wrapper::-webkit-scrollbar
  background: transparent
  width: 0

.form-step__error
    background: map-get($accent-colors, pale-red)
    border: 1px solid $input-error-color
    color: $input-error-color
    display: none
    margin-bottom: $g8-4x
    padding: $g8-2x
    width: 100%
