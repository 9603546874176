.phone-number-input
  margin-bottom: $g8-2x
  position: relative

  input
    border-bottom: rem-calc(3) solid transparent
    margin: 0

    &:disabled
      cursor: not-allowed

  label
    display: none

  [type='tel']:focus
    background-color: $light-gray !important
    border: none
    box-shadow: none
    transition: none

  .error-msg
    color: $alert-color
    font-size: $tiny-font-size
    padding-top: $g8
    text-align: right

  &__error
    border-bottom: rem-calc(3) solid $alert-color !important

  &__valid
    border-bottom: rem-calc(3) solid $success-color !important

  ::-webkit-input-placeholder
    color: $darkest-gray

  ::-moz-placeholder
    color: $darkest-gray

  :-ms-input-placeholder
    color: $darkest-gray

  :-moz-placeholder
    color: $darkest-gray
