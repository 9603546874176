.glossary-term
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  gap: 10px
  padding: 20px 0px
  width: auto
  border-bottom: 1px solid map-get($iob-color-palette, grey)

  &__title
    color: map-get($iob-color-palette, navy)
    font-family: $font-stack-poppins
    font-size: 21px
    font-style: normal
    font-weight: 500
    line-height: 120%
    +breakpoint(medium only)
      font-size: 19px
    +breakpoint(small only)
      font-size: 17px

  &__description
    color: map-get($iob-color-palette, navy)
    font-family: $font-stack-poppins
    font-size: 17px
    font-style: normal
    font-weight: 300
    line-height: 160%
    letter-spacing: -0.16px
    +breakpoint(medium only)
      font-size: 15px
      letter-spacing: -0.14px
    +breakpoint(small only)
      font-size: 13px
      letter-spacing: -0.12px
    & > p
      margin-bottom: 0px

      & > a
        color: map-get($iob-color-palette, orange)
