.error-page
  +background-image(no-repeat, cover, center, center)
  background-image: url('./../../images/backgrounds/error-page-background.svg')
  min-height: calc(100vh - #{$g8-10x})
  width: 100%

  &__404, &__500
    &--subtitle
      margin-left: $g8-2x

  .cell
    +breakpoint(small only)
      padding: 0 $g8-2x

    ul
      +unordered-list-orange
