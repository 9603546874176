.unit-card
  box-shadow: $card-box-shadow
  height: $g8*35
  overflow: hidden
  width: $g8*39

  &:hover .unit-card__detail
    transition: .3s ease-in-out

  &__top
    padding: $g8-2x $g8-3x $g8-2x $g8-3x
    z-index: 3

    &--grid
      &.modules, &.learning_units, &.elearning
        &::before
          border-top: 1px solid $dark-gray
          content: ''
          position: absolute

      &.modules
        &::before
          width: 55%

      &.learning_units, &.elearning
        &::before
          width: 65%

  &__text
    font-size: $small-font-size

  &__bottom
    z-index: 1

    &.clear
      background-color: $lightest-gray !important

      .unit-card__name
        color: $black-3
        padding: $g8-3x 0 0 $g8-3x

    &.color
      .unit-card__name
        padding: 0 $g8-3x

  &__detail
      background-repeat: no-repeat
      background-size: contain
      position: relative
      transition: .3s ease-in-out
      width: 100%

  &__info
    height: $g8*20
    width: 100%
    position: relative

  &__title
    height: $g8*10
    margin-top: $g8

    &--sub
      color: $gray
      font-size: $small-font-size
      font-style: normal
      font-weight: 600
      line-height: rem-calc(20)
      margin-top: $g8-2x
      padding-left: $g8-3x

  &__join
    bottom: 0
    color: $black-3
    margin-bottom: $g8
    padding: $g8-3x
    position: absolute
    width: $g8*33

    &--date
      border-top: 1px solid $dark-gray
      font-size: $tiny-font-size
      font-style: normal
      font-weight: normal
      line-height: $large-font-size
      margin: $g8-3x $g8-2x 0 0
      padding-top: $g8-2x

    &--graphic
      background-image: url('./../../images/icons/play.svg')
      background-repeat: no-repeat
      height: rem-calc(53)
      width: rem-calc(53)

  .unit-card__title--sub + .unit-card__name
    padding-top: 0
