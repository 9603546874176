.about
  hr
    border-bottom: 1px solid $light-gray

  &__header
    margin-bottom: $g8-3x
    padding: $g8-2x

    +breakpoint(medium)
      +background-image(no-repeat, cover, center, center)
      background-image: url('../../images/backgrounds/about-header-background.jpg')
      padding: $g8-2x 0
      position: relative

      &:before
        +background-image(no-repeat, cover, center, center)
        background-image: url('../../images/backgrounds/grid-background.svg')
        bottom: 0
        content: ''
        left: 0
        position: absolute
        right: 0
        top: 0

    +breakpoint(large)
      height: $g8*44
      padding-top: $g8*11

    +breakpoint(xlarge)
      padding-top: $g8*13

    &-title
      align-self: center
      justify-content: center

      +breakpoint(medium)
        color: $white
        text-align: center

      +breakpoint(large)
        align-self: flex-start
        text-align: left

    &-intro
      background-color: $white
      color: $black-3
      position: relative
      white-space: pre-line
      z-index: 99

      +breakpoint(medium)
        padding: $g8-6x
        margin: 0 auto

      +breakpoint(xlarge)
        padding: $g8-10x

  &__main-content
    padding-top: $g8-4x
    position: relative
    z-index: 100

    &:before
      content: ''
      position: absolute
      top: 0
      right: 0
      left: 0

    +breakpoint(large)
      margin-top:  $g8*26

    +breakpoint(xlarge)
      margin-top: $g8*25

    .block-text-card-italic
      white-space: pre-wrap
      +block-text-border-left($secondary-color, $font-stack-poppins, $default-font-size, italic, $gray)

    .about-cards
      padding: $g8-3x 0

      &__single-card
        &:nth-child(2)
          border-bottom: 1px solid $light-gray
          border-top: 1px solid $light-gray
          margin: $g8-3x 0
          padding: $g8-3x 0

          +breakpoint(medium)
            border: none
            margin: 0
            padding: 0

    .values-cards
      padding-bottom: $g8-6x

      &__single-card
        padding-bottom: $g8-5x
        padding-top: $g8-5x

      h3
        width: 80%

      .block-text-card
        white-space: pre-line
        +block-text-border-left($secondary-color, $font-stack-poppins, $medium-font-size, normal, $black)

        &--yellow
          border-left: 3px solid map-get($accent-colors, yellow)

        &--orange
          border-left: 3px solid map-get($accent-colors, orange)

        &--pink
          border-left: 3px solid map-get($accent-colors, pink)

        &--green
          border-left: 3px solid map-get($accent-colors, green)

        &--purple
          border-left: 3px solid map-get($accent-colors, purple)

        &--blue
          border-left: 3px solid map-get($accent-colors, blue)
