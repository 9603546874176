.news-show
  margin-bottom: $g8-10x
  padding-top: 100px
  &:before
    +background-image(repeat, cover, top, center)
    background-image: url('../../images/backgrounds/grid-background-hero.svg')
    bottom: 0
    content: ''
    display: block
    height: 15%
    left: 0
    position: absolute
    right: 0
    top: 0
    z-index: -1

    +breakpoint(medium down)
      +background-image(repeat, contain, top, center)
      background-image: url('../../images/backgrounds/grid-background-hero-small.svg')
      height: 12%

  &__grid
    +breakpoint(xlarge)
      width: 130%

  &__text
    color: $black-3
    margin-top: $g8

    +breakpoint(large)
      margin-top: $g8-10x

    hr
      border-bottom: 1px solid $light-gray
      margin: $g8-2x auto

  &__title
    line-height: 120%

  &__subtitle
    font-size: $small-font-size

  &__content
    color: $black
    margin-bottom: $g8-10x
    position: relative

    p
      font-size: $medium-font-size
      font-weight: 300
      text-align: justify

    blockquote
      +blockquote-news
      display: contents

      +breakpoint(large)
        left: 70%
        padding-left: $g8*37
        position: relative
        width: fit-content

        p
          width: 120%

      +breakpoint(xlarge)
        p
          width: 70%

      +breakpoint(xxlarge)
        left: 80%

    ul
      +unordered-list-orange

    ol
      +ordered-list-orange

    h1, h2, h3, h4, h5, h6
      margin-top: $g8-4x

    img
      +breakpoint(medium)
        margin-bottom: $g8-4x

  &__hero-image
    +background-image(no-repeat, cover, center, center)
    border-radius: 100%
    height: 22rem
    position: relative
    transform: translate(-15%, -10%)
    width: 22rem !important

    +breakpoint(large)
      height: 25rem
      transform: translate(25%, -10%)
      width: 25rem !important

    +breakpoint(xlarge)
      height: 40rem
      transform: translate(5%, -10%)
      width: 40rem !important

    &:after
      @include center-element
      border-radius: 100%
      border: 1px solid $black-3
      content: ''
      height: 24rem
      width: 24rem

      +breakpoint(large)
        height: 27rem
        width: 27rem

      +breakpoint(xlarge)
        height: 42rem
        width: 42rem
