h1, h2, h3, h4, h5, h6
  font-weight: 600

  &.font-poppins
    font-family: $font-stack-poppins

p, span

  &.font-poppins
    font-family: $font-stack-poppins

.text-tiny
  font-size: $tiny-font-size

.text-small
  font-size: $small-font-size

.text-medium
  font-size: $medium-font-size

.text-large
  font-size: $large-font-size

.text-white
  color: $white

.text-black
  color: $black !important

.text-black-3
  color: $black-3

.text-gray
  color: $gray

.text-alert
  color: $alert-color !important

.text-orange
  color: $secondary-color

.heading-rule
  margin-bottom: $g8-3x
  overflow: hidden
  position: relative

  &::after
    border-top: 1px solid $light-gray
    content: ''
    margin-left: $g8-4x
    position: absolute
    top: $g8-2x
    width: 100%
