html.is-reveal-open
  overflow-y: visible

.reveal
  padding: rem-calc(60) 0
  border: none

  +breakpoint(medium)
    padding: $g8-2x 0 $g8-2x $g8-5x

  .close-button
    color: $black-3
    top: rem-calc(70)
    right: rem-calc(20)

    +breakpoint(medium)
      top: rem-calc(20)
      left: initial
      right: rem-calc(20)

.reveal.small
  background-color: $white
  color: $black-3
  +breakpoint(medium)
    width: 80%

  +breakpoint(xlarge)
    width: 50%

.reveal-overlay
  .reveal
    &:focus
      outline: none

.modal-decorator
  +background-image(no-repeat, cover, left, center)
  background-image: url('./../../images/backgrounds/modal_pattern-mobile.svg')
  height: $g8-5x
  left: 0
  position: absolute
  right: 0
  top: 0
  width: 100%

  +breakpoint(medium)
    +background-image(no-repeat, cover, top, center)
    background-image: url('./../../images/backgrounds/modal_pattern.svg')
    height: 100%
    width: $g8-5x

.confirm-modal
  background-color: $white
  color: $black-3
  padding: rem-calc(60) rem-calc(20) rem-calc(30) rem-calc(20)

  +breakpoint(medium)
    padding: rem-calc(60) rem-calc(20) rem-calc(30) 0

  max-width: rem-calc(500)
  margin: 0 auto

.member-modal
  .confirm-modal
    .button
      width: 90% !important

.exit-flow-modal
  position: relative

  +breakpoint(large)
    position: absolute
    top: $g8-4x
    right: $g8-9x
    z-index: 999

  .exit-button
    span
      color: $black-3
      cursor: pointer
      font-size: $xxlarge-font-size
      font-weight: 300
      height: $g8-3x
      outline: none

      +breakpoint(medium down)
        line-height: $g8-4x

      +breakpoint(large)
        display: flex
        align-items: center
        justify-content: center
        position: absolute
