.shopping
  +breakpoint(medium down)
    padding-top: 0

  +breakpoint(nav-header)
    padding-top: $g8-8x

  .loader__container
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: $g8*26

  &__user-actions
    display: flex
    flex-direction: column
    margin-bottom: $g8-5x

    +breakpoint(large)
      flex-direction: row

  &__user-actions--modules

    +breakpoint(medium)
      width: 100%

    +breakpoint(large)
      width: 70%

    &-cpd
      +breakpoint(large)
        padding-right: $g8-4x

    &-modules
      +breakpoint(large)
        padding-left: $g8-4x

    &-wrapper
      display: flex
      flex-direction: column

      +breakpoint(medium)
        flex-direction: row

    .loader__container
      width: $g8*37.5

  &__user-actions--members
    max-width: $g8*50

    +breakpoint(medium down)
      margin-top: $g8-5x

    +breakpoint(large)
      margin-bottom: 0
      width: $g8*51

      .membership-card
        height: $g8*42.25

  &__user-actions, .renewal-fees
    h3
      color: $black-3
      font-size: $large-font-size

  .renewal-fees

    .membership-card
      height: 100%

    .scroller__renewal-fees
      .scroller__wrapper--top
        margin-bottom: 0

  .scroller__title::after
    top: rem-calc(13)

  &__user-title
    padding-top: $g8-8x

  &__user-title--text
   flex: 1 1

+breakpoint(small)
  .user-shopping__navigation--button a
    width: 100%
