.strategy-pillars
  display: flex
  flex-direction: column
  gap: 40px
  padding: 80px
  align-items: center
  background-color: rgba(245, 245, 245, 1)
  +breakpoint(small only)
    gap: 20px
    padding: 20px

  &__content
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1)
    border-radius: 14px
    width: 360px
    background-color: white

    &-container
      display: flex
      flex-direction: row
      gap: 40px
      flex-wrap: wrap
      justify-content: center

  &__header
    font-size: 46px
    font-weight: 600
    color: rgba(6, 36, 60, 1)
    +breakpoint(small only)
      text-align: center
      font-size: 26px

  &__image
    border-radius: 14px 14px 0px 0px
    height: 190px
    color: rgba(255, 255, 255, 1)
    align-items: center
    padding: 15px 30px
    justify-content: center
    display: flex
    background-size: cover !important
    +breakpoint(small only)
      padding: 20px

    &-text
      text-align: center
      +breakpoint(small only)
        padding: 20px
        gap: 25px

  &__text
    font-size: 16px
    font-weight: 300
    color: rgba(6, 36, 60, 1)
    padding: 30px 40px 40px 40px
