.p-memberships,
.p-programmes-reregistrations,
.p-users-cpd__renew,
.p-events__registration
  .page-wrapper
    top: 0 !important

  .top-bar
    background-color: $white

    +breakpoint(large)
      background-color: transparent

  .primary-navigation
    display: none

  .primary-navigation-trigger
    display: none

  .logo
    background-image: url('./../../images/logos/logo-color.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-white.svg') !important

  .logo-secondary
    background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

  .sign-up-feature
    background:  url('../../images/backgrounds/signup-background.jpg')
    +background-image(no-repeat, cover, left, center)
    height: 100vh

  .scrollable
    height: 100vh
    overflow-y: scroll

  .footer
    display: none

  &__form
    .padding-y-nav-height
      padding-top: $g8*13

  .personal-tab, .place-of-work-tab, .direct-debit-tab
    &__toggle
      .toggle-message
        display: none !important

.p-users-cpd__renew
  +breakpoint(medium down)
    .scrollable
      .padding-y-nav-height
        padding-top: $g8*13

.p-events__registration
  background-color: $white !important

  .events--step
    .disabled-input
      &__placeholder
        visibility: hidden

      &__value
        color: $black
        font-weight: 600

      .attendants__remove
        font-size: $tiny-font-size
        text-align: right
