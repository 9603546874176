.input-toggle
  +triangle-checkbox

  .radio,
  .checkbox
    margin: rem-calc(20) 0

  .radio
    label
      color: $black

    &_buttons
      margin-top: $g8-3x

  input[type='radio']
    display: none

  input[type='checkbox'] + label,
  input[type='radio'] + label
    font-size: $medium-font-size
    margin: 0
    padding-left: rem-calc(50)
    position: relative

  input[type='checkbox'] + label:before
    +checkbox-radio-label-before

  input[type='radio'] + label:before
    +checkbox-radio-label-before
    border-radius: 100%

  input[type='radio'] + label:after
    background-color: $secondary-color
    border-radius: rem-calc(50)
    content: ''
    top: rem-calc(9)
    left: rem-calc(9)
    margin: 0
    opacity: 0
    position: absolute
    transition: .3s
    width: rem-calc(14)
    height: rem-calc(14)

  input[type='radio']:checked + label:after
    opacity: 1
    transition: .3s

  abbr
    display: none

  label.radio_buttons.required
    display: none

  label.select.required
    color: $black
    font-size: 0.9em
    min-height: 0

  .input-container.focused:not(.file-field).small label
    font-size: 0.9em

  .input-container
    label
      &::after
        min-height: rem-calc(14)

    &.focused:not(.file-field) label
      min-height: $g8-3x

  .input-container label
    color: $darkest-gray
    transition: none

    .file.required
      color: $black

  .input-container.file-field
    .file
      align-items: center
      .input-selector
        height: $g8-5x !important

  .input-container.file-field .file label
    font-size: $tiny-font-size

  .input-container.small select
    font-size: 0.9em

  .module-message
    align-items: center
    border-left: rem-calc(5) solid $secondary-color
    color: $gray
    display: flex
    font-size: $tiny-font-size
    height: $g8-3x
    margin-left: rem-calc(50)
    margin-top: $g8-2x
    padding-left: $g8-2x
