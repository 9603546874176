@mixin checkbox-radio-label-before {
  background-color: $light-gray;
  content: '';
  height: rem-calc(32);
  left: 0;
  margin: 0;
  position: absolute;
  width: rem-calc(32);
}

@mixin triangle-checkbox {
  input[type='checkbox']{
    display: none;
  }
  label.boolean {
    padding-left: $g8-6x;
    color: $black;
    font-size: $medium-font-size;
  }
  input[type='checkbox'] + label:before {
    background-color: $light-gray;
    content: '';
    height: 2rem;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 2rem;
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
    transition: .3s;
  }

  input[type='checkbox'] + label:after {
    border-bottom: 0.875rem solid $light-secondary;
    border-left: 0.875rem solid transparent;
    content: '';
    height: 0;
    left: $g8;
    opacity: 0;
    position: absolute;
    top: $g8;
    transition: .3s;
    width: 0;
  }
}
