.strategy-our-values
  &__header
    text-align: center

    &-top
      font-size: 46px
      font-weight: 600
      color: #06243c
      +breakpoint(small only)
        font-size: 28p

.strategy-values
  &__container
    display: flex
    flex-direction: column
    padding: 10px 0px
    gap: 20px

  &__body
    display: flex
    justify-content: center
    padding: 10px 10px
    gap: 60px
    width: 100%

  &__icon
    width: 50%

  &__content
    +breakpoint(medium down)
      text-align: center
      width: 100vw
      padding-left: 0px
    +breakpoint(medium only)
      display: flex
      flex-direction: column
      align-items: center
      width: 100vw
      padding-left: 0px
    +breakpoint(large)
      text-align: center
      width: 15%

    &-heading
      font-weight: 600
      font-size: 23px
      color: rgba(6, 36, 60, 1)
      +breakpoint(medium)
        font-size: 18px
      +breakpoint(small)
        font-size: 16px

    &-body
      font-weight: 400
      font-size: 12px

    & > *
      +breakpoint(medium only)
        width: 33vw

  &--mobile
    +breakpoint(large)
      display: none

    & .swiper-pagination
      display: flex
      align-items: flex-end
      justify-content: center
      position: relative
      bottom: 0px
      width: 100vw
      padding-left: 0px

    span.swiper-pagination-bullet
      background-color: transparent
      height: 10px
      width: 10px
      margin: 8px

      &.swiper-pagination-bullet
        background-color: gray
        opacity: 1

      &.swiper-pagination-bullet-active
        background-color: black

  &--desktop
    +breakpoint(medium down)
      display: none
