.back-to-top

  &__top-container
    color: #06243C
    margin-top: 40px
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 10px
    padding-bottom: 60px
    +breakpoint(small only)
      padding-bottom: 20px
    &:hover
      color: #ED6E56

  &__secondary-top-container
    display: flex
    align-items: center
    gap: 10px
    color: #06243C
    &:hover
      color: #ED6E56
      cursor: pointer
    &:hover #hovered-arrow
      display: block
    &:hover #unhovered-arrow
      display: none
