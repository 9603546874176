.primary-navigation-trigger
  position: absolute
  top: 30%
  right: 5%

  +breakpoint(nav-header up)
    display: none

  &__menu-icon
    width: rem-calc(30)
    height: rem-calc(20)
    position: relative

    span
      @include center-element
      background: $white
      border-radius: $prototype-border-radius
      display: block
      height: rem-calc(3)
      transition: .3s
      width: 100%

      &:before
        @include nav-trigger-pseudo
        top: -$g8
        background: $white

      &:after
        @include nav-trigger-pseudo
        top: $g8
        background: $white

    &[data-mobile-menu-active="true"]
      span
        background: transparent
        transition: .3s

        &:before
          @include nav-trigger-pseudo-true
          transform: rotate(225deg)
          background: $black

        &:after
          @include nav-trigger-pseudo-true
          transform: rotate(-225deg)
          background: $black

    .header--is-normal &:not([data-mobile-menu-active="true"])
      span
        background: $black
        transition: .3s
        &:before
          background: $black
        &:after
          background: $black
