.p-programmes-applications
  .close-button
    display: none

  select
    height: rem-calc(59)

  #memberOffCanvas
    background-color: $light-gray
    z-index: 301

    &.position-right
      width: 100vw
      transform: translateX(100vw)

      &.is-open
        transform: translate(0, 0)

      &.is-closed
        width: 0

    .top-bar
      background-color: $light-gray
      box-shadow: 0px 3px 10px rgba(14, 24, 40, 0.1)
      color: $primary-color
      position: -webkit-sticky
      position: sticky
      top: 0
      width: 100%

      .top-bar-left
        font-family: $font-stack-poppins

      .top-bar-right
        font-size: rem-calc(13)
        font-weight: 600
        font-family: $font-stack-poppins

      .top-bar-left, .top-bar-right
        flex: 0 0 auto

        .menu
          background-color: $light-gray

          .menu-text
            padding-right: 0

    .member-content
      margin: rem-calc(30) rem-calc(45)

      h3
        font-family: $font-stack-poppins
        font-weight: normal
        font-style: normal

      ul.styled-list
        li::before
          border-color: $black

  +breakpoint(large)
    .header
      z-index: 1

  +breakpoint(medium down)
    .member-button
      background-color: $light-gray
      color: $primary-color
      font-size: $medium-font-size
      font-weight: 600
      font-family: $font-stack-poppins
      padding: rem-calc(18)
      margin: rem-calc(30) 0
      position: static

      .title-bar-left
        flex: 1 0

      .title-bar-right
        flex: 0 1

  .page-wrapper
    top: 0

  .primary-navigation,
  .primary-navigation-trigger

    +breakpoint(nav-header up)
      display: none

  .top-bar
    background-color: $white

    .logo
        background-image: url('./../../images/logos/logo-color.svg')

    +breakpoint(large up)
      background-color: transparent

      .logo
        background-image: url('./../../images/logos/logo-color.svg')

        +breakpoint(large)
          background-image: url('./../../images/logos/logo-white.svg')

      .logo-secondary
        background-image: url('./../../images/logos/logo-ucd-grey.svg')!important

  .application-feature
    height: 100vh
    background:  url('../../images/backgrounds/signup-background.jpg') left center /cover

  .scrollable
    height: 100vh
    overflow-y: scroll
    z-index: 2

    +breakpoint(medium down)
      .padding-y-nav-height
        padding-top: $g8*13

  .application-content
    h1
      font-family: $font-stack-poppins

    .form-step__selector
      font-weight: 300
      letter-spacing: $letter-spacing
      padding: rem-calc(5) 0
      width: 9.5rem

    .title
      font-size: $small-font-size
      font-weight: 300
      letter-spacing: $letter-spacing
      text-transform: uppercase

    .radio_item
      padding-left: rem-calc(50)
      padding-top: rem-calc(30)

    input[type='submit']
      width: auto

  .footer
    display: none
