.navigation-search
  height: 100%

  .input__clear
    display: none
    margin-top: -5px

  &__input
    background: $light-gray
    height: 50px
    opacity: 0
    overflow: hidden
    position: relative
    // right: 0
    top: 30px
    width: 0
    border-radius: 10px
    border: 1.5px solid #F2F3F5
    box-sizing: border-box
    z-index: 1
    +transition(all 0.2s ease-in-out)

    .input-container
      margin-bottom: 0

    +breakpoint(medium down)
      opacity: 1
      width: 100%
      position: relative

    label
      pointer-events: none

    input
      max-width: $g8*125
      width: 100%
      padding-right: $g8*9

  &__icon
    align-items: center
    cursor: pointer
    display: flex
    height: $g8-7x
    position: relative
    width: 1.6rem

    +breakpoint(medium down)
      background-image: url('../../images/icons/icon-search-black.svg')
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      height: $g8-3x
      position: absolute
      // right: rem-calc(35)
      top: rem-calc(15)
      width: $g8-3x
      z-index: 9999

  +breakpoint(medium down)
    width: 100%

.navigation-search[data-state="active"]
  .input__clear
    display: block

  +breakpoint(medium down)
    .navigation-search__icon
      display: none

  .navigation-search__input
    width: 100%
    opacity: 1
    +breakpoint(medium down)
      width: 100%
    label
      left: 5% !important




