.b-ie
  .paginated-scroller
    &__arrows
      &--left
        background-image: url('./../../images/icons/paginated-scroller-orange-left.svg')

        &.disabled
          opacity: 0.2

      &--right
        background-image: url('./../../images/icons/paginated-scroller-orange-left.svg')
        transform: rotate(-180deg)

        &:hover:not(.disabled)
          background-image: url('./../../images/icons/paginated-scroller-hover-right.svg')
          transform: rotate(-180deg)

        &.disabled
          opacity: 0.2
          transform: rotate(-180deg)

  .input-toggle input[type='radio']:checked + label::after
    top: $g8 !important

  .primary-navigation__user-tools
    display: -ms-flexbox
    order: 0

//Both IE and Edge
.b-ie,
.b-ms-edge
  input::-ms-clear
    display: none !important

  .scroller
    .scroller__arrows
      .disabled
        opacity: 0.5

      &--left,
      &--right
        background-size: 3rem 3rem

        &:hover:not(.disabled)
          transform: scale3d(1, 1, 1)
          transition-delay: 0.1s

      &--left
        background-image: url('./../../images/icons/navigation-light-left.svg')

        &:hover:not(.disabled)
          background-image: url('./../../images/icons/navigation-light-hover-left.svg')

      &--right
        background-image: url('./../../images/icons/navigation-light-right.svg')

        &:hover:not(.disabled)
          background-image: url('./../../images/icons/navigation-light-hover-right.svg')

    &--dark
      .scroller__arrows
        &--left
          background-image: url('./../../images/icons/navigation-dark-left.svg')

          &:hover:not(.disabled)
            background-image: url('./../../images/icons/navigation-dark-hover-left.svg')

        &--right
          background-image: url('./../../images/icons/navigation-dark-right.svg')

          &:hover:not(.disabled)
            background-image: url('./../../images/icons/navigation-dark-hover-right.svg')

    &.scroller__module-card, &.scroller__photogallery
      overflow: hidden

  .my-institute
    .scroller
      &__standard-card, &__unit-card
        overflow: hidden
