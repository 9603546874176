@mixin blockquote-quote {
  border-left: rem-calc(3) solid $secondary-color;
  padding: 0 0 0 $g8-5x;
  @include breakpoint(small only) {
    margin-bottom: $g8-6x;
  }

    p {
      color: $gray;
      font-family: $font-stack-poppins;
      font-size: $medium-font-size;
      font-style: italic;
      line-height: $xlarge-font-size;
      margin-bottom: $g8-3x;
      @include breakpoint(large) {
        font-size: $default-font-size;
      }
    }

    footer {
      color: $black;
      font-family: $font-stack-poppins;
      font-size: $tiny-font-size;
    }
}

@mixin blockquote-success {
  border-left: rem-calc(4) solid $secondary-color;
  margin-bottom: $g8*11;
  padding: 0 0 0 $g8-3x;

    h6 {
      color: $black;
      font-size: $small-font-size;
      font-weight: 300;
      letter-spacing: $letter-spacing;
    }

    p {
      color: $black-2;
      font-family: $font-stack-poppins;
      font-size: $large-font-size;
      font-weight: 300;
      margin-bottom: $g8-3x;
    }
}

@mixin blockquote-news {
  border-left: none;
  padding: 0;
  @include breakpoint(large) {
    border-bottom: rem-calc(1) solid $dark-gray;
    margin-top: -$g8-6x;
  }

    p {
      border-left: rem-calc(4) solid $secondary-color;
      color: $black-2;
      font-family: $font-stack-poppins;
      font-size: $xlarge-font-size;
      font-weight: normal;
      line-height: 120%;
      margin-bottom: $g8-3x;
      padding-left: $g8-3x;
      text-align: left;
    }
}
