[disabled] ~ *
  font-size: inherit

.form
  &.hide-steps
    .form-step-wrapper
      display: none

  .form-step__selector
    border-bottom: rem-calc(5) solid $light-gray
    overflow: hidden
    padding: rem-calc(5) 0
    white-space: nowrap
    width: rem-calc(50)
    word-wrap: break-word
    @include transition(all 0.3s ease-in-out)

    .selector__text
      font-size: $small-font-size
      font-weight: 300
      letter-spacing: $letter-spacing
      opacity: 0
      @include transition(all 0.1s ease-in)

    &.completed
      border-color: $light-orange

    &.active
      width: auto
      padding: $g8 $g8-half
      border-color: $secondary-color

      .selector__text
        opacity: 1
        @include transition(all 0.3s ease-out)
        -webkit-transition-delay: 0.2s
        -moz-transition-delay: 0.2s
        -ms-transition-delay: 0.2s
        -o-transition-delay: 0.2s
        transition-delay: 0.2s

  .form-step
    display: none

    &.active
      display: block
