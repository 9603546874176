@use 'sass:math';

@mixin scale($iw, $ih, $w: null, $h: null) {
  @if $w == null {
    $ratio: math.div($h, $ih);
    height: rem-calc($h);
    width: rem-calc($iw * $ratio);
  }
  @if $h == null {
    $ratio: math.div($w, $iw);
    height: rem-calc($ih * $ratio);
    width: rem-calc($w);
  }
}

@mixin collapse-child-margins($selector: '> *') {
  @if not $selector {
    $selector: '&';
  }
  #{unquote($selector)} {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

@mixin b_position(
  $position,
  $box-edge-values
) {
  $box-edge-values: _unpack-shorthand($box-edge-values);
  $offsets: (
          top: nth($box-edge-values, 1),
          right: nth($box-edge-values, 2),
          bottom: nth($box-edge-values, 3),
          left: nth($box-edge-values, 4),
  );
  position: $position;
  @each $offset, $value in $offsets {
    @if _is-length($value) {
      #{$offset}: $value;
    }
  }
}

@mixin nav-trigger-pseudo {
  background: $black-3;
  border-radius: $prototype-border-radius;
  content: '';
  display: block;
  height: rem-calc(3);
  position: absolute;
  transition: .3s;
  width: 100%;
}

@mixin nav-trigger-pseudo-true {
  top: 0;
  transition: .3s;
}

@mixin standard-card-decorator {
  border-radius: 100%;
  border: 2px solid $white;
  content: '';
  height: rem-calc(68);
  position: absolute;
  right: -2px;
  transition: .4s ease-in-out;
  width: rem-calc(68);
}

@mixin standard-card-decorator-hover-state {
  opacity: 0;
  top: 0;
  transition: .4s ease-in-out;
}

@mixin logo-divider-pseudo-elements {
  background-color: $light-gray;
  content: '';
  height: 3px;
  position: absolute;
  width: 40%;
}

@mixin logo-divider-pseudo-elements-desktop {
  height: 100%;
  width: 3px;
}

@mixin background-image($repeat, $size, $position1, $position2) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position1 $position2;
}

@mixin block-text-border-left($border-color, $font-family, $font-size, $font-style, $font-color) {
  border-left: 3px solid $border-color;
  color: $font-color;
  font-family: $font-family;
  font-size: $font-size;
  font-style: $font-style;
  margin-top: $g8-2x;
  padding: 0 $g8-5x 0 $g8-3x;
}

@mixin iphone-scrolling {
  @media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
    position: fixed;
    height: 100%;
    overflow: hidden;

    body {
      width: 100vw;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
    @content;
  }
}

@mixin pseudo-heading-bar($color) {
  position: relative;
    &:before {
      background-color: $color;
      content: '';
      display: block;
      height: $g8*1.3;
      margin-bottom: $g8-5x;
      width: $g8*13;

      @include breakpoint(large) {
        height: $g8-10x;
        left: 0;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        width: $g8+$g8-half;
      }
    }
}

@mixin orange-heading-underline {
  border-bottom: rem-calc(6) solid $secondary-color;
  display: inline-block;
  font-family: $font-stack-poppins;
  font-size: $small-font-size;
  margin-bottom: $g8-4x;
  padding-bottom: $g8;
}

@mixin center-element {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
