.header
  left: 0
  position: fixed
  right: 0
  top: 0
  z-index: 300

  .top-bar
    background-color: transparent
    color: $white
    font-size: $medium-font-size
    height: $g8-10x
    padding: rem-calc(20)
    +breakpoint(small only)
      justify-content: left
      align-items: flex-start

      .mobile-cta
        position: absolute
        display: flex
        flex-direction: row
        background-color: #ED6E57
        border-radius: 8px
        background-image: url('./../../images/icons/signin-white.png')
        background-repeat: no-repeat
        background-position: 15% center
        height: 41px
        width: 141px
        background-size: auto 16px
        padding-left: 11%
        padding-top: 9px
        top: 21px
        right: 65px
        color: #ffffff

    &__logo
      display: flex
      align-items: center

      .logo
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/logos/logo-white.svg')
        display: block
        height: $g8-5x
        width: rem-calc(100)

        +breakpoint(medium)
          width: rem-calc(120)

        +breakpoint(large)
          height: rem-calc(50)
          width: rem-calc(170)


  &--is-scrolled
    .top-bar
      background-color: $white
      color: $black-3

      ul li a
        color: $black-3

      .logo
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/logos/logo-color.svg') !important

      .join-button
        color: #ED6E57
        border: 1px solid #ED6E57
        background-image: url('./../../images/icons/join-color.png')

      .arrow-head
        background-image: url('./../../images/icons/arrow-head-color.png')

    .primary-navigation-trigger
      &__menu-icon

        span
          background: $black-3

          &:before
            background: $black-3

          &:after
            background: $black-3
  &--is-normal
    .top-bar
      background-color: $white
      color: $black-3

      ul li a
        color: $black-3

      .logo
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/logos/logo-color.svg') !important

      .join-button
        color: #ED6E57
        border: 1px solid #ED6E57
        background-image: url('./../../images/icons/join-color.png')

      .arrow-head
        background-image: url('./../../images/icons/arrow-head-color.png')

      .primary-navigation
        .menu li a
          color: $black-3

        .user-logged-in
          color: $black-3 !important
          white-space: nowrap

          &:after
            background-image: url('./../../images/icons/dropdown-nav-icon-dark.svg') !important

    .primary-navigation-trigger
      &__menu-icon

        span
          background: $black-3

          &:before
            background: $black-3

          &:after
            background: $black-3

  input
    width: 100%
    border-radius: 8px
    height: 50px

+breakpoint(medium down)
  .headroom
    will-change: transform
    transition: transform 200ms linear

    &--pinned
      transform: translateY(0%)

    &--unpinned
      transform: translateY(-100%)
