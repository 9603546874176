.programmes-grid
  display: none
  +breakpoint(medium)
    display: grid
    column-gap: 50px
    gap: 40px
    grid-template-columns: repeat(3, minmax(0, 1fr))
  +breakpoint(large)
    grid-template-columns: repeat(4, minmax(0, 1fr))

  &__anchor
    margin-top: -80px
    position: absolute

  &__title
    color: map-get($iob-color-palette, navy)
    font-family: $font-stack-poppins
    font-size: 33px
    font-style: normal
    font-weight: 600
    line-height: 120%
    letter-spacing: -0.99px
    grid-column: span 3 / span 3
    +breakpoint(large)
      grid-column: span 4 / span 4

  &__button
    display: inline-flex
    padding: 20px 30px
    justify-content: center
    align-items: center
    gap: 15px
    background-color: #ffff
    border-radius: 7px
    border: 2px solid map-get($iob-color-palette, black)
    color: map-get($iob-color-palette, black)
    &:hover
      color: map-get($iob-color-palette, white)
      border: 2px solid map-get($iob-color-palette, black)
      background-color: map-get($iob-color-palette, black)
    &:focus:not(&:hover)
      color: map-get($iob-color-palette, black)
      
    &__text
      text-align: center
      font-family: Poppins
      font-size: 16px
      font-style: normal
      font-weight: 500
      line-height: 120%
      letter-spacing: -0.48px

    &--container
      display: flex
      justify-content: center
      grid-column: span 3 / span 3
      +breakpoint(large)
        grid-column: span 4 / span 4

  &__item
    grid-column: span 1 / span 1
    width: 250px !important
