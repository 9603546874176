.glossary-quick-links
  display: flex
  flex-direction: row
  gap: 39px
  width: 100%

  &__data--hidden
    display: none

  &__col
    width: 25%

  &__letter_group
    border-bottom: 1px solid map-get($iob-color-palette, grey)
    margin-top: 16px

    &--heading
      font-family: $font-stack-poppins
      font-size: 20px
      font-style: normal
      font-weight: 600
      line-height: 120%
      letter-spacing: -0.6px
      color: map-get($iob-color-palette, navy)
      margin-bottom: 16px

    &--item
      display: block
      width: 100%
      color: #6F7889 !important
      font-weight: 400
      background: url(./../../images/icons/grey_arrow_right.svg) 100% 50% no-repeat
      &:hover, &:focus
        color: map-get($iob-color-palette, navy) !important
        background: url(./../../images/icons/navy_arrow_right.svg) 100% 50% no-repeat
