.rich-text-accordion
  margin-bottom: $g8-3x

  .accordion-title
    background: none
    border: none !important
    padding: 0 $g8-4x 0 0

    &::before
      font-size: $large-font-size !important
      margin-top: 0
      top: -0.75rem
      right: -1rem

    h4
      color: $black
      margin: 0 !important

  .accordion-content
    border: none
