.p-pages__index
  .page-wrapper
    top: 0

  section
    padding-top: 5rem
    padding-bottom: 5rem

.p-pages__index, .p-events__index
  .header
    .top-bar
      // background-color: transparent
      // color: $white !important
      transition: .3s

      +breakpoint(nav-header)
        // background-color: transparent
        // color: $white

      &__logo
        .logo
          // background-image: url('./../../images/logos/logo-color.svg')

          +breakpoint(nav-header)
            background-image: url('./../../images/logos/logo-white.svg')

        .logo-secondary

          +breakpoint(large)
            height: $g8-5x
            width: rem-calc(150)

          +breakpoint(nav-header)
            background-image: url('./../../images/logos/logo-ucd-grey.svg')

            &:before
              border-left: 1px solid $white

    .primary-navigation
      // background-color: transparent !important

      +breakpoint(nav-header)
        // background-color: transparent !important

      .menu li a
        // color: $white

        +breakpoint(nav-header)
          // color: $white

      .search-icon
        background-image: url('./../../images/icons/icon-search-black.svg')
        +background-image(no-repeat, contain, center, center)

        +breakpoint(nav-header)
          background-image: url('./../../images/icons/icon-search-white.svg')

      // GGEZ
      &__user-tools

        .menu li
         //box-shadow: 0px 3px 5px black

      .user-logged-in
        color: $white

        &:after
          background-image: url('./../../images/icons/dropdown-nav-icon-light.svg')

    &--is-scrolled
      .top-bar
        background-color: $white
        transition: .3s

        .primary-navigation
          .menu li a
            color: $black-3

          &__user-tools
            // border-right: 1px solid $gray

            .menu li:not(:last-child)
              // border-right: 1px solid $gray

          .user-logged-in
            color: $black-3

            &:after
              background-image: url('./../../images/icons/dropdown-nav-icon-dark.svg')

    &--is-white
      .top-bar
        background-color: $white

        .search-icon
          background-image: url('./../../images/icons/icon-search-black.svg')

        .logo
          background-image: url('./../../images/logos/logo-color.svg')

        .join-button
          background-image: url('./../../images/icons/join-color.png')
          color: #ED6E57
          border: 1px solid #ED6E57

        .arrow-head
          background-image: url('./../../images/icons/arrow-head-color.png')

        .logo-secondary
          background-image: url('./../../images/logos/logo-ucd-grey.svg')

          &:before
            border-left: 1px solid $medium-gray

        .primary-navigation
          .menu li a
            color: $black-3

          &__user-tools
            // border-right: 1px solid $gray

            .menu li:not(:last-child)
              // border-right: 1px solid $gray

          .user-logged-in
            color: $black-3

            &:after
              background-image: url('./../../images/icons/dropdown-nav-icon-dark.svg')

  // Programmes Panel
  .programmes
    background-color: $lightest-gray

    section
      padding-bottom: $g8-7x
      padding-top: $g8*12

    .button
      margin-bottom: $g8-10x

      +breakpoint(medium)
        margin-bottom: $g8*12

    .scroller
      &__arrows
        background-color: $lightest-gray

        img
          background-color: $lightest-gray

  .programmes,
  .news-events
    +breakpoint(340 down)
      .button
        font-size: $small-font-size
        padding: $g8-3x

    +breakpoint(small only)
      .button
        width: 100%

// Home general
.home
  .intro
    color: $gray
    margin: $g8-3x auto
    text-align: center
    width: 100%

    @include breakpoint(medium)
      width: 50%
      margin: $g8-3x auto

  .scroller__details
    color: $gray

// Testimonial Slider

// About Panel
.home-about
  background-color: #F2F3F5
  padding: $g8-10x 0
  padding-top: 4rem

  .grid-container
    max-width: 98rem !important

  .button
    width: $g8*26

  .standard-carousel

    .swiper-container
      height: rem-calc(350)
      overflow: visible
    .swiper-pagination
      bottom: rem-calc(10)
      justify-content: center
      padding: 0
      position: absolute
      top: inherit
      +breakpoint(medium down)
        bottom: -20px

// Why IOB Panel
.home-why
  background-color: #FAFAFA
  padding: $g8-10x 0

  .grid-container
    max-width: 98rem !important

  .button
    width: $g8*26

  .standard-carousel

    .swiper-container
      height: rem-calc(350)

      .swiper-wrapper
        overflow: visible

        .swiper-slide
          width: 100% !important

    .swiper-pagination
      bottom: -4px
      justify-content: center
      padding: 0
      position: absolute
      top: inherit

  .why-tabs
    overflow: hidden
    text-align: center

    & button
      background-color: inherit
      border: none
      outline: none
      cursor: pointer
      padding: 24px 16px
      transition: 0.3s
      &.active
        border-bottom: 4px solid #ED6E56

// Testimonials panel
.companies
  +breakpoint(medium)
    margin-top: $g8-8x
    margin-bottom: $g8-8x

  &__logo
    display: flex
    justify-content: center
    padding: $g8-7x 0
    text-align: center

    +breakpoint(medium)
      padding: $g8

    &:nth-child(2)
      position: relative

      &:before
        +logo-divider-pseudo-elements
        top: 0

        +breakpoint(medium)
          +logo-divider-pseudo-elements-desktop
          left: 0

      &:after
        +logo-divider-pseudo-elements
        bottom: 0

        +breakpoint(medium)
          +logo-divider-pseudo-elements-desktop
          right: 0

// News and Events panel
.news-events__buttons
  .button--gray,
  .button--orange
    +breakpoint(small only)
      width: 100%
    +breakpoint(medium)
      margin-right: $g8-2x

.news-insights
  display: flex
  width: 100%
  padding-top: 20px !important
  padding-bottom: 40px
  background: #F2F3F5

  +breakpoint(medium down)
    flex-direction: row
    flex-wrap: wrap
  &__left__column
    width: 60%
    min-height: 400px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    +breakpoint(medium down)
      width: 100%
      order: 2
    &__card
      display: flex
      flex-direction: column
      align-items: flex-start
      width: 40%
      margin-right: 55px
      margin-top: 20px
      height: 371px
      background: #FFFFFF
      box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
      border-radius: 14px 14px 14px 14px
      &:nth-child(2)
        margin-top: 55px
      &:nth-child(4)
        margin-top: 55px
      +breakpoint(medium down)
        width: 100%
        margin-right: 0px
        &:nth-child(2)
          margin-top: 20px
        &:nth-child(4)
          margin-top: 20px
      &__header
        width: 100%
        height: 100%
        top: 0
        position: absolute
        left: 0
        border-radius: 14px 14px 0px 0px
        & h4
          position: absolute
          top: 25px
          left: 30px
          font-style: normal
          color: white
          font-weight: 600
          font-size: 14px
          line-height: 120%
          letter-spacing: 0.25em
          text-transform: uppercase
      &__circle
        width: 45px
        height: 45px
        background: #ffffff82
        border-radius: 50%
        position: absolute
        top: 10px
        right: 10px
        +breakpoint(medium down)
          margin: 0 auto
        &__path
          width: 36px
          height: 36px
          margin: 5px auto
          background: white url('./../../images/icons/path.svg') no-repeat center center
          border-radius: 50%
          display: block
      &__circle_event
        width: 45px
        height: 45px
        background: rgba(237, 110, 86, 0.25)
        border-radius: 50%
        position: absolute
        top: 10px
        right: 10px
        +breakpoint(medium down)
          margin: 0 auto
        &__event
          width: 36px
          height: 36px
          margin: 5px auto
          background: #ed6e56 url('./../../images/icons/event.svg') no-repeat center center
          border-radius: 50%
          display: block
      &__textarea
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 30px 40px 40px
        width: 100%
        height: 148px
        position: relative
        left: 0px
        & h6
          font-style: normal
          font-weight: 500
          font-size: 18px
          line-height: 120%
          letter-spacing: -0.03em
          color: #6F7889
        & h4
          font-style: normal
          font-weight: 500
          font-size: 16px
          line-height: 120%
          letter-spacing: -0.03em
          color: #6F7889
        & p
          font-style: normal
          font-weight: 500
          font-size: 18px
          line-height: 120%
          letter-spacing: -0.03em
          color: #06243C
          & a
            color: #06243C
      &__image
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-end
        padding: 20px 30px 0px
        position: relative
        width: 100%
        height: 190px
        border-radius: 14px 14px 0px 0px
  &__right__column
    padding: 0px 30px 0px
    width: 40%
    min-height: 400px
    display: flex
    flex-direction: column
    justify-content: center
    +breakpoint(medium down)
      min-height: unset
      width: 100%
      text-align: center
      order: 1
    & h4
      font-style: normal
      font-weight: 600
      font-size: 46px
      line-height: 120%
      letter-spacing: -0.03em
      color: #06243C
      padding-bottom: 20px
    & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      letter-spacing: -0.01em
      color: #6F7889
    &__button
      display: flex
      flex-direction: row
      align-items: center
      padding: 20px 30px
      width: 195px
      height: 64px
      left: 0px
      background: transparent url('./../../images/icons/arrow_right_discover.svg') no-repeat right
      background-position: 90% 50%
      border-radius: 7px
      border: 1px solid #ED6E56
      color: #ED6E56
      +breakpoint(medium down)
        margin: 5px auto
  &__left__column__mobile
    width: 100%
    min-height: 400px
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    order: 2
    +breakpoint(medium down)
      width: 100%
      order: 2
    &__card
      display: flex
      flex-direction: column
      align-items: flex-start
      width: 60%
      height: 338px
      background: $white
      box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
      border-radius: 14px 14px 14px 14px
      margin: 20px auto
      +breakpoint(small only)
        width: 300px
      &__header
        width: 100%
        height: 100%
        top: 0
        position: absolute
        left: 0
        border-radius: 14px 14px 0px 0px
        & h4
          position: absolute
          top: 25px
          left: 30px
          font-style: normal
          color: white
          font-weight: 600
          font-size: 14px
          line-height: 120%
          letter-spacing: 0.25em
          text-transform: uppercase
      &__circle
        width: 45px
        height: 45px
        background: #ffffff82
        border-radius: 50%
        position: absolute
        top: 10px
        right: 10px
        +breakpoint(medium down)
          margin: 0 auto
        &__path
          width: 36px
          height: 36px
          margin: 5px auto
          background: white url('./../../images/icons/path.svg') no-repeat center center
          border-radius: 50%
          display: block
      &__circle_event
        width: 45px
        height: 45px
        background: rgba(237, 110, 86, 0.25)
        border-radius: 50%
        position: absolute
        top: 10px
        right: 10px
        +breakpoint(medium down)
          margin: 0 auto
        &__event
          width: 36px
          height: 36px
          margin: 5px auto
          background: #ed6e56 url('./../../images/icons/event.svg') no-repeat center center
          border-radius: 50%
          display: block
      &__textarea
        display: flex
        flex-direction: column
        align-items: flex-start
        padding: 30px 30px 10px
        height: 148px
        position: relative
        left: 0px
        & h6
          font-style: normal
          font-weight: 500
          font-size: 18px
          line-height: 120%
          letter-spacing: -0.03em
          color: #6F7889
        & h4
          font-style: normal
          font-weight: 500
          font-size: 16px
          line-height: 120%
          letter-spacing: -0.03em
          color: #6F7889
        & p
          font-style: normal
          font-weight: 500
          font-size: 16px
          line-height: 120%
          letter-spacing: -0.03em
          color: #06243C
          & a
            color: #06243C
      &__image
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-end
        padding: 20px 0px 0px
        position: relative
        width: 100%
        height: 190px
        border-radius: 14px 14px 0px 0px
  &__right__column__mobile
    padding: 0px 30px 0px
    width: 100%
    min-height: 400px
    display: flex
    flex-direction: row
    justify-content: center
    order: 1
    +breakpoint(medium down)
      width: 100%
      text-align: center
      order: 1
    & h4
      font-style: normal
      font-weight: 600
      font-size: 46px
      line-height: 120%
      letter-spacing: -0.03em
      color: #06243C
    & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      letter-spacing: -0.01em
      color: #6F7889
    &__button
      display: flex
      flex-direction: row
      align-items: center
      padding: 20px 30px
      width: 195px
      height: 64px
      left: 0px
      background: transparent url('./../../images/icons/arrow_right_discover.svg') no-repeat right
      background-position: 90% 50%
      border-radius: 7px
      border: 1px solid #ED6E56
      color: #ED6E56
      +breakpoint(medium down)
        margin: 0 auto
.hero-wrapper
  width: 100%
  min-height: 380px
  display: flex
  padding: $g8-10x 0 !important

.hero-content
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%
  +breakpoint(medium down)
    flex-direction: column
  &__video
    width: 60%
    text-align: right
    padding-right: 50px
    //min-height: 370px
    +breakpoint(medium down)
      width: 100%
      order: 2
      padding: 10px
    & video, iframe
      width: 80%
      //height: 100%
      border-radius: 25px !important
      +breakpoint(medium down)
        width: 100% !important
        right: 0 !important

  &__text
    width: 30%
    min-height: 370px
    padding: 15px 30px
    +breakpoint(medium down)
      width: 100%
      order: 1
      text-align: center
    & h4
      font-style: normal
      font-weight: 600
      font-size: 46px
      line-height: 120%
      letter-spacing: -0.03em
      color: #06243C
    & h6
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 120%
      letter-spacing: -0.03em
      color: #6F7889
      margin-top: 1rem
      margin-bottom: 1rem
    &__button
      display: flex
      flex-direction: row
      align-items: center
      padding: 20px 30px
      width: 195px
      height: 64px
      left: 0px
      background: #ED6E56 url('./../../images/icons/arrow_right.svg') no-repeat right
      background-position: 80% 50%
      border-radius: 7px
      color: #ffffff
      +breakpoint(medium down)
        margin: 0 auto
        &:hover
          color: #ffffff
        &:focus
          color: #ffffff
    & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      letter-spacing: -0.01em
      color: #6F7889
.news-insights-mobile span.swiper-pagination-bullet
  border: 2px solid #003977
  height: 0.75rem
  width: 0.75rem
.news-insights-mobile span.swiper-pagination-bullet.swiper-pagination-bullet
  background-color: transparent
  opacity: 1
.news-insights-mobile span.swiper-pagination-bullet.swiper-pagination-bullet-active
  background-color: #003977
.news-insights-mobile span.swiper-pagination-bullet:not(:last-child)
  margin-right: 1.125rem

iframe
  border-radius: 25px
  width: 100%

#toast-container
  div
    opacity: 1
    width: 400px
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100)
  .toast-message
    width: 100%
