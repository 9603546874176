.p-users-apa__new,
.p-users-apa__success,
.p-users-professional-membership__new,
.p-users-professional-membership__success
  .primary-navigation,
  .primary-navigation-trigger,
  .footer,
  .mobile-footer
    display: none

  .top-bar
    background-color: transparent

  .page-wrapper
    top: 0

  .logo
    background-image: url('./../../images/logos/logo-color.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-white.svg') !important

  .logo-secondary
    background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

.apa-form__page
  &-graphic
    +background-image(no-repeat, cover, center, center)
    background-image: url('../../images/backgrounds/apa-background.png')
    position: relative

    &:before
      +background-image(no-repeat, cover, center, center)
      background-image: url('../../images/backgrounds/grid-background-hero.svg')
      bottom: 0
      content: ''
      left: 0
      opacity: 0.4
      position: absolute
      right: 0
      top: 0

  &-right-col
    +breakpoint(medium down)
      .padding-y-nav-height
        padding-top: $g8*13

    ul
      +unordered-list-orange
      color: $gray
      font-weight: 600
      margin: $g8-5x 0 $g8-5x $g8-2x

    h2
      border: none
      margin-bottom: $g8

    input[type='checkbox'] + label:before
      left: rem-calc(-23) !important

    input[type='checkbox'] + label:after
      left: rem-calc(-11) !important

    label
      padding-left: $g8-3x !important

    .terms-link
      padding-left: $g8*5.62
      margin-top: $g8

.apa-invitation
  padding: 0 $g8-2x $g8-10x $g8-2x

  @include breakpoint(nav-header)
    padding-top: $g8*11

  &__mail-details
    color: $black-3
    margin: $g8-4x 0

    &-category
      align-items: center
      color: $gray
      display: flex
      font-size: $tiny-font-size
      padding-left: $g8

    &-title
      align-items: center
      display: flex
      font-size: $large-font-size
      font-weight: 600

      +breakpoint(small only)
        margin-top: $g8-4x

    &-date
      p
        font-size: $tiny-font-size
        margin: 0
        text-align: right

  &__content
    background-color: $lightest-gray
    margin: 0 auto
    max-width: $g8*106
    padding: $g8-6x $g8-5x

    p
      color: $black-3
      font-style: normal
      font-weight: 300

      &:last-of-type
        margin-bottom: 0

      strong
        font-weight: 600

    ul
      +unordered-list-orange
      color: $gray
      font-weight: 600
      margin: $g8-3x 0 $g8-2x $g8-2x

.apa-success

  .scrollable
    height: 100vh

  &__page-graphic
    background-repeat: no-repeat !important
    background-size: cover !important
    background:  url('../../images/backgrounds/application-success-background.jpg')
    position: relative

    &:before
      +background-image(no-repeat, cover, center, center)
      background-image: url('../../images/backgrounds/grid-background-hero.svg')
      bottom: 0
      content: ''
      left: 0
      position: absolute
      right: 0
      top: 0

  &__page-right-col-content
    width: 100%

  &__page-right-col
    display: flex
    justify-content: center
    position: relative

    &:after
      +breakpoint(large)
        +background-image(no-repeat, cover, center, center)
        background-image: url('../../images/backgrounds/apa-success-decorator.svg')
        bottom: 0
        content: ''
        height: 100%
        position: absolute
        right: 0
        top: 0
        width: $g8-6x

    blockquote
      +blockquote-success
      margin: $g8-6x 0
