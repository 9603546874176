.cpd-certificates
  font: poppins
  color: #06243C

  &__container
    display: flex
    flex-direction: column
    gap: 32px
    padding: 0px 180px 0px 180px

    +breakpoint(small only)
      gap: 20px
      padding: 0px 10px 0px 10px

  &__header-text
    font-weight: 600
    font-size: 58px
    line-height: 120%
    letter-spacing: -0.03em
    padding-top: 20px
    color: #06243C

    +breakpoint(small only)
      font-size: 36px

  &__help-text
    font-size: 16px
    font-weight: 300
    letter-spacing: -0.01em
    line-height: 25.6px
    color: #6F7889

  &__content-box
    box-shadow: 2px -2px 4px rgba(6, 36, 60, 0.1), -10px 5px 20px rgba(6, 36, 60, 0.04), -1px 2px 10px rgba(6, 36, 60, 0.3)
    gap: 30px
    padding: 50px
    border-radius: 40px
    display: flex
    flex-direction: column
    min-width: 454px

    +breakpoint(small only)
      border-radius: 10px
      padding: 30px 20px 20px 20px
      gap: 20px
      min-width: 184px

  &__content-item
    background-color: #C4C4C44D
    padding: 20px
    display: flex
    flex-direction: row
    border-radius: 8px
    gap: 23.59px
    align-items: center
    justify-content: center

    +breakpoint(small only)
      gap: 5px
      padding: 10px 10px 10px 20px

  &__content-item-name
    font-weight: 500
    font-size: 18.25px
    flex-grow: 1
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__content-item-button
    flex-grow: 0
    background-color: #ED6E56
    border-radius: 7px
    color: #ffffff
    padding: 10px 20px 10px 30px
    font-size: 16px
    pointer-events: all
    white-space: nowrap

    +breakpoint(small only)
      font-size: 10px

  &__show-older-container
    display: flex
    flex-direction: row
    justify-content: flex-end

  &__show-older
    font-size: 14px
    font-weight: 500
    color: #06243C

    +breakpoint(small only)
      font-size: 12px

  &__show-older:hover
    cursor: pointer
    color: rgb(0, 49, 102)
