.user-navigation
  background-color: $lightest-gray
  display: none
  left: 0
  position: fixed
  right: 0
  top: $g8-10x
  z-index: 99

  +breakpoint(nav-header)
    display: block

  ul
    display: flex
    list-style: none
    margin: 0
    padding: 0

  li
    position: relative

    &:not(:last-child)
      margin-right: $g8-5x

    a
      border-bottom: rem-calc(4) solid transparent
      color: $dark-gray
      display: inline-block
      font-size: $medium-font-size
      padding-bottom: $g8-2x
      padding-top: $g8-2x

      &:hover
        border-bottom: rem-calc(4) solid $secondary-color
        color: $black-3

    a.active
      border-bottom: rem-calc(4) solid $secondary-color
      color: $black-3

  &__drop-down-icon
    &:after
      +background-image(no-repeat, cover, center, center)
      background-image: url('./../../images/icons/dropdown-nav-icon.svg')
      content: ''
      display: inline-block
      height: rem-calc(11)
      left: $g8
      position: relative
      top: 0
      width: rem-calc(12)

  ul#user-navigation__drop-down-nav
    background: $black-3
    box-shadow: 0 rem-calc(3) rem-calc(10) 0 rgba(106,124,139,1)
    flex-direction: column
    justify-content: center
    left: -$g8-10x
    opacity: 0
    padding: $g8-4x $g8-2x
    position: absolute
    top: $g8-8x
    transition: .3s
    visibility: hidden
    width: $g8*25

    li
      margin-right: 0
      text-align: center

    a
      border-bottom: none
      color: $white
      padding-bottom: rem-calc(10)
      padding-top: 0

      &:hover
        color: $secondary-color

  .unread-messages
    position: relative

    &:after
      background: $secondary-color
      border-radius: 100%
      content: ''
      position: absolute
      display: inline-block
      height: rem-calc(10)
      top: rem-calc(10)
      width: rem-calc(10)

.user-navigation ul li:hover #user-navigation__drop-down-nav
  opacity: 1
  transition: .3s
  visibility: visible
