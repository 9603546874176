.membership-type
  font-family: $font-stack-poppins


  &__flex-container
    width: 365px
    display: flex
    flex-direction: column
    background: #FFFFFF
    border-radius: 11px
    border: 4px solid #ADB5C2

  &__flex-container-orange
    border: 4px solid #ED6E56

  &__top-text
    font-family: $font-stack-poppins
    color: #06243C
    font-weight: 500
    line-height: 25.6px
    padding-bottom: 20px
    text-align: center
    font-size: 16px

    +breakpoint(small only)
      font-size: 12px
      padding-bottom: 10px

  &__heading
    font-family: $font-stack-poppins
    text-align: center
    padding: 20px 0px
    background-color: #ADB5C2
    font-size: 23px
    font-weight: 600
    color: #06243C

    +breakpoint(small only)
      font-size: 15px
      padding: 15px 0px
  
  &__body
    padding: 40px 40px 40px 40px
    
    +breakpoint(small only)
      padding: 10px 0px 10px 10px

  &__line-container
    font-family: $font-stack-poppins
    padding-bottom: 10px
    font-size: 16px
    font-weight: 400

    +breakpoint(small only)
      font-size: 10px

  &__line-container-wrapping
    display: inline-flex
    align-items: baseline

  &__image
    padding-right: 15px
  
  &__image-text
    flex-wrap: nowrap

  &__member
    background-color: #ED6E56
    color: #FFFFFF !important

  &__heavy
    font-weight: 600
    display: inline-block

  &__footer
    font-family: $font-stack-poppins
    font-weight: 300
    font-size: 16px
    text-align: center
    line-height: 160%
    letter-spacing: -1%

    +breakpoint(small only)
      font-size: 12px

  &__footer-price
    font-family: $font-stack-poppins
    font-weight: 600
    font-size: 19px
    text-align: center
    letter-spacing: 0.2em

    span
      font-size: 14px
      font-weight: 600
      line-height: 105%
      letter-spacing: 0.25em
      text-align: center

      +breakpoint(small only)
        font-size: 12px


    +breakpoint(small only)
      font-size: 14px
