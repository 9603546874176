.user-correspondence
  +breakpoint(medium down)
    padding: $g8 0 $g8-10x 0

  +breakpoint(nav-header)
    padding-top: $g8-10x
    padding-bottom: $g8-10x

  span
    font-size: $small-font-size
    font-weight: 600
    margin-right: $g8

  .filter
    padding: $g8 $g8-3x $g8-2x $g8-3x

  &__grid
    a
      font-weight: normal

    &--filters
      p
        padding-left: $g8-half

      +breakpoint(medium only)
        margin-top: $g8-5x
        padding-left: $g8-3x

      +breakpoint(large)
        margin-top: $g8-3x

  &__filters
    .cell
      a
        font-weight: 600

    +breakpoint(medium)
      .cell
        padding: 0 $g8-half

  &__header
    padding: $g8 $g8-2x $g8 $g8-2x

    &.cell
      margin: 0

    img
      margin-left: $g8

    a.reverse-link
      font-size: $tiny-font-size
      letter-spacing: $letter-spacing
      color: $black-2
      text-align: right

      +breakpoint(medium)
        text-align: left

    p
      margin: 0

    .sort-by
      color: $gray
      font-size: $tiny-font-size
      overflow: hidden
      position: relative

      &:after
        background-color: $light-gray
        content: ''
        height: 1px
        left: $g8-8x
        position: absolute
        top: rem-calc(10)
        width: 100%

  &__link
    &.cell
      display: block
      margin: $g8 0

      +breakpoint(small only)
        height: $g8*16 !important

      +breakpoint(medium)
        min-height: $g8-7x !important

      +breakpoint(large)
        height: $g8-7x !important

  &__mail
    background-color: $lightest-gray
    height: 100%

    +breakpoint(small only)
      padding: $g8 $g8-3x $g8-2x $g8-3x

    +breakpoint(medium)
      padding: $g8 $g8-2x $g8 0

    &.is-unread
      background-color: $pale-orange
      border-bottom: rem-calc(3) solid $secondary-color

      .user-correspondence__subject
        font-weight: 600

  &__subject, &__date
    color: $black-3

  &__date
    +breakpoint(small only)
      text-align: right

    +breakpoint(medium)
      font-size: $medium-font-size
      margin-left: $g8-2x

  &__category
    color: $gray
    padding-left: $g8

    &--cpd
      border-left: rem-calc(5) solid map-get($accent-colors, pink)

    &--event
      border-left: rem-calc(5) solid map-get($accent-colors, purple)

    &--membership
      border-left: rem-calc(5) solid map-get($accent-colors, orange)

    &--module
      border-left: rem-calc(5) solid map-get($accent-colors, green)

    &--receipt
      border-left: rem-calc(5) solid map-get($accent-colors, blue)

    +breakpoint(medium)
      height: 100%
      line-height: $g8-5x

  #js-sort-date
    cursor: pointer

  .flip
    transform: rotate(-180deg)

  .pagination-container
    font-size: $small-font-size
    font-weight: 600
    margin-top: $g8-4x
    width: 100%

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
      -webkit-appearance: none
      margin: 0

    input[type=number]
      -moz-appearance: textfield

    .input-group
      align-items: center
      background: none
      display: flex
      justify-content: space-between
      margin: 0

      +breakpoint(medium down)
        padding: 0 $g8-2x

      &-field
        background: none
        color: $gray
        font-size: $small-font-size
        font-weight: 600
        height: auto
        margin: 0 !important
        padding: 0 !important
        text-align: center
        width: $g8-2x !important

      &-label
        background: none
        border: none
        color: $gray

    a.next.button,
    a.prev.button
      background: none
      color: $black-3
      font-weight: 600
      height: auto
      padding: 0

  &__title-text
    display: flex
    flex: 1 1

  +breakpoint(small only)
    &__navigation--button a
      width: 100%

  &__instructions
    padding: 0 1rem
    font-style: italic

    +breakpoint(medium)
      padding: 0 1.5rem

    +breakpoint(nav-header)
      padding: 0
