.scroller
  &__programmes
    .swiper-container
      height: $g8*64
      width: 100%

    .swiper-slide
      width: 290px !important
      a
        display: grid
        z-index: 6

  &__standard-card
    .swiper-slide
      width: 100%

      +breakpoint(medium)
        width: rem-calc(420)

  &__tall-card
    .swiper-slide
      width: rem-calc(300)
      height: rem-calc(450)

  &__membership-card
    .swiper-slide
      width: rem-calc(320)

      +breakpoint(medium)
        width: rem-calc(394)

  &__photogallery
    @include overflow-left-hidden

    .swiper-slide
      height: $g8*28
      width: 100%

      +breakpoint(medium)
        width: 50%

      +breakpoint(large)
        width: 40%

  &__module-card
    @include overflow-left-hidden

    .swiper-slide
      width: 100%

      +breakpoint(medium)
        width: $g8*50

  &__renewal-fees
    @include overflow-left-hidden
    overflow: hidden
    padding-bottom: $g8-2x

    .swiper-slide
      width: 100%

      +breakpoint(medium)
        width: $g8*50
        height: $g8*34

  &__unit-card
    .swiper-slide
      width: rem-calc(312)
      height: rem-calc(280)

  &__ecpd-card
    .swiper-slide
      width: $g8*35
      min-height: $g8*38.1

      +breakpoint(medium)
        width: rem-calc(314)
