@mixin unordered-list-orange {
  list-style-position: outside;
  list-style: none;
  margin-left: $g8-4x;

  li {
    margin-bottom: $g8;
    display: -webkit-box;

    &:before {
      color: $secondary-color;
      content: "\2022";
      display: inline-block;
      font-weight: bold;
      margin-left: -1em;
      width: 1em;
    }
  }
}

@mixin ordered-list-orange {
  li {
    counter-increment: list;
    list-style-type: none;
    margin-left: $g8-3x;
    position: relative;
  }

  li:before {
    color: $secondary-color;
    content: counter(list) ".";
    left: -$g8-3x;
    position: absolute;
    text-align: left;
    width: $g8-3x;
  }
}
