.primary-navigation
  bottom: 0
  display: flex
  flex-direction: column
  height: 100vh
  left: 0
  position: absolute
  right: 0
  text-align: center
  top: rem-calc(-1500)
  transition: .3s
  z-index: -1
  align-items: center

  #logout-link
    margin-top: 50px

  +breakpoint (nav-header up)
    flex-direction: row
    height: auto
    position: relative
    top: 0
    width: 100%
    z-index: 0

  &[data-mobile-menu-active="true"]
    top: rem-calc(0)

    +breakpoint(nav-header up)
      top: 0

  .menu
    background-color: transparent
    flex-direction: column

    +breakpoint(nav-header up)
      flex-direction: row
      flex-wrap: nowrap

    li
      align-items: center
      display: flex
      justify-content: center

    li
      margin-bottom: $g8-2x

      +breakpoint(nav-header up)
        margin-bottom: 0

    a
      color: $white
      +breakpoint(medium down)
        color: $black

      +breakpoint(medium)
        font-size: $small-font-size

      +breakpoint(xlarge)
        font-size: $medium-font-size

  &__site-nav

    +breakpoint(nav-header up)
      display: flex
      justify-content: left
    +breakpoint(medium down)
      margin-top: 20%

  &__user-tools
    order: -1

    +breakpoint(nav-header up)
      display: flex
      order: initial
    +breakpoint(medium down)
      display: none

    .menu
      flex-direction: column
      margin-bottom: $g8-2x
      & a:first-child
        padding-right: 0

      +breakpoint(nav-header up)
        margin-bottom: 0

      &:after
        bottom: 0
        content: ''
        display: block
        left: 0
        margin: 0 auto
        position: absolute
        right: 0
        width: 40%

        +breakpoint(nav-header up)
          display: none

    +breakpoint(nav-header up)

      .menu
        flex-direction: row

    +breakpoint(nav-header up)
      margin-top: 0
      padding: 0

  .user-logged-in
    background-color: $gray
    border-right: none !important
    color: $white
    display: flex
    flex-direction: column
    font-size: $small-font-size
    font-weight: 300
    margin-bottom: 0 !important
    padding: $g8-3x 0 $g8 0
    pointer-events: none

    +breakpoint(small only)
      letter-spacing: $letter-spacing
      text-transform: uppercase

    +breakpoint(nav-header up)
      background-color: initial
      color: $black-3
      cursor: pointer
      flex-direction: row
      font-size: $medium-font-size
      font-weight: 600
      padding: 0 0 0 0
      pointer-events: initial
      position: relative

      &:after
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/icons/dropdown-nav-icon-dark.svg')
        content: ''
        height: rem-calc(11)
        position: relative
        right: rem-calc(-10)
        width: rem-calc(12)

  .user-logged-in__drop-down
    background-color: $gray
    margin: 0
    padding-bottom: $g8-3x

    li
      &:first-child
        border-right: none !important
        margin: 0

    a
      color: $white !important
      +breakpoint(medium down)
        color: $black

    &[data-user-nav-active="true"]
      display: flex

    +breakpoint(nav-header up)
      background-color: $black-3
      box-shadow: $card-box-shadow
      display: none
      flex-direction: column
      justify-content: center
      padding: $g8-3x
      position: absolute
      right: -$g8-8x
      top: rem-calc(68)
      width: $g8*34

.nav
  display: flex
  flex-direction: row
  width: max-content

.search
  width: 100%
  padding-left: 10px
  padding-right: 10px

  .navigation-search
    flex-grow: 1
    width: 100%

.join-button
  display: flex
  flex-direction: row
  border: 1px solid $white
  background-color: transparent
  border-radius: 8px
  background-image: url('./../../images/icons/join-white.png')
  background-repeat: no-repeat
  background-position: 25% center
  height: 50px
  width: 110px
  background-size: auto 16px
  padding-left: 23%

.signup-button
  display: flex
  flex-direction: row
  background-color: #ED6E57
  border-radius: 8px
  background-repeat: no-repeat
  background-position: 21% center
  height: 50px
  width: 127px
  background-size: auto 16px

.meganav-menu
  cursor: pointer
  padding-left: 20px
  padding-right: 20px

.meganav-learn
  display: none
  margin-top: 80px

.signin-join
  display: flex
  flex-direction: row
  a::first-of-type
    margin-left: 10px

.gap-1
  gap: 1rem

.width-unset-important
  width: unset !important

.meganav-discover
  display: none
  margin-top: 80px

.arrow-head
  +background-image(no-repeat, contain, center, center)
  background-image: url('./../../images/icons/arrow-head-white.png')
  width: 10px
  height: 10px
  transition: 0.3s
  transform: rotate(0deg)

  .meganav-menu[data-active="true"] &
    transform: rotate(180deg)
