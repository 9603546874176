.programmes-card
  background-color: #ffffff
  box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
  box-sizing: border-box
  height: 354px
  width: 220px
  overflow: hidden
  border-radius: 0px 0px 14px 14px
  +breakpoint(xlarge)
    width: 280px
    height: 390px

  &__small-icon
    width: 15px
    height: 15px
    background-image: url('./../../images/icons/ribbon_icon.png')
    background-size: contain

  &__level-info
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-end

  &__text
    color: #06243C !important

  &__blurb
    color: #6F7889 !important
    font-size: 12px
    font-weight: 400

    &.cell
      width: auto

  &__grey-text
    color: #ADB5C2 !important
    font-weight: 500
    font-size: 12px
    position: relative

  &:hover .programmes-card__detail
    transition: .3s ease-in-out

  &__top
    display: grid
    grid-template-columns: repeat(1, minmax(0, 1fr))
    height: 100%
    padding: 1rem
    +breakpoint(xlarge)
      padding: 1.5rem

    &__header
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      margin-bottom: 12px
      +breakpoint(xlarge)
        margin-bottom: 48px

  &__text, &__category, &__degree
    color: $black-3
    font-size: $small-font-size

  &__number
    align-items: center
    background-color: $white
    border-radius: 100%
    color: $black-3
    display: flex
    float: right
    font-size: $medium-font-size
    height: $g8-4x
    justify-content: center
    width: $g8-4x

  &__name
    h3
      color: #06243C !important
      line-height: 120%
      font-size: 18px
      +breakpoint(xlarge)
        font-size: 20px

  &__bottom
    align-self: end

    .grey-text
      color: #ADB5C2 !important
      font-weight: 500
      font-size: 12px

  &__detail
      background-repeat: no-repeat
      transition: .3s ease-in-out
