.accordion
  +accordion-default

  &.blue-right
    .accordion-title
      color: $primary-color
      text-align: right

      &::before
        right: 11rem

  &--light
    background-color: $light-gray

    .accordion-title
      color: $primary-color

      &:hover,
      &:focus
        background-color: $light-gray

  &--dark
    background-color: $gray

    .accordion-title
      color: $white

      &:hover,
      &:focus
        background-color: $gray

  &--none
    background-color: transparent

    .accordion-title
      border: none
      border-bottom: none !important
      color: $secondary-color
      font-size: rem-calc(13)
      height: $g8-3x
      padding-top: 0

      &:hover,
      &:focus
        background-color: transparent

      &:before
        left: 0
        top: $g8

    .accordion-content
      border: none
      border-bottom: none !important
      padding: 0
