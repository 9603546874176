.hours-breakdown
  color: #06243C
  font-family: 'Poppins'

  &__wrapper
    display: flex
    flex-direction: column
    gap: 10px
    padding: 0px 180px

    +breakpoint(small only)
      padding: 0px 15px

  &__top-text
    display: flex
    flex-direction: column
    gap: 20px

  &__header-top-text
    font-weight: 600
    font-size: 33px
    gap: 10px
    color: #06243C

  &__header-bottom-text
    font-weight: 300
    font-size: 16px
    color: #6F7889

    +breakpoint(small only)
      font-size: 10px

  &__middle-text
    font-weight: 500
    font-size: 24px
    height: 64px
    display: flex
    flex-direction: row
    align-items: center

    +breakpoint(small only)
      font-size: 14px
      height: fit-content

  &__content-form
    display: flex
    flex-direction: column
    border-radius: 20px
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    background-color: #ffffff
    min-width: 550px
    +breakpoint(small only)
      min-width: 0px

  &__content
    display: flex
    flex-direction: column
    padding: 40px

    +breakpoint(small only)
      padding: 10px

  &__content-top-text
    display: flex
    flex-direction: row
    gap: 100px
    +breakpoint(small only)
      gap: 15px

  &__content-top-text-left
    padding-bottom: 47px
    border-bottom: 4px solid #ED6E56
    flex-grow: 0
    font-size: 20px
    font-weight: 500

    +breakpoint(small only)
      font-size: 10px
      padding-bottom: 15px
      border-bottom: 2px solid #ED6E56

  &__content-top-text-right
    padding-bottom: 47px
    flex-grow: 2
    border-bottom: 4px solid #ED6E56
    font-size: 20px
    font-weight: 500

    +breakpoint(small only)
      font-size: 10px
      padding-bottom: 15px
      border-bottom: 2px solid #ED6E56

  &__top-cap
    background-image: url('../../images/backgrounds/pattern_orange.svg')
    height: 19px
    border-radius: 20px 20px 0px 0px
    background-size: cover
    background-position: 0px

  &__content-row
    display: flex
    flex-direction: row
    padding: 10px 10px 0px 10px
    gap: 140px
    align-items: center

    +breakpoint(small only)
      gap: 37px
      padding: 10px 10px 0px 10px

  &__content-dropdown
    display: none
    flex-direction: row
    background: #F2F3F5
    gap: 100px
    padding: 20px

    +breakpoint(small only)
      gap: 0px
      padding: 5px

  &__content-container
    display: flex
    flex-direction: column
    gap: 20px
    border-bottom: 1px solid #ADB5C2
    padding-bottom: 20px

    +breakpoint(small only)
      gap: 5px
      padding-bottom: 5px

  &__content-container:hover
    cursor: pointer
    color: #003166

  &__content-delete
    display: none
    flex-direction: row
    justify-content: flex-end

  &__content-delete-button
    background-color: #ED6E56
    padding: 15px 30px
    gap: 15px
    border-radius: 40px
    display: flex
    flex-direction: row
    color: #ffffff
    align-items: center
    pointer-events: all

    +breakpoint(small only)
      padding: 5px 10px

  &__content-delete-button:hover
    cursor: pointer

  &__content-row-date
    height: fit-content
    font-weight: 500
    font-size: 18px
    color: #06243C

    +breakpoint(small only)
      font-size: 8px

  &__content-row-name
    height: fit-content
    font-weight: 500
    font-size: 18px
    color: #06243C

    +breakpoint(small only)
      font-size: 8px

  &__content-row-dropdown
    height: 70px
    background-image: url('../../images/icons/arrow_down.svg')
    width: 70px
    background-size: cover
    flex-shrink: 0

    +breakpoint(small only)
      width: 30px
      height: 30px

  &__content-row-dropdown:hover
    cursor: pointer

  &__middle-left
    display: flex
    flex-direction: column
    gap: 20px

    +breakpoint(small only)
      width: 50%
      gap: 0px

  &__middle-right
    display: flex
    flex-direction: column
    gap: 20px

    +breakpoint(small only)
      width: 50%
      gap: 0px

  &__content-dropdown-left
    font-weight: 500
    font-size: 14px
    display: inline
    color: #06243C

    +breakpoint(small only)
      font-size: 8px

  &__content-dropdown-right
    font-weight: 300
    font-size: 14px
    display: inline
    color: #06243C

    +breakpoint(small only)
      font-size: 8px

  &__middle-right-heading
    font-weight: 600
    font-size: 16px
    color: #06243C

    +breakpoint(small only)
      font-size: 10px
      font-weight: 500

  &__middle-right-italic
    font-weight: 300
    font-size: 12px
    font-style: italic
    display: inline
    color: #06243C

    +breakpoint(small only)
      font-size: 8px

  &__content-delete-icon
    background-image: url('../../images/icons/trash_can.png')
    width: 24px
    height: 22px
    background-size: cover

    +breakpoint(small only)
      width: 12px
      height: 12px

  &__first-last-name
    height: fit-content
    padding: 14px 0px
    text-transform: uppercase
    letter-spacing: 0.2em
    color: #06243C

    +breakpoint(small only)
      padding: 0px

  &__content-delete-text
    font-size: 16px
    font-weight: 500
    pointer-events: none

    +breakpoint(small only)
      font-size: 8px

  &__left-container-gray

    +breakpoint(small only)
      height: fit-content
      line-height: 70%

  &__content-row-right
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    flex-grow: 2
