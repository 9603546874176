.p-users__sign_in
  .primary-navigation,
  .primary-navigation-trigger

    +breakpoint(nav-header up)
      display: none

  .page-wrapper
    top: 0

  .top-bar
    .logo
      background-image: url('./../../images/logos/logo-color.svg')

    +breakpoint(nav-header up)
      background-color: transparent

      .logo
        background-image: url('./../../images/logos/logo-white.svg')

        +breakpoint(large)
          background-image: url('./../../images/logos/logo-color.svg')

  .signup-menu
    .separator-center
      text-align: left !important

    .separator-left,
    .separator-center
      &::after
        margin: 0.5rem auto 0

.signin-feature
  height: 100vh
  background:  url('../../images/backgrounds/signin-background.jpg') left center /cover
