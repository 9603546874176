.p-users-modules__index
  .user-modules
    &__banner
      height: $g8-5x
      width: 100%

      +breakpoint(large)
        margin-top: $g8*7.5

    &__container
      margin-bottom: $g8-8x
      margin-top: $g8-8x

      +breakpoint(small only)
        padding: 0 $g8-2x

      .scroller__title
        font-size: $large-font-size

.p-users-modules__show
  .scroller__title, .title_line h4
    color: $black-3
    font-size: $large-font-size

  .scroller__title::after
    top: rem-calc(13)
