.cpd-landing-page
  position: relative
  font-family: $font-stack-poppins

  &__wrapper
    padding-top: 80px

  &__product-header
    margin-top: 5rem
    height: 15.625rem
    background-image: url('//images.ctfassets.net/19hllh2ssa3x/6IFLeTe85oDKQKfc6CbtnP/77e5c96e62f87c76b6fcadd9a1414339/pattern_orange.svg')
    border-radius: 0 0 100px 0px
    background-position: left 0% bottom 45%
    background-size: cover

    +breakpoint(small only)
      background-size: auto
      height: 12.5rem
      background-position: left 47.5% bottom 65%

    &--lxp
      margin-top: 0

  &__back
    display: flex
    flex-direction: row
    pointer-events: all
    font-weight: 500
    color: #ED6E56
    column-gap: 30px

    +breakpoint(small only)
      display: none

    &:hover
      color: #003166

  &__back-arrow
    font-size: 50px
    line-height: 20px

  &__header-text
    padding-top: 5rem
    font-size: 58px
    padding-left: 5rem
    vertical-align: middle
    color: white
    font-weight: 600

    +breakpoint(small only)
      padding-left: 1.25rem
      font-size: 48px
      padding-top: 1rem

  &__content
    padding-left: 5.625rem
    padding-right: 5.625rem

    +breakpoint(small only)
      padding-left: 1.25rem
      padding-right: 1.25rem

  &__content-top

    +breakpoint(small only)
      padding-top: 3.75rem

  &__close
    padding-top: 3rem
    padding-bottom: 3rem
    font-size: 24px

    +breakpoint(small only)
      display: none

  &__overview
    padding-bottom: 2.4rem
    font-size: 36px
    font-weight: 600
    color: #06243C

    +breakpoint(small only)
      padding-bottom: 1.375rem

  &__content-body
    display: flex
    flex-direction: row
    width: 100%
    column-gap: 2.5rem

    @media only screen and (max-width : 1299px)
      flex-direction: column
      padding-bottom: 4.375rem

    +breakpoint(small only)
      flex-direction: column
      justify-content: space-between
      row-gap: 4rem


  &__left
    flex: 7
    min-width: 780px
    flex-grow: 0

    +breakpoint(medium down)
      min-width: auto

  &__left-content
    display: flex
    flex-direction: column
    justify-content: space-between
    row-gap: 2.5rem

  &__right
    flex: 4

  &__designations
    padding-bottom: 4.375rem

    +breakpoint(small only)
      padding-bottom: 0rem

  &__fee-reminder
    max-width: 440px

    +breakpoint(small only)
      padding-bottom: 3.75rem

  &__page-close
    background-image: url('./../../images/icons/close-arrow.svg')
    width: 45px
    height: 46px

    +breakpoint(small only)
      display: none

  &__page-close-text
    font-size: 24px
    font-weight: 500

    +breakpoint(small only)
      display: none

    &:hover
      cursor: pointer

  &__page-close-flex
    display: flex
    flex-direction: row
    gap: 15px
    align-items: center

    +breakpoint(small only)
      display: none
