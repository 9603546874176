.gray-bar

  &__content-flex
    background-color: rgba(229, 229, 229, 0.5)
    display: flex
    flex-direction: row
    padding: 20px 20px 20px 20px
    white-space: nowrap
    gap: 10px

    +breakpoint(small only)
      background-color: #ffff
      flex-direction: column
      gap: 20px
      padding: 0

  &__content-left
    display: flex
    flex-direction: column
    flex-grow: 1

    +breakpoint(small only)
      background-color: rgba(229, 229, 229, 0.5)
      padding: 10px

  &__content-row
    display: flex
    flex-direction: row

  &__content-top-left
    font-size: 16px
    font-weight: 300

  &__content-top-right
    font-size: 16px
    font-weight: 600

  &__content-bottom-left
    font-size: 12px
    font-weight: 600

  &__content-bottom-right
    font-size: 12px
    font-weight: 300


  &__content-right
    display: flex
    flex-direction: row
    justify-content: flex-end

    +breakpoint(small only)
      padding: 10px
      justify-content: flex-start
      background-color: rgba(229, 229, 229, 0.5)
