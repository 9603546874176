.total-payment-card
  box-shadow: 0px 3px rem-calc(10) rgba(14, 24, 40, 0.1)
  margin-bottom: $g8-8x

  h4, p
    margin: 0

  &__user,
  &__employer
    align-items: center
    display: flex
    justify-content: space-between
    padding: rem-calc(20)

  &__user
     background-color: $info-color
     min-height: rem-calc(90)

  &__employer
    background-color: $pale-blue
    font-size: $tiny-font-size

.general-payment-card
  background-color: $lightest-gray
  margin-bottom: rem-calc(20)
  padding: rem-calc(20)

  p
    font-size: $tiny-font-size

  &__info
    display: flex
    justify-content: space-between

  &__module-title
    font-size: $medium-font-size
    font-weight: 600
    margin: rem-calc(15) 0

  &__module-details
    font-size: $tiny-font-size

  .toggle-message
    padding-bottom: 0

    .input-container
      label
        font-size: $medium-font-size

      &.focused
        label
          font-size: rem-calc(11)
          left: rem-calc(23)
          top: 0

    p
      margin: 0

.additional-info
  &__title
    font-size: $small-font-size
    margin-bottom: $g8-4x

  &__toggles
    .toggle
      margin: $g8-3x 0

    .toggle-message
      display: none !important

  &__block
    background-color: $lightest-gray
    height: $g8*40
    overflow-y: scroll
    padding: $g8-3x
    scroll-behavior: smooth

    .sla-declaration
      font-size: $tiny-font-size
      line-height: $g8-3x

      u
        font-size: $medium-font-size
        font-weight: 600

        a
          font-size: $tiny-font-size

.additional-info__block::-webkit-scrollbar
  width: $g8

.additional-info__block::-webkit-scrollbar-track
  background: $medium-gray

.additional-info__block::-webkit-scrollbar-thumb
  background: $secondary-color

.additional-info__block::-webkit-scrollbar-corner
  background-color: $medium-gray
