.p-areas
  .programme-information
    +breakpoint(medium down)
      //margin-bottom: $g8*11

  .programme-area
    margin: $g8-10x $g8-2x

    +breakpoint(large)
      margin: $g8*12 $g8*14

    a
      font-weight: 600

    &__left
      +breakpoint(medium)
        padding-right: $g8-4x

      +breakpoint(xlarge)
        padding-right: $g8*15

    &__media
      +background-image(no-repeat, contain, left, center)
      height: $g8*45
      width: 100%

    &__secondary-title
      margin: $g8-5x 0 $g8-3x 0

    &__title
      color: $black-3
      hyphens: auto
      line-height: 120%
      word-wrap: break-word

    &__secondary-title
      +breakpoint(small only)
        margin-top: $g8-8x

    &__description
      color: $black-3
      font-size: rem-calc(17)
      font-weight: 300
      letter-spacing: $letter-spacing
      line-height: rem-calc(20)
      margin-bottom: $g8-5x

    blockquote
      border-left: rem-calc(3) solid $secondary-color
      padding: 0 0 0 $g8-5x

      +breakpoint(small only)
        margin-bottom: $g8-6x

      p
        color: $gray
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-style: italic
        line-height: $xlarge-font-size
        margin-bottom: $g8-3x

        +breakpoint(large)
          font-size: $default-font-size

      footer
        color: $black
        font-family: $font-stack-poppins
        font-size: $tiny-font-size

    &__paragraph
      line-height: rem-calc(26)

      h1, h2, h3, h4, h5, h6
        font-family: $font-stack-poppins
        font-weight: normal

    &__scroller
      margin: 0 rem-calc(20) $g8-6x rem-calc(20)

    ul
      +unordered-list-orange

    ol
      +ordered-list-orange
