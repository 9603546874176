$flagsImagePath: "~intl-tel-input/build/img/" !default;

.iti {
  width: 100%;

  [type='text']:focus {
    background-color: $light-gray !important;
    border-style: solid;
    border-width: 0 0 rem-calc(3) 0;
    border-color: transparent;
    box-shadow: none;
    transition: none;
  }

  input::placeholder {
    font-size: 0.9em
  }
}

.iti__country-list {
  z-index: 10 !important;
  background-color: $light-gray !important;

  .iti__dial-code {
    color: $gray;
  }
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: $light-gray !important;
  height: 97%;
}
