.p-programmes-enrolments,
.p-programmes-reregistrations
  .input-toggle
    margin: $g8-5x 0

    .searchable-dropdown
      +breakpoint(large)
        margin-left: $g8-6x
        width: 90%

    .input-container.has-message,
    .input-container.error
      margin-bottom: $g8-4x

    .collection_radio_buttons
      font-size: $small-font-size !important

      +breakpoint(xlarge)
        font-size: $medium-font-size !important

    .radio
      margin: $g8-2x $g8-3x

  .input-toggle input[type='radio'] + label:after
    top: rem-calc(5)

  .input-toggle .radio label
    color: $black !important
