.info-show
  &__tabs
    background-color: $white
    border: none
    left: -$g8-2x

    +breakpoint(medium only)
      left: -$g8-3x

    +breakpoint(medium down)
      background-color: $lightest-gray
      position: relative
      width: 100vw

    .tabs
      background-color: $lightest-gray
      border: none
      display: block

      +breakpoint(medium only)
        padding-left: $g8-3x

      +breakpoint(large)
        display: initial

      +breakpoint(small only)
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        align-items: center

    .tabs-title
      outline: none

      +breakpoint(small)
        padding: 0 5%

      +breakpoint(large)
        padding: 0 $g8-7x 0 0

      &.is-active a
        border-top: 3px solid $secondary-color
        color: $black-3

      a
        background: none
        border-top: 3px solid transparent
        color: $dark-gray
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-weight: bold
        outline: none
        padding: $g8-3x 0

        &:hover
          text-decoration: none

    .tabs-content
      background-color: $white
      border-bottom: 1px solid $light-gray
      border: none
      color: $black
      padding-bottom: $g8-5x
      padding-top: $g8-4x

      .tabs-panel
        padding: 0

  &__general-tab, &__requirements-tab,
  &__how-to-tab, &__guidelines-tab
    +breakpoint(medium)
      &.grid-container
        padding-left: $g8-8x
        padding-right: $g8-8x

    h1, h2, h3, h4, h5, h6
      font-family: $font-stack-poppins
      margin-bottom: $g8-3x
      margin-top: $g8-2x

    p, ul, ol
      font-size: $medium-font-size

    ul
      +unordered-list-orange
      p
        margin-bottom: 0

    ol
      +ordered-list-orange
      p
        margin-bottom: 0

    hr
      border-bottom: 1px solid $light-gray
      margin: $g8-4x 0

    .rich_image
      max-height: $g8*29

  &__general-tab
    p
      margin-bottom: 0
