.info-expand
  padding: $g8-3x $g8-3x 0 $g8-3x
  &__second-element
    display: none

  &__button
    color: $secondary-color
    cursor: pointer
    font-weight: 600
    padding-left: $g8
