.my-details
  &__navigation
    margin-top: $g8-4x
    margin-bottom: 0

    &--button
      .button
        margin-bottom: 0

      +breakpoint(small only)
        margin-bottom: $g8-3x
        text-align: center
        .button
          width: 100%

  .tabs
    border: none
    height: $g8-8x
    overflow-x: auto
    overflow-y: hidden
    white-space: nowrap

    +breakpoint(small only)
      background-color: $lightest-gray
      left: -$g8-2x
      position: relative
      width: 100vw

    .tabs-title
      display: inline-block
      float: none
      outline: none

      +breakpoint(small only)
        padding: 0 5% 0 5%

      +breakpoint(medium)
        padding: 0 $g8-7x 0 0

      &.is-active a
        border-top: 3px solid $secondary-color
        opacity: 1

      a
        background: none
        border-top: 3px solid transparent
        color: $black-3
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-weight: bold
        opacity: 0.25
        outline: none
        padding: $g8-3x 0

        &:hover
          text-decoration: none

  .tabs-content
    border: none

    .tabs-panel
      padding: 0

  .status
    position: relative
    width: 100%

    +breakpoint(large)
      width: 50%

    &.error
      background: map-get($accent-colors, pale-red)
      border: 1px solid $input-error-color
      color: $input-error-color
      margin-bottom: $g8-4x
      padding: $g8-2x

    &.notice
      background-color: map-get($accent-colors, pale-green)
      border: 1px solid map-get($accent-colors, green)
      color: map-get($accent-colors, green)
      margin-bottom: $g8-4x
      padding: $g8-2x
