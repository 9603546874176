.grid-masonry
  .grid-masonry:after
    clear: both
    content: ''
    display: block

  .grid-sizer,
  .grid-item
    width: 50%
    float: left

    +breakpoint(medium)
      width: 33.33%

  .grid-item img
    display: block
    max-width: 100%
    outline: none
    padding: rem-calc(2)

    &:hover
      cursor: zoom-in

