.p-users__new, .p-confirmations
  .page-wrapper
    top: 0

  .primary-navigation,
  .primary-navigation-trigger

    +breakpoint(nav-header up)
      display: none

  .top-bar
    background-color: $white

    .logo
        background-image: url('./../../images/logos/logo-color.svg')

    +breakpoint(large up)
      background-color: transparent

      .logo
        background-image: url('./../../images/logos/logo-color.svg')

        +breakpoint(large)
          background-image: url('./../../images/logos/logo-white.svg')

  .signup-menu
    .separator-center
      text-align: left !important

    .separator-left,
    .separator-center
      &::after
        margin: 0.5rem auto 0

  .scrollable
    overflow-y: scroll

.p-users__new
  .sign-up-feature
    height: 100vh
    background:  url('../../images/backgrounds/signup-background.jpg') left center /cover

  .scrollable
    height: 100vh

  .form
    &__actions
      margin-top: $g8-4x

      .button
        min-width: $g8*17

  .data-protection-notice
    &__heading
      font-weight: 300
      font-size: $small-font-size
      line-height: 120%
      letter-spacing: $letter-spacing
      text-transform: uppercase

    ::-webkit-scrollbar
      width: $g8

    ::-webkit-scrollbar-track
      background: $medium-gray

    ::-webkit-scrollbar-thumb
      background: $secondary-color

    ::-webkit-scrollbar-corner
      background-color: $medium-gray

    &__field
      background-color: $lightest-gray
      padding: $g8-3x
      max-height: rem-calc(320)
      overflow-y: scroll
      text-align: justify
      text-justify: auto

      h3
        font-size: $medium-font-size
        margin: 0
        font-style: normal
        font-weight: normal

      p, ul
        font-size: $tiny-font-size

.trial-error
  color: #ff1818
