.continuous-assessment
  .file-list
    label
      font-size: $default-font-size !important
      margin-top: $g8-2x
      margin-bottom: $g8-2x

  .upload-status
    .description
      border-bottom: rem-calc(5) solid map-get($accent-colors, green)

    &[data-hidden]
      display: none

    &.error
      .description
        border-bottom: rem-calc(5) solid $input-error-color

  .assessment__container
    &[data-hidden]
      display: none

  .module-title span
      color: $gray

  .accordion
    width: 100%

    .accordion-title
      border: none
      color: $secondary-color
      font-weight: bold
      text-align: right
      height: auto
      padding: 0

      &:hover, &:focus
        background: none
        color: $primary-color

      &::before
        content: ''

    .accordion-content
      border: none

    ul.styled-list

      li::before
        background: $secondary-color
        border: none
        width: $g8
        height: $g8
        left: -1.8rem
        top: $g8