.p-programmes__show
  .awarding_body_logo
    background-color: $medium-gray
    border-radius: rem-calc(30)
    height: rem-calc(58)
    margin-bottom: rem-calc(10)
    margin-top: rem-calc(55)
    margin: auto
    width: rem-calc(58)

  .disabled-input
    height: auto
    margin-bottom: 20px
    margin-top: $g8-2x
    padding: $g8-2x

    ul
      +unordered-list-orange
      margin-top: $g8-2x

    &__value
      color: $black-3

  #curriculumOffCanvas
    z-index: 301

    &.position-right
      transform: translateX(100vw)
      width: 100vw

      &.is-open
        transform: translate(0, 0)

      &.is-closed
        width: 0

    .top-bar
      box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1)
      color: $primary-color
      font-family: $font-stack-poppins
      font-size: $medium-font-size
      padding: $g8-3x $g8-4x
      position: -webkit-sticky
      position: sticky
      top: 0
      width: 100%

      .top-bar-left, .top-bar-right
        flex: 0 0 auto

    .curriculum-content
      margin: $g8-5x $g8-4x

    .menu-text
      font-weight: 600
      padding: 0

  .programme-information
    h2, h3, h4
      font-family: $font-stack-poppins
      font-weight: normal

    h2
      color: $black-3
      margin-top: $g8-4x

    h3
      margin: $g8-5x 0 $g8-3x 0

    h4
      margin: $g8-5x 0 $g8-5x 0

    h5
      font-family: $font-stack-poppins
      margin-bottom: 0
      margin-top: $g8-2x

    &__level-indicator
      background-color: $white
      border-radius: $g8-5x
      font-family: $font-stack-poppins
      font-size: rem-calc(35)
      font-weight: 600
      height: rem-calc(85)
      left: rem-calc(37)
      position: absolute
      top: rem-calc(25)
      width: rem-calc(85)

      +breakpoint(small only)
        left: 40%
        top: -$g8-3x

      span
        display: block
        width: rem-calc(19)
        height: $g8-5x
        margin: $g8-2x auto

    &__curriculum
      background-color: $lightest-gray
      color: $black-3
      font-family: $font-stack-poppins
      font-size: $small-font-size
      padding: $g8*13 $g8-3x $g8-5x $g8-3x

      h3
        color: $black
        font-family: $font-stack-poppins
        font-weight: 600

      hr
        border: rem-calc(2) solid $medium-gray

    &__details
      padding: $g8-5x $g8-4x 0 $g8*15

      +breakpoint(large down)
        padding: $g8-4x $g8-3x

      +breakpoint(small only)
        padding: $g8-9x $g8-2x

      a
        font-weight: 600

      .curriculum-button
        background-color: $light-gray
        color: $primary-color
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-weight: 600
        margin: $g8-4x 0
        padding: $g8-2x $g8-3x

      .designation
        font-family: $font-stack-poppins
        font-size: $medium-font-size
        font-weight: 300
        letter-spacing: $letter-spacing
        line-height: $g8-3x
        text-transform: uppercase

      &--content
        .tabs
          border: none
          // height: $g8-8x
          overflow-x: auto
          overflow-y: hidden
          white-space: nowrap

          +breakpoint(small only)
            background-color: $lightest-gray
            left: -$g8-2x
            position: relative
            width: 100vw

          .tabs-title
            display: inline-block
            float: none
            outline: none

            +breakpoint(medium)
              padding: 0 $g8-7x 0 0

            +breakpoint(small only)
              padding: 0 5% 0 5%

            &.is-active a
              // border-top: 3px solid $secondary-color
              opacity: 1

            a
              background: none
              border-top: 3px solid transparent
              color: $black-3
              font-family: $font-stack-poppins
              font-size: $medium-font-size
              font-weight: bold
              opacity: 0.25
              outline: none
              padding: $g8-3x 0

              &:hover
                text-decoration: none

        .tabs-content
          border: none
          padding-top: $g8-3x
          background: #FFFFFF
          box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
          border-radius: 14px

          .tabs-panel
            padding: 0
            font-weight: 300
            font-size: 16px
            color: #6F7889

            & h4
                font-style: normal
                font-weight: 600
                font-size: 23px
                line-height: 120%
                color: #06243C
                padding-bottom: 20px
            & h2
                font-style: normal
                font-weight: 600
                font-size: 33px
                line-height: 120%
                color: #06243C
                padding-bottom: 20px
            & p
                font-style: normal
                font-weight: 300
                font-size: 16px
                line-height: 160%
                color: #6F7889
                & b
                  font-weight: bold

            ul
              +unordered-list-orange
              margin-left: 0 !important

            .information-cards
              .card
                background-color: $lightest-gray
                border: none
                padding: $g8-4x

                .card-title
                  font-family: $font-stack-poppins
                  font-size: $small-font-size
                  font-weight: 300
                  letter-spacing: $letter-spacing
                  text-transform: uppercase

                p
                  font-family: $font-stack-poppins
                  font-size: $medium-font-size
                  line-height: $g8-3x
.round-container
  padding: 20px
  margin-top: 30px
  background: #FFFFFF
  box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
  border-radius: 14px
  +breakpoint(small only)
    padding: 10px
.product-tabs
  background-color: #FAFAFA
  padding-top: 50px
  position: relative
  .fake-bottom
    height: 45px
    width: 100%
    background-color: #F2F3F5
    position: absolute
    bottom: 0
    z-index: 2
#details-tabs
  border: none
  background: none
  padding-top: 30px
  padding-left: 30px
  -ms-overflow-style: none
  scrollbar-width: none
  overflow-y: scroll

#details-tabs::-webkit-scrollbar
  display: none

.product-tabs > .tabs-title > a:focus, .product-tabs > .tabs-title > a[aria-selected="true"]
  background: none
  border-bottom: 4px solid #ED6E56
  color: #06243C !important

.product-tabs .tabs-title > a
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 120%
  color: #6F7889
  padding: 20px 0 20px 0
  margin-right: 30px

.programme-testimonial
  //margin-top: -50px

.product-header .header-tags a
  font-weight: 300
  color: #fff

  &:hover
    color: #06243c

.product-tabs .tabs-title > a:hover a:focus
  background: none

.course-stats
  width: 100%
  height: 105px
  margin-top: -70px
  &__stat
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    height: 105px
    width: 100%
    background: #FFFFFF
    box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
    border-radius: 14px 14px 14px 14px
    +breakpoint(medium down)
      position: relative
      flex-direction: column
      height: auto
      flex-wrap: nowrap
      width: 90%
      margin: 0 auto
    &__block
      display: flex
      flex-direction: column
      width: 19%
      height: 80%
      padding: 15px
      margin: 10px auto
      text-align: center
      +breakpoint(medium down)
        width: 90%
        flex-direction: row
        justify-content: space-between
        align-items: flex-end
      &__one
            padding-right: 30px
            background: transparent url('./../../images/icons/clock.svg') no-repeat left
      &__two
            padding-right: 30px
            background: transparent url('./../../images/icons/module.svg') no-repeat left
      &__three
            padding-right: 30px
            background: transparent url('./../../images/icons/weekly.svg') no-repeat left
      &__four
            padding-right: 30px
            background: transparent url('./../../images/icons/wallet.svg') no-repeat left
      &__five
            padding-right: 30px
            background: transparent url('./../../images/icons/bulb.svg') no-repeat left
      &__last__dashed
          border-top: 1px dashed #D4D7DD
          font-weight: 500
          font-size: 16px
          line-height: 160%
          color: #6F7889 !important
          padding-top: 5px
          margin-top: 5px
      & li
          list-style: none
          font-style: normal
          font-weight: 500
          font-size: 16px
          line-height: 160%
          color: #ADB5C2
.programme-information__details--content
  width: 100%
  +breakpoint(medium down)
    #details-tabs
      padding-top: 350px
.programme-overview
  display: flex
  flex-direction: row
  width: 100%
  padding: 30px
  +breakpoint(medium down)
    flex-direction: column
    padding: 10px
  &__left
    width: 45%
    min-height: 200px
    padding: 10px
    +breakpoint(medium down)
      width: 100%
    & h2
        font-style: normal
        font-weight: 600
        font-size: 33px
        line-height: 120%
        color: #06243C
        padding-top: 50px
    & p
        font-style: normal
        font-weight: 300
        font-size: 16px
        line-height: 160%
        color: #6F7889
        padding-top: 40px

  &__right
    width: 48%
    min-height: 200px
    padding: 10px
    +breakpoint(medium down)
      width: 100%
    &__box_wrapper
       display: flex
       flex-direction: row
       padding-top: 0px
    & h3
        font-style: normal
        font-weight: 500
        font-size: 18px
        line-height: 120%
        color: #06243C
    & li
      //padding-left: 30px
      background: transparent url('./../../images/icons/tick.svg') no-repeat top left
      list-style: none
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 120%
      color: #6F7889
      margin: 0 45px
    &__leftbox
      width: 100%
      padding-top: 50px
      & ul
          display: flex
          flex-direction: column
          flex-wrap: wrap
          align-content: left
          justify-content: center
          +breakpoint(medium down)
            flex-wrap: nowrap
            height: auto
      & p
          padding-left: 30px
    &__rightbox
      width: 45%
  &__bottom_box
    display: flex
    flex-direction: row
    align-items: flex-start
    justify-content: space-between
    padding: 40px
    position: static
    width: 95%
    min-height: 500px
    background: #FAFAFA
    border-radius: 14px
    margin: 0px auto
    +breakpoint(medium down)
      flex-direction: column
      padding: 10px
      width: 100%
      margin: 0
    & li
        font-style: normal
        font-weight: 300
        font-size: 16px
        line-height: 160%
        color: #6F7889
        padding-left: 30px
        background: transparent url('./../../images/icons/dot.svg') no-repeat left
        background-position: left top
    & ul
        list-style: none
        margin-left: 0 !important
    & h3
        font-style: normal
        font-weight: 500
        font-size: 18px
        line-height: 120%
        color: #06243C
    &__left
      width: 45%
      min-height: 300px
      +breakpoint(medium down)
        width: 100%
    &__right
       width: 45%
       min-height: 50px
       +breakpoint(medium down)
          width: 100%
       &__manager
          display: flex
          flex-direction: row
          align-items: center
          padding: 20px 30px
          justify-content: space-between
          width: 405px
          height: 130px
          background: #FFFFFF
          border-radius: 14px
          margin-top: 20px
          +breakpoint(medium down)
            width: 100%
          &__img
            width: 30%
            height: 100%
            &__round
              border-radius: 50%
              width: 90px
              height: 90px
          &__text
            width: 65%
            height: 100%
            padding-top: 20px
            & span
                font-style: normal
                font-weight: 500
                font-size: 16px
                line-height: 160%
                color: #06243C
            & p
                font-weight: 300
                font-size: 16px
                line-height: 160%
                color: #6F7889

.programme_designation_tab
  width: 100%
  min-height: 400px
.programme-designation
  width: 100%
  padding: 30px
  & ul
      list-style: none
      margin-left: 0 !important
  & li
    font-style: normal
    font-weight: 300
    font-size: 16px
    line-height: 160%
    color: #6F7889
    padding-left: 30px
    margin-bottom: 30px
    background: transparent url('./../../images/icons/dot.svg') no-repeat left
    background-position: left top
    & p
       padding-top: 0px
       line-height: 160%
  & h4
      font-style: normal
      font-weight: 600
      font-size: 23px
      line-height: 120%
      color: #6F7889
      padding-bottom: 20px
  & h2
      font-weight: 600
      font-size: 33px
      line-height: 120%
      letter-spacing: -0.03em
      color: #06243C
      padding-bottom: 20px

  & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      color: #6F7889


.programme_key-dates_tab
  width: 100%
  min-height: 400px
.programme-key-dates
  width: 100%
  padding: 30px
  +breakpoint(small only)
    padding: 30px 10px 10px 10px
  & ul
      list-style: none
      margin-left: 0 !important
  & li
    font-style: normal
    font-weight: 300
    font-size: 16px
    line-height: 160%
    color: #6F7889
    padding-left: 30px
    margin-bottom: 30px
    background: transparent url('./../../images/icons/dot.svg') no-repeat left
    background-position: left top
    & p
       padding-top: 0px
       line-height: 160%
  & h4
      font-style: normal
      font-weight: 600
      font-size: 23px
      line-height: 120%
      color: #6F7889
      padding-bottom: 20px
  & h2
      font-style: normal
      font-weight: 600
      font-size: 33px
      line-height: 120%
      color: #06243C
      padding-bottom: 20px
  & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      color: #6F7889
.programme_modules_tab
  width: 100%
  min-height: 400px
.programme_modules
  width: 100%
  padding: 30px
  & h4
      font-style: normal
      font-weight: 600
      font-size: 23px
      line-height: 120%
      color: #6F7889
      padding-bottom: 20px
  & h2
      font-style: normal
      font-weight: 600
      font-size: 33px
      line-height: 120%
      color: #06243C
      padding-bottom: 20px
  & p
      font-style: normal
      font-weight: 300
      font-size: 16px
      line-height: 160%
      color: #6F7889
  & .rich-text-accordion
      border-bottom: 1px solid #ADB5C2
      background: transparent url('./../../images/icons/qfa.svg') no-repeat top left
      padding-left: 70px
      & p > b
          font-style: normal
          font-weight: 600
          font-size: 14px
          line-height: 120%
          text-transform: uppercase
          color: #ED6E56
      & a[aria-expanded='false']::before
          content: "" !important
          background: transparent url('./../../images/icons/arrow_down.svg') no-repeat
          width: 70px
          height: 70px
      & a[aria-expanded='true']::before
          content: "" !important
          background: transparent url('./../../images/icons/arrow_down.svg') no-repeat
          transform: rotate(180deg)
          width: 70px
          height: 70px
  & div:first-child
    margin-top: 40px

.p-programmes__show .programme-information__details--content .tabs-content .tabs-panel ul li::before
  content: ""



.bottom-bg
  background-color: #d4d7dd
  //z-index: -1
  height: 100px
  width: 100%
  position: relative
  bottom: 0
