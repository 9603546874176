.my-institute
  &__cpd-empty, &__modules-empty
    height: $g8*41
    position: relative

    .grid-frame
      margin: 0 $g8-4x $g8-4x $g8-4x

      +breakpoint(small only)
        text-align: center

    &--graphic
      +background-image(no-repeat, cover, left, center)
      height: $g8-6x
      left: 0
      position: absolute
      right: 0
      top: 0
      width: 100%

    +breakpoint(medium)
      &--image
        +background-image(no-repeat, contain, right, center)
        height: $g8*36
        position: absolute
        right: 0
        top: 0
        width: $g8*36

  /// CPD
  &__cpd-empty
    background-color: $lightest-gray

    &--graphic
      background-image: url('./../../images/backgrounds/accreditation-decoration-mobile.svg')

    +breakpoint(medium)
      &--image
        background-image: url('./../../images/backgrounds/cpd-empty-image.png')

      &--cell-50
        width: 50% !important

    +breakpoint(large)
      &--cell-25
        width: 50% !important

    +breakpoint(xlarge)
      &--cell-25
        width: 25% !important

  /// Modules
  &__modules-empty
    background-color: $gray

    &--graphic
      background-image: url('./../../images/empty_state_banners/empty-modules.svg')

    +breakpoint(medium)
      &--image
        background-image: url('./../../images/backgrounds/modules-empty-image.png')

      &--cell-50
        width: 50% !important

    +breakpoint(large)
      &--cell-30
        width: 40% !important

    +breakpoint(xlarge)
      &--cell-30
        width: 30% !important

  /// Events
  &__events-empty
    background-color: $light-primary
    height: $g8*34
    position: relative

    +breakpoint(small only)
      height: $g8*41

    .grid-frame
      margin: 0 $g8-4x $g8-4x $g8-4x

      +breakpoint(small only)
        text-align: center

    &--graphic
      +background-image(no-repeat, cover, left, center)
      background-image: url('./../../images/empty_state_banners/empty-events.svg')
      height: $g8-6x
      left: 0
      position: absolute
      right: 0
      top: 0
      width: 100%

    +breakpoint(medium)
      &--image
        +background-image(no-repeat, contain, right, center)
        background-image: url('./../../images/backgrounds/events-empty-image.png')
        height: $g8*34
        position: absolute
        right: $g8-3x
        top: 0
        width: $g8*46

      &--cell-30
        width: 40% !important

      &--cell-50
        width: 40% !important

    +breakpoint(large)
      &--cell-30
        width: 40% !important

    +breakpoint(xlarge)
      &--cell-30
        width: 100% !important

      &--cell-50
        width: 30% !important
