
.members-banner
  background-color: rgba(255, 255, 255, 0.75)
  padding: 80px 0px 20px

  &__swiper-wrapper-container
    width: 80% !important

  &__header
    font-family: $font-stack-poppins
    font-style: normal
    font-weight: 600
    font-size: 46px
    letter-spacing: -0.03em
    color: #06243C
    display: flex
    flex-direction: row
    justify-content: center
    margin-left: auto
    margin-right: auto
    padding-bottom: 20px

    +breakpoint(small only)
      font-size: 16.5px

  &__logos
    padding: 0px 100px 10px
    height: 46px
    align-items: center

  .swiper-wrapper
    transition-timing-function: linear
    padding: 0px 0px 100px 0px

.logo-carousel-swiper-pagination
  position: absolute
  text-align: center
  transition: 0.3s opacity
  transform: translate3d(0, 0, 0)
  z-index: 10

.swiper-pagination-bullet-active
  opacity: var(--swiper-pagination-bullet-opacity, 1)
  background-color: #06243C

.logo-carousel-swiper-button-prev,
.logo-carousel-swiper-button-next
  position: absolute
  top: var(--swiper-navigation-top-offset, 50%)
  width: calc(var(--swiper-navigation-size) / 44 * 27)
  height: var(--swiper-navigation-size)
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2))
  z-index: 10
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  background-color: #06243C
  border-radius: 17px
  color: #FFFFFF
  width: 35px
  height: 35px
  left: 35px
  
.logo-carousel-swiper-button-prev,
.logo-carousel-swiper-button-next 
  &:after 
    font-family: swiper-icons
    font-size: var(--swiper-navigation-size)
    text-transform: none !important
    letter-spacing: 0
    font-variant: initial
    line-height: 1
    font-size: 15px

.logo-carousel-swiper-button-prev,
.swiper-rtl .logo-carousel-swiper-button-next
  left: 43%
  right: auto
  top: 80%

  +breakpoint(small only)
    left: 27%

  +breakpoint(large only)
    left: 40%

  +breakpoint(xxxlarge only)
    left: 42%

  &:after 
    content: url('../../images/icons/left-scroller-arrow-white.svg')

.logo-carousel-swiper-button-next,
.swiper-rtl .logo-carousel-swiper-button-prev
  left: auto
  right: 40%
  top: 80%

  +breakpoint(small only)
    right: 27%

  +breakpoint(large only)
    right: 36%

  +breakpoint(xxxlarge only)
    right: 42%
    
  &:after 
    content: url('../../images/icons/right-scroller-arrow-white.svg')

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction
  bottom: 30px
