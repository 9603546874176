.feature-banner
  box-shadow: $prototype-box-shadow
  display: flex
  flex-direction: column
  height: rem-calc(390)
  justify-content: center
  margin: 0 auto
  position: relative
  width: 98%

  +breakpoint(large)
    margin-top: rem-calc(30)

  &__content
    padding: $g8-4x

    +breakpoint(large)
      padding: $g8-8x
      width: 80%

    h3
      line-height: 120%
      padding-right: 10%

    p
      margin-top: rem-calc(30)

      +breakpoint(large)
        margin: rem-calc(40) 0

    a
      align-self: flex-start

    .button
      min-width: $g8*22

  &--orange
    background-color: $lightest-orange

  &--blue
    background-color: lighten($lightest-blue, 5%)

  &__graphic
    +background-image(no-repeat, cover, center, center)
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: rem-calc(55)

    +breakpoint(large)
      bottom: 0
      height: 100%
      left: initial
      position: absolute
      right: 0
      top: 0
      width: rem-calc(55)

    &--orange
      background-image: url('../../images/backgrounds/part-time-feature-banner-bottom.svg')

      +breakpoint(large)
        background-image: url('../../images/backgrounds/part-time-feature-banner.svg')

    &--blue
      background-image: url('../../images/backgrounds/post-grad-feature-banner-bottom.svg')

      +breakpoint(large)
        background-image: url('../../images/backgrounds/post-grad-feature-banner.svg')

.feature-carousel
  position: relative
