.category-overview-wrapper
    width: 100%
    min-height: 370px
    padding: 1rem 0
    margin: 50px 0
    +breakpoint(small only)
        padding: 0
        margin: 0
.category-overview
    display: flex
    flex-direction: row
    height: 100%
    width: 100%
    justify-content: space-between
    padding-bottom: 30px
    +breakpoint(medium down)
        flex-direction: column
        justify-content: unset
    &__video
        width: 50%
        height: 380px
        +breakpoint(medium down)
            width: 100%
            height: 260px
        & .video-embed-container
            height: 380px
            +breakpoint(small only)
                height: 260px
        & iframe
            width: 100%
            height: 370px
            filter: drop-shadow(0px 20px 40px rgba(6, 36, 60, 0.07)) drop-shadow(0px 40px 100px rgba(6, 36, 60, 0.03))
            border-radius: 14px
            background-color: #ffffff
    &__img
        width: 560px
        +breakpoint(medium down)
            width: 100%
            padding: 30px 20px 20px 20px

        img
            filter: drop-shadow(0px 20px 40px rgba(6, 36, 60, 0.07)) drop-shadow(0px 40px 100px rgba(6, 36, 60, 0.03))
            border-radius: 14px

    &__text
        width: 50%
        height: 100%
        flex-wrap: wrap
        padding: 30px 70px 20px 20px
        +breakpoint(medium down)
            width: 100%
            padding: 30px 20px 20px 20px
        & p
            font-style: normal
            font-weight: 300
            font-size: 16px
            line-height: 160%
            letter-spacing: -0.01em
            color: #6F7889
        & h4
            font-style: normal
            font-weight: 600
            font-size: 33px
            line-height: 120%
            letter-spacing: -0.03em
            color: #06243C
    &__span
        font-style: normal
        font-weight: 300
        font-size: 16px
        line-height: 160%
        letter-spacing: -0.01em
        color: #6F7889
        position: relative
        top: -8px
        margin-right: 12px
