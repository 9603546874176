.member-testimonial
  font-family: 'Poppins'

  &__container
    padding: 80px 80px 0px 80px
    background-color: #FFFFFF

    +breakpoint(small only)
      padding: 20px 20px 0px 20px

  &__heading
    font-family: $font-stack-poppins
    font-weight: 600
    font-size: 46px
    text-align: center
    padding-bottom: 40px
    color: #06243C

    +breakpoint(small only)
      font-size: 22px

  &__image
    height: 300px
    width: 300px
    border-radius: 14px
    background-size: cover !important

  &__flex-container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 15px

  &__width-constrainer
    width: 90% !important

  &__left-arrow-orange
    min-width: 35px
    height: 57px
    background-image: url('../../images/icons/left-scroller-arrow-orange-smaller.svg')
    cursor: pointer
    background-repeat: no-repeat

    +breakpoint(small only)
      display: none

    +breakpoint(medium only)
      margin-right: 10px

    &:focus, &:hover
      filter: contrast(0.8)

  &__right-arrow-orange
    min-width: 35px
    height: 57px
    background-image: url('../../images/icons/right-scroller-arrow-orange-smaller.svg')
    cursor: pointer
    background-repeat: no-repeat

    +breakpoint(small only)
      display: none

    +breakpoint(medium only)
      margin-left: 10px

    &:focus, &:hover
      filter: contrast(0.8)

  &__heading
    font-family: $font-stack-poppins
    font-weight: 600
    font-size: 24px
    line-height: 28.8px
    letter-spacing: -0.03%
    color: #06243C
    text-align: left
    padding-bottom: 25px

    +breakpoint(small only)
      font-size: 14px

  &__top-text
    font-family: 'Merriweather'
    font-size: 28px
    font-style: italic
    font-weight: 300
    line-height: 39.2px
    text-align: left
    padding-bottom: 25px

  &__name-text
    font-family: $font-stack-poppins
    font-weight: 500
    font-size: 16px
    line-height: 19.2px
    letter-spacing: -0.03em
    text-align: left

    +breakpoint(small only)
      font-size: 12px

    &:hover
      background-image: url('../../images/icons/left-carousel-arrow-orange.svg')

    &:hover
      background-image: url('../../images/icons/right-carousel-arrow-orange.svg')

  &__center-align
    align-items: center

    +breakpoint(small only)
      padding-bottom: 20px
      flex-direction: column !important

    #player
      max-width: unset
      height: 100%
      width: fit-content

  &__swiper-wrapper-container
    width: 80% !important

  &__swiper-wrapper
    +breakpoint(small only)
      padding-bottom: 0px !important

  &__mobile-container-secondary
    +breakpoint(small only)
      padding-bottom: 20px !important

  &__vimeo-container
    +breakpoint(small only)
      position: relative
      height: 100%
      text-align: center
      width: 100%

  &__embed-container
    +breakpoint(small only)
      text-align: center

  &__tablet-container
    +breakpoint(medium only)
      flex-direction: column !important

  &__tablet-content
    +breakpoint(medium only)
      padding-left: 0px !important
      padding-right: 0px !important
      width: 100% !important
      justify-content: center !important

  &__tablet-details
    +breakpoint(medium only)
      top: 20px
      position: relative

  &__mobile-details
    +breakpoint(small only)
      top: 20px
      position: relative


  &__video-sizing
    +breakpoint(medium only)
      height: 331px !important

    +breakpoint(small only)
      height: 179px !important

  &__mobile-slide-details
    +breakpoint(small only)
      top: 15px
      position: relative
