#eventsOffCanvas
  background-color: $gray
  z-index: 301

  &.position-right
    transform: translateX(100vw)
    width: 100vw

    &.is-open
      transform: translate(0, 0)

    &.is-closed
      width: 0

    +breakpoint(medium)
      transform: translateX(50vw)
      width: 50vw

  .top-bar
    box-shadow: 0 rem-calc(3) rem-calc(10) rgba(14, 24, 40, 0.1)
    padding: $g8-3x $g8-4x

  .top-bar,.menu
    background-color: $gray

  .top-bar-left, .top-bar-right
    flex: 0 0 auto

  .menu-text
    font-weight: 600
    padding: 0

  .event-content
    padding: $g8-5x $g8-4x

    hr
      margin: $g8-4x auto

    h3
      line-height: 120%

    h6
      font-size: $small-font-size
      font-weight: 300

    p
      margin-bottom: 0

    .details-container
      +breakpoint(medium down)
        margin-bottom: $g8-5x

.events-show
  &__off-canvas
    margin-bottom: $g8-6x

    .title-bar
      background-color: $gray
      padding: $g8*2.25 $g8-3x
