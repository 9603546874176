.strategy-ceo
  display: flex
  flex-direction: column
  gap: 40px
  align-items: center
  padding: 80px
  background-color: rgba(245, 245, 245, 1)
  +breakpoint(small only)
    padding: 20px
    gap: 20px

  &__header
    font-weight: 600
    font-size: 46px
    color: rgba(6, 36, 60, 1)
    text-align: center
    +breakpoint(small only)
      font-size: 26px

  &__content
    display: flex
    flex-direction: row
    gap: 60px
    +breakpoint(small only)
      flex-direction: column
      gap: 25px
    +breakpoint(medium only)
      gap: 5px

    &-container
      display: flex
      flex-direction: row
      gap: 40px
      align-items: center
      +breakpoint(small only)
        gap: 15px
        flex-direction: column
      +breakpoint(medium only)
        gap: 20px

    &-name
      font-size: 23px
      font-weight: 600
      color: rgba(6, 36, 60, 1)

    &-image
      width: 147px
      height: 155px
      border-radius: 16px
      object-fit: cover
      +breakpoint(small only)
        min-width: unset

    &-title
      font-weight: 300
      font-size: 18px
      color: rgba(6, 36, 60, 1)

    &-text
      display: flex
      flex-direction: column
      justify-content: space-evenly
      +breakpoint(small only)
        text-align: center

    &-link
      color: rgba(6, 36, 60, 1)
      font-weight: 500
      font-size: 16px
      font-style: italic
      &:hover
        color: #ED6E56
        cursor: pointer
