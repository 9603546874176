.p-events__index
  .page-wrapper
    top: 0

  section
    padding-bottom: $g8-5x
    padding-top: $g8-5x

  .hero-carousel
    padding-bottom: $g8-5x

.p-events
  background-color: $black-3

  .markdown-table
    table
      color: $white

    tbody tr:nth-child(even)
      background-color: $gray

  .scroller
    &__tall-card
      .swiper-slide, .tall-card
        height: $g8*63
        width: $g8*39 !important


    &__wrapper
      position: relative
      z-index: 1

    &__wrapper--top
      margin-bottom: $g8

    &__details
      color: $gray
      margin-bottom: $g8-3x

    &__card-list
      .filter
        span
          color: $white
          font-size: $small-font-size

        svg path
          fill: $white !important

  .swiper-container
    background-color: transparent
    height: 100%
    z-index: 2

  .swiper-slide
    &:before
      background-image: none

    .standard-card
      &__title, &__date
        color: $white !important

  .filters
    +breakpoint(small only)
      padding: 0 $g8-2x

    .cell
      padding: 0 $g8-half
