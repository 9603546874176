@use 'sass:math';

.ph-col-1 { flex: 0 0 percentage(math.div(1, 12)); }
.ph-col-3 { flex: 0 0 percentage(math.div(3, 12)); }
.ph-col-5 { flex: 0 0 percentage(math.div(5, 12)); }
.ph-col-7 { flex: 0 0 percentage(math.div(7, 12)); }
.ph-col-9 { flex: 0 0 percentage(math.div(9, 12)); }
.ph-col-11 { flex: 0 0 percentage(math.div(11, 12)); }

.ph-row {
  .vbig { height: rem-calc(60); }
  .vvbig { height: rem-calc(120); }
}
