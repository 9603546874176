.category-header
  margin-top: 5rem
  height: 288px
  padding: 40px 75px
  border-radius: 0px 0px 100px 0px
  background-position: left 0% bottom 50%
  background-size: cover
  +breakpoint(small only)
    padding: 30px 30px
  & h4
    text-shadow: 0px 1px 5px rgba(6, 36, 60, 0.3), 0px 10px 10px rgba(6, 36, 60, 0.07)
    padding-top: 60px
    font-weight: 600
    color: white
    font-size: 58px
    +breakpoint(medium only)
      font-size: 40px
    +breakpoint(small only)
      font-size: 33px
      //padding-top: 0

  .header-tags
    display: flex
    flex-direction: row
    & div
      background: #ED6E56
      color: white
      padding: 10px 20px
      border-radius: 100px
      margin-right: 10px
