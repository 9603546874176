.scroller
  &__wrapper
    &--top
      overflow: hidden
      margin-bottom: $g8

      +breakpoint(medium)
        margin-bottom: $g8-3x

  &__title
    font-family: $font-stack-poppins !important
    font-weight: 600 !important
    hyphens: auto
    line-height: 120%
    position: relative
    color: #06243C

    +breakpoint(medium)
      &::after
        //border-top: 1px solid $dark-gray
        content: ''
        margin-left: $g8-4x
        position: absolute
        top: $g8-2x
        width: 100%
        z-index: 1

  &__details
    margin-bottom: $g8-5x

  &__arrows
    //background-color: $white
    z-index: 2

    +breakpoint(medium)
      padding-left: $g8-4x

    &--left,
    &--right
      background-repeat: no-repeat
      background-size: auto
      cursor: pointer
      display: inline-block
      height: 3.5rem
      width: 3.5rem
      transition: background-image .2s ease-in-out

      &:hover
        transition: background-image .2s ease-in-out

    &--left
      background-image: url('./../../images/icons/left-arrow.png')

      +breakpoint(xlarge)
        &:hover:not(.disabled)
          background-image: url('./../../images/icons/navigation-hover-left.png')

      &.disabled
        background-image: url('./../../images/icons/left-arrow-disabled.png')

    &--right
      background-image: url('./../../images/icons/right-arrow.png')
      margin-left: $g8-half

      +breakpoint(xlarge)
        &:hover
          background-image: url('./../../images/icons/navigation-hover-right.png')

      &.disabled
        background-image: url('./../../images/icons/right-arrow-disabled.png')

  .swiper-container
    margin-top: $g8
    overflow: visible

  .swiper-slide
    box-sizing: border-box
