.popular-categories
  .categories-section

    .category
      width: 300px
      height: 280px
      margin: 10px
      border-radius: 14px
      background-color: #fff
      box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)

      .category-inside
        display: flex
        justify-content: flex-start
        flex-direction: column
        height: 100%
        padding-bottom: 30px
        padding-left: 20px
        padding-right: 20px
        padding-top: 20px

      .category-color
        height: 14px
        border-radius: 14px 14px 0px 0px

      .categories-svg
        +background-image(no-repeat, contain, center, center)
        height: 10px
        content: ''

      .financial-curve
        background-image: url('./../../images/programme_categories/financial_curve.svg')

      .retail-curve
        background-image: url('./../../images/programme_categories/retail_curve.svg')

      .executive-curve
        background-image: url('./../../images/programme_categories/executive_curve.svg')

      .funds-curve
        background-image: url('./../../images/programme_categories/funds_curve.svg')

      .digital-curve
        background-image: url('./../../images/programme_categories/digital_curve.svg')

      .governance-curve
        background-image: url('./../../images/programme_categories/governance_curve.svg')

      .credit-curve
        background-image: url('./../../images/programme_categories/credit_curve.svg')

      .culture-curve
        background-image: url('./../../images/programme_categories/culture_curve.svg')

      .shield-border
        width: 60px
        height: 60px
        border-radius: 50%

      .financial-border, .retail-border
        background: rgba(237, 110, 86, .25)

      .executive-border
        background: rgba(111, 120, 137, .25)

      .funds-border
        background: rgba(7, 151, 117, .25)

      .digital-border
        background: rgba(62, 101, 186, .25)

      .governance-border
        background: rgba(198, 196, 106, .25)

      .credit-border
        background: rgba(6, 36, 60, .25)

      .culture-border
        background: rgba(242, 112, 121, .25)

      .category-shield
        margin: 7px auto
        height: 46px
        width: 46px
        background: url('./../../images/programme_categories/category_shield.svg') no-repeat center center
        border-radius: 50%

      .financial-shield, .retail-shield
        background-color: #ED6E56

      .executive-shield
        background-color: #6F7889

      .funds-shield
        background-color: #079775

      .digital-shield
        background-color: #3E65BA

      .governance-shield
        background-color: #C6A96A

      .credit-shield
        background-color: #06243C

      .culture-shield
        background-color: #F27079

      .category-link
        height: 35px
        padding-top: 9px
        padding-left: 20px
        padding-right: 20px
        border-radius: 20px
        font-style: normal
        font-weight: 500
        font-size: 12px

      .financial-link, .retail-link
        background: rgba(237, 110, 86, 0.15)
        color: #ED6E56

      .executive-link
        background: rgba(111, 120, 137, .15)
        color: #6F7889

      .funds-link
        background: rgba(7, 151, 117, .15)
        color: #079775

      .digital-link
        background: rgba(62, 101, 186, .15)
        color: #3E65BA

      .governance-link
        background: rgba(198, 196, 106, .15)
        color: #C6A96A

      .credit-link
        background: rgba(6, 36, 60, .15)
        color: #06243C

      .culture-link
        background: rgba(242, 112, 121, .15)
        color: #F27079

      .flex-row
        display: flex
        flex-direction: row

      .category-content-top
        justify-content: space-between
        margin-bottom: 20px

      .category-content-bottom
        max-width: fit-content
        align-items: center
        padding-bottom: 20px

      .discover
        font-weight: 500
        font-size: 16px
        color: #ED6E56
        padding-right: 12px

      .discover-arrow
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/programme_categories/discover_arrow.svg')
        height: 10px
        width: 10px
        content: ''

    .financial
      border: 1px solid #ED6E56
      background-color: #ED6E56

    .retail
      border: 1px solid #FFD9C0
      background-color: #FFD9C0

    .executive
      border: 1px solid #6F7889
      background-color: #6F7889

    .funds
      border: 1px solid #079775
      background-color: #079775

    .digital
      border: 1px solid #3E65BA
      background-color: #3E65BA

    .governance
      border: 1px solid #C6A96A
      background-color: #C6A96A

    .credit
      border: 1px solid #06243C
      background-color: #06243C

    .culture
      border: 1px solid #F27079
      background-color: #F27079