.cpd-show-page
  position: relative
  font-family: $font-stack-poppins

  &__wrapper
    padding-top: 80px

  &__header-banner
    height: 15.625rem
    background-image: url('//images.ctfassets.net/19hllh2ssa3x/6IFLeTe85oDKQKfc6CbtnP/77e5c96e62f87c76b6fcadd9a1414339/pattern_orange.svg')
    border-radius: 0 0 100px 0px
    background-position: left 0% bottom 45%
    background-size: cover

    +breakpoint(small only)
      height: 12.5rem
      background-size: auto
      background-position: left 47.5% bottom 65%

  &__header-text
    padding-top: 5rem
    font-size: 58px
    padding-left: 5rem
    vertical-align: middle
    color: white
    font-weight: 600
    white-space: nowrap

    +breakpoint(small only)
      padding-left: 1.25rem
      font-size: 48px

  &__header-bottom-bar-wrapper
    position: relative
    margin-right: 5rem
    margin-left: 5rem
    top: 63px
    min-width: 690px

    +breakpoint(small only)
      display: none

  &__header-bottom-bar
    width: 100%
    height: 20px
    background-color: white
    border-radius: 14px 14px 0px 0px

  &__header-nav-wrapper
    font-weight: 500px
    margin-left: 5rem
    margin-right: 5rem
    text-align: center
    box-shadow: 0px 3px 4px rgba(6, 36, 60, 0.07), 0px 5px 20px rgba(6, 36, 60, 0.03), 0px 10px 50px rgba(6, 36, 60, 0.03)
    border-radius: 0px 0px 20px 20px
    min-width: 690px

    +breakpoint(small only)
      display: none

  &__header-nav
    gap: 50px
    justify-content: center
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    font-size: 16px
    font-weight: 500px

  &__nav-links-button
    font-weight: 500
    height: 1rem
    pointer-events: all
    height: 49px
    border-radius: 0px 0px 0px 4px
    gap: 30px
    display: flex
    flex-direction: column
    justify-content: flex-start

    &--active
      border-bottom: #ED6E56 solid 4px
      color: #06243C

    &:hover
      color: #06243C !important
      border-bottom: #ED6E56 solid 4px !important
      cursor: pointer

  &__nav-links-text
    font-size: 16px

  &__content
    row-gap: 100px
    display: flex
    flex-direction: column
    justify-content: space-between
    padding-top: 50px
    padding-bottom: 50px

    +breakpoint(small only)
      row-gap: 40px
      padding-top: 40px
      padding-bottom: 40px

  &__content-grey
    row-gap: 100px
    display: flex
    flex-direction: column
    justify-content: space-between
    background-color: rgba(212, 215, 221, 0.3)
    padding-bottom: 50px

    +breakpoint(small only)
      row-gap: 40px
      padding-bottom: 20px
      background-color: #ffffff

  &__back
    display: flex
    column-gap: 30px
    flex-direction: row
    align-items: center
    font-size: 24px
    padding-left: 170px
    pointer-events: all
    font-weight: 500
    color: #ED6E56
    padding-top: 30px
    cursor: pointer

    &:hover
      color: #003166

    +breakpoint(small only)
      display: none

  &__back-arrow
    font-size: 50px
    line-height: 20px

  &__my-hours
    position: relative

    +breakpoint(small only)
      padding: 0px 15px

  &__annual-returns

    +breakpoint(small only)
      padding: 0px 15px

  &__my-ecpd

    +breakpoint(small only)
      padding: 0px 15px

  &__cpd-events

    +breakpoint(small only)
      padding: 0px 15px

  &__cpd-downloads
    position: relative
    top: -50px

    +breakpoint(small only)
      top: -20px
      padding: 0px 15px

  &__my-certificates
    position: relative
    top: -50px

    +breakpoint(small only)
      top: -20px
      padding: 0px 15px

  &__nav-links-button-annual-return
    display: none

  &__fake-bottom
    position: absolute
    bottom: -100px
    width: 100%
    height: 340px
    background-color: rgba(212, 215, 221, 0.3)
    z-index: -1

    +breakpoint(small only)
      background-color: #ffffff
