.swal-overlay
  .swal-modal
    border-radius: 0
    padding: $g8-5x $g8-2x $g8-7x $g8-2x
    position: relative
    width: 90%

    +breakpoint(large)
      width: $g8*100

    &:before
      +background-image(no-repeat, cover, center, center)
      background-image: url('./../../images/backgrounds/accreditation-decoration-mobile.svg')
      content: ''
      height: $g8-5x
      left: 0
      position: absolute
      right: 0
      top: 0
      width: 100%

      +breakpoint(medium)
        background-image: url('./../../images/backgrounds/accreditation-decoration.svg')
        height: 100%
        width: $g8-5x

  .swal-title
    color: $black-3
    font-family: $font-stack-poppins
    padding: 0  0 $g8-2x 0

  .swal-footer
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0

    +breakpoint(medium)
      flex-direction: row

  .swal-button-container
    width: 100%

    +breakpoint(medium)
      width: auto

  button.swal-button.swal-button
    border-bottom-right-radius: $g8-3x !important
    border-radius: 0
    cursor: pointer
    display: inline-block
    font-size: $medium-font-size
    padding: $g8-2x $g8-4x
    text-align: center
    width: 100%

    &--cancel
      background-color: $light-gray
      color: $primary-color
      transition: .3s

      &:hover,
      &:focus
        background-color: $light-gray
        border-bottom-right-radius: 0 !important
        color: $primary-color
        transition: .3s

    &--confirm
      background-color: $secondary-color
      color: $primary-color
      color: $white
      mask: url('../../images/masks/button.svg') no-repeat top right
      transition: .3s

      &:hover
        background-color: $secondary-color
        border-bottom-right-radius: 0 !important
        transition: .3s

  .swal-icon
    border: rem-calc(3) solid $secondary-color
    height: $g8-5x
    width: $g8-5x

    &--warning__body
     background-color: $secondary-color
     border-radius: rem-calc(2)
     height: rem-calc(19)
     top: rem-calc(5)
     width: rem-calc(3)

    &--warning__dot
      background-color: $secondary-color
      border-radius: 50%
      bottom: -rem-calc(9)
      height: rem-calc(5)
      left: rem-calc(3)
      width: rem-calc(5)
