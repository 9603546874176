.my-ecpd

  &__flex-container
    display: flex
    flex-direction: column

    +breakpoint(small only)
      padding: 0px 10px

  &__header
    display: flex
    flex-direction: column
    padding: 0px 180px

    +breakpoint(small only)
      padding: 0px

  &__header-top
    font-size: 58px
    letter-spacing: -0.03em
    color: #06243C
    font-weight: 600

    +breakpoint(small only)
      font-size: 36px

  &__header-bottom
    color: #6F7889
    letter-spacing: -0.01em
    font-size: 16px
    font-weight: 300

    +breakpoint(small only)
      font-size: 10px

  &__content
    display: flex
    flex-direction: row
    gap: 40px
    padding: 40px 60px
    width: 100%
    overflow-y: hidden
    scroll-behavior: smooth
    overflow-y: scroll
    scrollbar-width: none
    -ms-overflow-style: none
    z-index: 1

    +breakpoint(small only)
      padding: 10px 0px !important
      gap: 20px
      left: 0px !important

  &__content::-webkit-scrollbar
    display: none

  &__content-carousel-tile
    display: flex
    flex-direction: column
    background-color: #ffffff
    text-decoration: none
    color: inherit
    width: 300px
    border-radius: 14px
    box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)

    +breakpoint(small only)
      width: 245px

  &__content-carousel-tile-completed
    opacity: 0.5

  &__content-carousel-image
    width: 300px
    height: 190px
    background-size: cover
    background-position: top left
    background-repeat: no-repeat
    border-top-left-radius: 14px
    border-top-right-radius: 14px
    position: relative

    +breakpoint(small only)
      width: 245px

    .my-ecpd-hours
      color: #ffffff
      font-weight: 600
      font-size: 14px
      position: relative
      top: 50px
      left: 30px
      letter-spacing: 0.2em

  &__content-carousel-text-wrapper
    height: 60px
    display: flex
    flex-direction: row
    padding: 20px 0px 0px 30px
    align-items: center
    font-weight: 600
    font-size: 14px
    color: #FFFFFF

    +breakpoint(small only)
      font-size: 12px

  &__content-carousel-bottom
    padding: 30px 40px 40px 40px

    +breakpoint(small only)
      padding: 8px 8px 27px 8px

  &__content-carousel-text-box
    display: flex
    flex-direction: column
    gap: 10px

  &__content-carousel-text-box-name
    font-size: 16px
    font-weight: 600
    letter-spacing: -0.03em
    color: map-get($iob-color-palette, navy)

    +breakpoint(small only)
      font-size: 14px

  &__content-carousel-text-box-description
    font-size: 12px
    font-weight: 400
    letter-spacing: -0.03em
    color: #6F7889

    +breakpoint(small only)
      font-size: 12px

  &__scroller
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 15px
    padding: 10px

  &__scroller-left
    width: 35px
    height: 36px
    background-image: url("./../../../javascript/images/icons/left-scroller.svg")

  &__scroller-right
    width: 35px
    height: 36px
    background-image: url("./../../../javascript/images/icons/right-scroller.svg")

  &__scroller-left:hover
    cursor: pointer
    color: #003166

  &__scroller-right:hover
    cursor: pointer
    color: #003166

  &__scroller-dot
    width: 8px
    height: 8px
    border-radius: 100px
    background-color: #06243C
    opacity: 0.25

    &--active
      opacity: 1
