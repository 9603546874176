.p-info__index
  .info
    margin-bottom: $g8-5x
    margin-top: $g8-5x

    +breakpoint(large)
      margin-bottom: $g8*15
      margin-top: $g8-10x

    &__card
      margin-bottom: $g8-5x
      padding: 0 0 $g8-4x 0

      +breakpoint(large)
        padding: 0 0 $g8-4x $g8-6x

      &:not(:last-child)
        border-bottom: solid $lightest-gray 1px

      p
        margin-bottom: $g8-4x

      &--dark-pink
        +pseudo-heading-bar(map-get($accent-colors, dark-pink))

      &--pink
        +pseudo-heading-bar(map-get($accent-colors, pink))

      &--orange
        +pseudo-heading-bar(map-get($accent-colors, orange))

      &--yellow
        +pseudo-heading-bar(map-get($accent-colors, yellow))

      &--green
        +pseudo-heading-bar(map-get($accent-colors, green))

      &--light-blue
        +pseudo-heading-bar(map-get($accent-colors, light-blue))

      &--blue
        +pseudo-heading-bar(map-get($accent-colors, blue))

      &--purple
        +pseudo-heading-bar(map-get($accent-colors, purple))

      &--dark-pink, &--pink,
      &--orange, &--yellow,
      &--green, &--light-blue,
      &--blue, &--purple
        &:before
          +breakpoint(large)
            height: 82%
