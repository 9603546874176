.strategy-banner
  height: 250px
  display: flex
  flex-direction: row
  justify-content: space-between
  border-radius: 0px 0px 100px 0px
  background-position: left 0% bottom 50% !important
  background-size: cover !important
  +breakpoint(small only)
    align-items: center
    flex-direction: column
    justify-content: space-evenly

  &__left
    width: 50%
    display: flex
    margin: 30px
    flex-direction: column
    justify-content: space-evenly
    padding-left: 5%
    min-width: 250px
    +breakpoint(small only)
      margin: 0px
      min-width: unset
      width: unset
      align-items: center
      padding: 0

    &-top
      color: rgba(255, 255, 255, 1)
      font-size: 40px
      font-weight: 600
      +breakpoint(small only)
        font-size: 33px
        font-weight: 600

    &-bottom
      color: rgba(255, 255, 255, 1)
      font-size: 20px
      font-weight: 600px
      +breakpoint(small only)
        font-size: 15px

  &__right
    width: 30%
    margin: 30px
    background-color: rgba(255, 255, 255, 0.85)
    border-radius: 14px 14px 50px 14px
    display: flex
    flex-direction: column
    justify-content: space-evenly
    padding-left: 25px
    margin-right: 5%
    +breakpoint(small only)
      width: 80%
      flex-direction: column
      padding: 15px
      margin: 0px
      display: flex
      gap: 5px
      align-items: center

    &-top
      font-weight: 600
      font-size: 28px
      color: rgba(6, 36, 60, 1)
      font-weight: 600
      +breakpoint(small only)
        font-size: 16px

    &-bottom
      font-weight: 500
      display: flex
      flex-direction: row
      background-color: #ED6E57 !important
      border-radius: 8px
      align-items: center
      justify-content: center
      background-position: 21% center
      height: 50px
      width: 90%
      color: white !important
      background-size: auto 16px
      gap: 7px
      +breakpoint(small only)
        min-width: 105px
        font-size: 16px
        padding: 0px 10px
      &:hover
        color: rgba(6, 36, 60, 1) !important

        & .strategy__hovered-button
          display: block

        & .strategy__unhovered-button
          display: none
