.p-programmes__show
  .programme_details_tab
    .information-cards
      .card
        border: none
        background-color: $lightest-gray
        padding: rem-calc(30)

        .card-title
          font-family: $font-stack-poppins
          font-weight: 300
          font-size: $small-font-size
          letter-spacing: $letter-spacing
          text-transform: uppercase

        p
          font-family: $font-stack-poppins
          font-size: $medium-font-size
          line-height: rem-calc(26)

    hr
      width: 100%
      border: 1px solid $dark-gray
