.my-details
  &__page
    .section-title
      font-size: $small-font-size

    &--address,
    &--direct-debit
      .toggle-message
        display: none !important

    &--direct-debit
      .disabled
        color: rgba($darkest-gray, 0.7) !important

    &--main
      .searchable-items__item
        font-size: $medium-font-size

    &--marketing
      margin-top: $g8-3x

      .input-container.checkbox
        +triangle-checkbox
        margin-bottom: $g8-5x
        left: -$g8-3x

        label
          line-height: $paragraph-lineheight
          padding-top: rem-calc(5)

        .description
          font-size: $tiny-font-size
          left: $g8-9x
          position: relative
          top: $g8-2x
          width: calc(100% - #{$g8-9x})

          +breakpoint(large)
            width: 100%

    input
      color: $black

    .button--orange
      color: $white

    .button--orange.disabled
      color: $gray

    &-main
      +breakpoint(large)
        &-left_col
          padding-right: $g8-2x
        &-right_col
          padding-left: $g8-2x

    &-main
      +breakpoint(xlarge)
        &-left_col
          padding-right: $g8-6x
        &-right_col
          padding-left: $g8-6x

      &-right_col
        +breakpoint(medium down)
          margin-top: $g8-2x

      .email-input-wrapper
        border-bottom: 1px solid $light-gray
        padding-bottom: $g8-3x

        .input__clear
          display: none

      .forgot-password-wrapper
        align-items: center
        display: flex
        margin: $g8-3x 0

        p
          text-align: right

          +breakpoint(medium)
            text-align: left

        a
          margin: $g8-2x 0 0 0

          +breakpoint(medium)
            margin: 0

        div:first-child
          margin-bottom: $g8-2x

          +breakpoint(medium)
            margin-bottom: 0

    .toggle
      .input-container
        label
          font-size: $small-font-size

        &.focused
          label
            font-size: 0.9em

  &__toggle-info
    &[data-toggle-active="false"]
      display: none

    &[data-toggle-active="true"]
      display: block
