@use "sass:math";

$global-font-size:  100% !default;
$global-baseline:   1.5 !default;
$debug-line-height: false !default;

/// Helper function that strips the unit from a number.
///
/// @access private
/// @group helpers

@function -strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

/// Helper function that converts a `px`, `b` or `bu` to rem.
///
/// @access private
/// @group helpers

@function -length-to-rem($length) {
  @if unit($length) == 'px' or unitless($length) {
    $px-base: -strip-unit($global-font-size) * 0.01 * 16;
    @return math.div(-strip-unit($length), $px-base) * 1rem;
  } @else if unit($length) == 'b' {
    @return $global-baseline * -strip-unit($length) * 1rem;
  } @else if unit($length) == 'bu' {
    @return math.div($global-baseline * -strip-unit($length), 3) * 1rem;
  } @else if unit($length) == 'rem' {
    @return $length;
  }
}

/// A function that returns a rem length for a `b` (baseline) or
/// `bu` (baseline unit) unit type.
///
/// @access public

@function baseline-length(
  $length: 1b,
  $adjust: 0
) {
  @if unit($length) == 'b' or unit($length) == 'bu' or unit($length) == 'rem' {
    @return -length-to-rem($length) + -length-to-rem($adjust);
  } @else {
    @error 'Invalid unit: `$value` is not a valid `b`, `bu` or `rem` value.';
  }
}

/// @alias baseline-length

@function bl(
  $length: 1b,
  $adjust: 0
) {
  @return baseline-length($length, $adjust);
}


@function baseline-map(
  $font-size: 1rem,
  $adjust:    0b,
  $margin:    1b
) {
  @if $adjust != 0 and (unit($adjust) != 'b' and unit($adjust) != 'bu' and unit($adjust) != 'rem') {
    @error 'Invalid unit: `$adjust` is not a valid `b`, `bu` or `rem` value.';
  }

  @if unit($font-size) == ''    or
      unit($font-size) == 'px'  or
      unit($font-size) == 'rem'
  {
    // Convert font-size to rem if in px
    $font-size: if(unit($font-size) == 'rem', $font-size, -length-to-rem($font-size));

    // The number of gridlines required for `$font-size`
    $baseline-count: -strip-unit(ceil(math.div($font-size, $global-baseline)));
    $baseline-units: ($baseline-count * 3);

    // Calculate the rem line-height of `$font-size` in rem
    $-line-height-rem: ($baseline-units * math.div($global-baseline, 3) * 1rem);
    $-adjust-rem:      -length-to-rem($adjust);
    $-line-height:     $-line-height-rem + $-adjust-rem;

    // Convert to unitless line-height
    $line-height: math.div(-strip-unit($-line-height), -strip-unit($font-size));

    // If `$adjust` is rem then use it as line-height
    @if unit($adjust) == 'rem' {
      $line-height: $-adjust-rem;
    }

    $map: (
      font-size:     $font-size,
      line-height:   $line-height,
      margin-bottom: -length-to-rem($margin),
      margin-top:    0,
    );

    @return $map;
  } @else {
    @error 'Invalid unit: `$font-size` is not a valid `px` or `rem` value.';
  }
}

@mixin baseline-font(
  $font-size:  1rem,
  $adjust:     0,
  $margin:     1b
) {
  $map: baseline-map($font-size, $adjust, $margin);

  font-size:     map-get($map, font-size);
  line-height:   map-get($map, line-height);
  margin-bottom: map-get($map, margin-bottom);
  margin-top:    map-get($map, margin-top);
}

/// @alias baseline-font
@mixin bf(
  $font-size:  1rem,
  $adjust:     0,
  $margin:     1b
) {
  @include baseline-font($font-size, $adjust, $margin)
}

@mixin baseline-debug() {
  $-baseline:         $global-baseline * 1rem;
  $-line-color-full:  red;
  $-line-color-split: rgba($-line-color-full, 0.4);
  $-baseline-inc:     math.div($-baseline, 3);

  &.gridvh,
  &.gridv {
    background-image:
      linear-gradient(
        $-line-color-full 0,
        transparent -length-to-rem(1)
      ),
      linear-gradient(
        transparent $-baseline-inc,
        $-line-color-split $-baseline-inc,
        transparent $-baseline-inc + -length-to-rem(1)
      ),
      linear-gradient(
        transparent $-baseline-inc * 2,
        $-line-color-split $-baseline-inc * 2,
        transparent $-baseline-inc * 2 + -length-to-rem(1)
      );
    background-size: 100% $-baseline;
    height:          100%;
    pointer-events:  none;
    position:        absolute;
    width:           100%;
    z-index:         90001;
  }

  &.gridvh,
  &.gridh {
    &:after {
      background-image:
        linear-gradient(
          90deg,
          rgba($-line-color-full, 0.4) 0,
          transparent -length-to-rem(1)
        );
      background-size: $-baseline-inc;
      content:         '';
      height:          100%;
      left:            0;
      position:        absolute;
      top:             0;
      width:           100%;
      z-index:         90002;
    }
  }
}

#cssdebug {
  @include baseline-debug
}
