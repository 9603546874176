a
  color: $secondary-color
  font-weight: 600

  &.black, &.secondary
    &:hover
      color: $black-3
      text-decoration: underline

  &.black
    color: $black-3

  &.secondary
    color: $primary-color
