input
  background: $input-background
  border: $input-border
  box-shadow: $input-shadow
  height: $input-height
  line-height: $g8-3x
  outline: none
  padding: $input-padding
  width: 100%

::-webkit-input-placeholder
  color: $black-2

::-moz-placeholder
  color: $black-2

select
  background-color: $light-gray
  background-image: url('./../../images/icons/dropdown-caret.svg')
  background-position: right (-$g8-2x) top $g8
  background-size: rem-calc(12)
  cursor: pointer
  height: rem-calc(59)
  padding: $g8-2x $g8-4x $g8-2x $g8-3x
  position: relative

  &:active
    background-color: $light-gray

textarea
  position: relative
  resize: vertical

.user_message,
.input-container.text-area
  position: relative

  &:after
    background-color: $light-gray
    background-image: url('./../../images/icons/resize-icon.svg')
    background-repeat: no-repeat
    bottom: 1px
    content: ''
    height: $g8-3x
    pointer-events: none
    position: absolute
    right: 1px
    width: $g8-3x

.input-container.text-area.has-message.error:after
  bottom: $g8*3.625

.input-container.focused textarea
  background: $light-gray

.input-container label
  z-index: 2

.adjust-cursor-start.focused
  textarea
    padding-top: $g8-5x

.input-container.focused label
  //display: none
