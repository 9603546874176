.learn-meganav-columns-popular
  font-size: 12px
  float: left
  width: 25%
  padding: 10px
  +breakpoint(medium down)
    display: none
  h4
    font-size: 26px
    font-weight: 600
    color: black
    //margin-bottom: 50px
    +breakpoint(medium down)
      cursor: pointer
      margin-bottom: $g8
      color: #6F7889
  h4.active
    color: #06243C
  li
    list-style: none
    //background-color: #FBE8E4
    // height: 38px
    border: none
    padding: 10px 15px
    border-radius: 25px
    margin: 5px 0px
    display: inline-block
    cursor: pointer
    text-decoration: none
    //color: #ED6E56
    float: left
    // text-align: center
    // width: 100%
    +breakpoint(medium down)
      width: 100%
    p
        font-weight: 500 !important
        // overflow: hidden
        // white-space: nowrap
        // text-overflow: ellipsis
        margin: 0 auto
  ul
    text-align: left
    display: flex
    flex-direction: column
    margin: 0px
    padding: 0px
    background-color: #FDFDFD
    width: 100%
    +breakpoint(medium down)
      visibility: hidden
      position: absolute
      width: 100%
      text-align: center
  li:after
    border-bottom: 1px solid #D4D7DD
    margin-top: 10px
    margin-bottom: 10px

.learn-meganav-columns
  font-size: 12px
  float: left
  width: 25%
  padding: 10px
  color: #6F7889
  +breakpoint(medium down)
    float: none
    width: 100%
    text-align: center
  h4
    font-size: 26px
    font-weight: 600
    color: black
    margin-bottom: 10px
    +breakpoint(medium down)
      cursor: pointer
      margin-bottom: $g8
      color: #6F7889
      font-style: normal
      font-weight: 600
      font-size: 24px
      line-height: 120%
  &.active h4
    color: #06243C
  li
    list-style: none
    padding-top: 6px
    p
      font-weight: 500 !important
      font-size: 16px !important
      margin-bottom: 0.4rem !important
  ul
    text-align: left
    height: 38px
    width: 100%
    border: none
    padding: 10px 15px
    border-radius: 25px
    margin: 10px 0px
    display: inline-block
    cursor: pointer
    text-decoration: none
    margin: 0px
    padding: 0px
    background-color: #FDFDFD
  .meganav-columns__content
    +breakpoint(medium down)
      width: 100%
      ul
        text-align: center
  &:not(.active) .meganav-columns__content
    +breakpoint(medium down)
      display: none
  li:after
    content: ""
    display: block
    height: 100%
    border-bottom: 1px solid #D4D7DD
    //margin-top: 10px
    //margin-bottom: 10px
  li:last-of-type::after
    display: none
  a
    color: #6F7889
[data-mobile-menu-active='true']
  background: $white
  padding: 15px
  color: black

.tab-title-container
  display: none
  +breakpoint(medium down)
    display: flex
    align-content: center
    width: 100%
    flex-wrap: wrap
    flex-direction: column
    a
        width: 10px
        height: 10px
        +background-image(no-repeat, contain, center, center)
        background-image: url('./../../images/icons/back.svg')
        left: 10px
        position: absolute
        color: #06243C


.nav_colour_credit_and_lending
  color: #06243C !important
  background-color: rgba(6, 36, 60, 0.15)  !important
  padding: 10px 15px
  border-radius: 25px
  margin: 10px 0px
  display: inline-block
  cursor: pointer
  text-decoration: none
  float: left
  +breakpoint(medium down)
    width: 100%
  p

    margin: 0 auto

.nav_colour_culture
  background-color: rgba(242, 112, 121, 0.15) !important
  a
    color: #F27079 !important
  p
    margin: 0 auto

.nav_colour_digital_and_innovation
  background-color: rgba(62, 101, 186, 0.15) !important
  a
    color: #3E65BA !important
  p
    margin: 0 auto

.nav_colour_executive_education
  background-color: rgba(111, 120, 137, 0.15) !important
  a
    color: #6F7889 !important
  p
    margin: 0 auto

.nav_colour_financial_planning
  background-color: rgba(237, 110, 86, 0.15) !important
  a
    color: rgba(237, 110, 86, 1 ) !important
  p
    margin: 0 auto

.nav_colour_funds
  background-color: rgba(7, 151, 117, 0.15) !important
  a
    color: rgba(7, 151, 117, 1) !important
  p
    margin: 0 auto

.nav_colour_governance_and_compliance
  background-color: rgba(198, 169, 106, 0.15) !important
  a
    color: rgba(198, 169, 106, 1) !important
  p
    margin: 0 auto

.nav_colour_retail_banking
  background-color: rgba(255, 217, 192, 0.25) !important
  a
    color: rgba(255, 217, 192, 1 ) !important
  p
    margin: 0 auto

.nav_colour_risk_management
  background-color: rgba(237, 110, 86, 0.15) !important
  a
    color: rgba(237, 110, 86, 1) !important
  p
    margin: 0 auto

.tab-title
  font-size: 16px




