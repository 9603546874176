.generic-page
  font-style: normal
  font-weight: normal
  margin-top: $g8-5x

  +breakpoint(large)
    margin-top: $g8*24

  &__heading-2
    letter-spacing: $letter-spacing
    margin-bottom: $g8-4x
    text-transform: uppercase

  &__heading-3
    font-weight: 600
    margin-bottom: $g8

  ul
    +unordered-list-orange

  hr
    margin-bottom: $g8-4x

  h1, h2, h3, h4, h5
    font-family: $font-stack-poppins
    font-weight: 400
    padding-bottom: $g8-4x

  h6
    font-family: $font-stack-poppins
    font-weight: 300
    letter-spacing: $letter-spacing
    text-transform: uppercase
    padding-bottom: $g8-2x

  .markdown-table
    td
      vertical-align: top
