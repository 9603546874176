.toggle
  margin: $g8-2x 0

  &-component
    position: relative

  input
    display: none

  input + label
    background-color: $medium-gray
    border-radius: rem-calc(20)
    height: rem-calc(15)
    position: relative
    width: $g8-5x

  label:before
    background: $light-gray
    border-radius: 100%
    box-shadow: 0px 2px rem-calc(5) rgba(14, 24, 40, 0.05)
    content: ''
    height: rem-calc(32)
    left: rem-calc(-11)
    position: absolute
    top: rem-calc(-9)
    transition: .3s
    width: rem-calc(32)

  input:checked + label:before
    background-color: $secondary-color
    left: rem-calc(20)

  &-message
    padding: rem-calc(19) 0
    font-size: $tiny-font-size
    line-height: rem-calc(20)
    width: 100%

    input
      display: block

    label:before
      display: none

  &--disabled
    color: $medium-gray
    cursor: not-allowed
    pointer-events: none

    input + label
      background-color: $light-gray !important

    label:before
      background: $lightest-gray !important

.ui-toggle

  blockquote
    margin-bottom: 0 !important
    padding-bottom: $g8 !important
    padding-top: $g8 !important

    p
      font-size: $medium-font-size !important
      font-weight: normal !important

      &:last-child
        margin-bottom: 0!important
