.total-payment-card
  box-shadow: 0px 3px rem-calc(10) rgba(14, 24, 40, 0.1)
  margin-bottom: rem-calc(20)

  h4, p
    margin: 0

  &__user,
  &__employer
    align-items: center
    display: flex
    justify-content: space-between
    padding: rem-calc(20)

  &__user
     background-color: $info-color
     min-height: rem-calc(90)

  &__employer
    background-color: $pale-blue
    font-size: $tiny-font-size

.general-payment-card
  background-color: $lightest-gray
  margin-bottom: rem-calc(20)
  padding: rem-calc(20)

  .membership-card-date:empty,
  .exam-date:empty
    display: none

  .membership-card-date,
  .exam-date
    margin-bottom: 0

  p
    font-size: $tiny-font-size

  &__info
    display: flex
    justify-content: space-between

  &__module-title
    font-size: $medium-font-size
    font-weight: 600
    margin: rem-calc(15) 0

  &__module-details
    font-size: $tiny-font-size

  .toggle-message
    padding-bottom: 0

    .input-container
      label
        font-size: $medium-font-size

      &.focused
        label
          font-size: rem-calc(11)
          top: 0
          left: rem-calc(23)

    p
      margin: 0
