.corporate-membership
  display: flex
  padding: 80px
  gap: 40px
  justify-content: center
  background-color: #FAFAFA
  margin-top: 40px
  +breakpoint(small only)
    margin: 8px

  &__image
    height: 100%
    +breakpoint(medium down)
      display: none

  &__content
    padding-top: 10px
    padding-left: 40px
    +breakpoint(small only)
      padding-left: 0px

  &__tittle
    font-size: 46px
    color: #06243C
    font-family: $font-stack-poppins
    font-weight: 600
    line-height: 55.2px
    letter-spacing: -0.03em
    max-width: 110px
    +breakpoint(small only)
      font-size: 25px
      max-width: none
      text-align: center

  &__paragraph
    padding-top: 15px
    padding-bottom: 15px
    color: #6F7889
    font-size: 16px
    font-family: $font-stack-poppins
    font-weight: 300
    line-height: 160%
    letter-spacing: -0.16px
    max-width: 614px
    +breakpoint(small only)
      font-size: 12px
      max-width: none
      text-align: center

  &__button-container
    +breakpoint(small only)
      text-align: center

  &__button
    padding: 18px 27px
    background-color: #ED6E56
    font-size: 18px
    display: inline-block
    color: #ffffff
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    width: 200px
    height: 60px

    +breakpoint(small only)
      padding: 14px 27px
      width: 120px
      height: 40px
      font-size: 12px

  &__button:hover
    opacity: 0.75
    color: #ffffff

  &__button:focus
    background-color: #ED6E56 !important
    color: #ffffff !important
