.p-programmes__show, .p-areas
  .programme-header
    +background-image(no-repeat, cover, center, top)
    height: $g8*63
    width: 100vw

    +breakpoint(large down)
      height: $g8*29

    &:before
      +background-image(no-repeat, cover, center, center)
      background-image: url('../../images/backgrounds/grid-background.svg')
      bottom: 0
      content: ''
      display: block
      height: $g8*63
      left: 0
      position: absolute
      right: 0
      top: 0

      +breakpoint(small only)
        background-image: url('../../images/backgrounds/grid-background-small.svg')
        height: $g8*30

  .programme-information
    background-color: $white
    padding: 0
    position: relative
    top: 0

    +breakpoint(large down)
      top: auto

    &__graphics
      height: $g8*5.5
      width: 100%
