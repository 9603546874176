.standard-card-link
  color: $primary-color
  cursor: pointer
  display: block

  .standard-card
    font-family: $font-stack-poppins
    font-style: normal

    &__background
      +background-image(no-repeat, cover, center, center)
      background-image: url('../../images/backgrounds/standard_card-bg.jpg')
      height: $g8*22.5
      overflow: hidden
      position: relative

    &__date
      color: $black-3 !important
      font-size: $small-font-size
      font-weight: 300
      padding: $g8-3x $g8-2x 0 $g8-2x

    &__title
      color: $black-3
      font-weight: 600
      line-height: 120%
      min-height: $g8*13
      padding: $g8-2x

    &__decorator
      border-radius: 100%
      border: 2px solid $white
      height: rem-calc(68)
      position: absolute
      right: $g8-3x
      top: 30%
      width: rem-calc(68)

      &:before
        @include standard-card-decorator
        top: -130%

      &:after
        @include standard-card-decorator
        top: 130%

    &:hover
      .standard-card__decorator:before
        @include standard-card-decorator-hover-state

    &:hover
      .standard-card__decorator:after
        @include standard-card-decorator-hover-state
