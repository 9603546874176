.date-selector
  &__month, &__year
    .input-container
      padding-left: $g8

      +breakpoint(large)
        padding-left: $g8-half

      +breakpoint(xlarge)
        padding-left: $g8

  &__month, &__day
    .input-container
      padding-right: $g8

      +breakpoint(large)
        padding-right: $g8-half

      +breakpoint(xlarge)
        padding-right: $g8
