.home-events
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: $g8-10x 0
  +breakpoint(small only)
    padding: 0 0 50px 0 !important
  h3
    text-align: center
    font-style: normal
    font-weight: 600
    font-size: 46px
    line-height: 120%
    letter-spacing: -0.03em
    color: #06243C
  p
    text-align: center
    font-size: 18px
    font-weight: 500
    letter-spacing: -0.03em
    color: #6F7889

  .event-button
    display: flex
    align-items: center
    margin-top: 50px
    height: 64px
    border: 1px solid #ED6E56
    border-radius: 8px
    padding-left: 30px
    padding-right: 30px
    color: #ED6E56
    background: transparent url('./../../images/icons/arrow_right_discover.svg') no-repeat right
    background-position: 90% 50%

  .grid-container
    max-width: 98rem !important

    .cell

      .standard-carousel

        & .swiper-container

          width: 100vw !important

          & .swiper-slide
            display: flex
            flex-direction: column
            align-items: center

          & .swiper-pagination
            width: 100vw !important
            justify-content: center
            padding: 0
            position: absolute
            top: inherit
            bottom: 0
            +breakpoint(medium down)
              position: relative

.events-section
  display: flex
  flex-direction: row
  padding-top: 10px

.event-card
  width: 300px
  height: 371px
  padding-bottom: 25px
  margin: 10px
  border-radius: 14px
  box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)

  &-wrapper
    +breakpoint(medium)
      width: 100%
      display: flex
      flex-wrap: wrap
      flex-direction: row
      flex-wrap: wrap
      justify-content: center

  .card-top
    height: 190px
    //background: url('./../../images/backgrounds/shield_pattern.png')
    background-size: cover
    border-radius: 14px 14px 0px 0px
    padding-left: 20px
    padding-right: 20px
    padding-top: 10px
    & p
      text-transform: uppercase
      color: #06243C
      font-weight: 600
      font-size: 14px
      margin: 0
      letter-spacing: 0.25rem
    & h6
      padding-top: 0
      font-weight: 600
      font-size: 23px
      margin: 0

.event-svg
  +background-image(no-repeat, contain, center, center)
  margin: 7px auto
  height: 46px
  width: 46px
  border-radius: 50%
  background: #ED6E56 url('./../../images/icons/event.svg') no-repeat center

.event-border
  width: 60px
  height: 60px
  border-radius: 50%
  background: rgba(237, 110, 86, .25)

.card-bottom
  display: flex
  flex-direction: column
  justify-content: flex-start
  height: 50%
  padding-left: 20px
  padding-right: 20px

  & h6
    margin-top: 20px
    font-size: 16px
    color: #ED6E56
    font-weight: 500

  p.title
    margin: 0
    text-align: left
    font-weight: 500
    font-size: 16px
    letter-spacing: -0.03rem
    color: #6F7889

  p.blurb
    color: #06243C
    font-size: 18px
    font-weight: 500
    line-height: 120%
    text-align: left

.flex-row
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
.events section
  padding-top: 100px
  padding-bottom: 100px
  & .scroller--dark
      background: inherit
      & .scroller__arrows
          background: inherit
      & .scroller__title
          color: $white
          &::after
              width: 80%
              border: none
  & .scroller__arrows--left
      height: 50px
      width: 50px
  & .scroller__arrows--right
      height: 50px
      width: 50px
.events-show__header-wrap
  padding-top: 100px
