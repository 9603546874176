.designations
  &__container
    background-color: #FAFAFA

  &__testimonial-container
    background-color: #EFF0F1
    width: 100%

.p-designations
  .page-wrapper
    top: 0 !important

  .top-bar
    background-color: $white

    +breakpoint(large)
      background-color: transparent

  .primary-navigation,
  .primary-navigation-trigger,
  .footer
    display: none

  .logo
    background-image: url('./../../images/logos/logo-color.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-white.svg') !important

  .logo-secondary
    background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

    +breakpoint(large)
      background-image: url('./../../images/logos/logo-ucd-grey.svg') !important

  .sign-up-feature
    background:  url('../../images/backgrounds/signup-background.jpg')
    +background-image(no-repeat, cover, left, center)
    height: 100vh

  .scrollable
    height: 100vh
    overflow-y: scroll

  &__form
    .padding-y-nav-height
      padding-top: $g8*13

  .personal-tab, .place-of-work-tab, .direct-debit-tab
    &__toggle
      .toggle-message
        display: none !important

.designations-purchase-flow
  &__action
    margin-top: 2rem

  &__text
    font-size: $small-font-size
    font-weight: 400
    line-height: 150%

    label
      font-size: $tiny-font-size !important
      font-weight: 400 !important
      line-height: 150% !important

    label.boolean
      padding-left: 2rem !important

    .input-container
      position: relative
      margin-bottom: 1em

    &.focused:not(.file-field)
      label
        font-size: 0.7em
        min-height: 1.1rem
        left: 0rem !important

    a
      font-size: $tiny-font-size
      font-weight: 400
      line-height: 100%
      color: #23297a
      display: contents

    input[type=checkbox] + label:after
      left: 0.3rem !important
      top: 0.2rem !important

    input[type=checkbox] + label:before
      height: 1.5rem !important
      width: 1.5rem !important

  &__header
    font-size: $medium-font-size
    font-weight: 600
    line-height: 160%

  &__header-lighter
    font-size: $default-font-size
    font-weight: 300
    line-height: 180%
    letter-spacing: -0.02em
