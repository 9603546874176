.product-header
  margin-top: 5rem
  padding: 65px 65px
  border-radius: 0px 0px 100px 0px
  background-position: left 0% bottom 50%
  background-size: cover
  +breakpoint(small only)
    padding: 30px
  & h6
    padding-top: 60px
    font-weight: 600
    color: white
    font-size: 24px
    text-shadow: 0px 1px 5px rgba(6, 36, 60, 0.3), 0px 10px 10px rgba(6, 36, 60, 0.07)
    +breakpoint(small only)
      padding-top: 30px
  & h4
    text-shadow: 0px 1px 5px rgba(6, 36, 60, 0.3), 0px 10px 10px rgba(6, 36, 60, 0.07)
    padding-top: 0px
    padding-right: 20px
    margin: 0px
    font-weight: 600
    color: white
    font-size: 58px
    margin-bottom: -16px
    +breakpoint(medium only)
      font-size: 40px
    +breakpoint(small only)
      font-size: 33px

  .header-tags
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-content: flex-start
    & div
      background: #ED6E56
      color: white
      padding: 10px 20px
      border-radius: 100px
      margin-right: 10px
      margin-bottom: 10px

  .header-content
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: flex-end
    +breakpoint(medium down only)
      align-items: flex-start
    & .cta-h6
      letter-spacing: -0.03em
      text-shadow: none
      color: #06243C
      padding-top: 0px
      margin-bottom: 20px
    & .cta-buttons
      & .button-row
        display: flex
        flex-direction: row
        +breakpoint(small only)
          display: flex
          flex-direction: row
          flex-wrap: wrap
      a
        letter-spacing: -0.03em
        color: #ED6E57
        display: flex
        justify-content: center
        height: 50px
        border-radius: 8px
        width: 217.5px
        +breakpoint(large down only)
          width: 100%

        &:last-child
          border: 1px solid #ED6E57
          align-items: center

      .arrow
        margin-left: 15px
        background-repeat: no-repeat
        width: 10px
        height: 10px

      .icon-arrow
        background-image: url('./../../images/icons/arrow_right.svg')

      .icon-arrow-color
        background-image: url('./../../images/icons/arrow_right_color.svg')

      .icon-download
        margin-right: 15px
        background-image: url('./../../images/icons/download_color.svg')
        background-repeat: no-repeat
        width: 20px
        height: 20px
        background-size: cover

      .apply
        color: white
        background-color: #ED6E57
        background-repeat: no-repeat
        align-items: center
        margin-right: 40px
        +breakpoint(large down only)
          margin-bottom: 15px
          margin-right: 0

      +breakpoint(large down only)
        flex-direction: column
      +breakpoint(medium down only)
        flex-direction: row
      +breakpoint(small down only)
        flex-direction: column

    +breakpoint(medium down only)
      flex-direction: column

  .cta-header
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, #FFFFFF 100%)
    border-radius: 14px 14px 50px 14px
    box-shadow: 0px 3px 4px rgba(15, 37, 59, 0.03), 0px 5px 20px rgba(15, 37, 59, 0.03), 0px 10px 50px rgba(14, 37, 59, 0.03)
    padding: 40px
    +breakpoint(medium down only)
      margin-top: 50px
      width: 100%
	
