.glossary-refine-by
  display: flex
  justify-content: center
  align-items: center
  gap: 10px
  margin: 30px 0px 30px 0px
  +breakpoint(medium down)
    margin: 0px 10px 20px 10px
    flex-direction: column

  &__title
    color: #6F7889
    text-align: center
    font-family: $font-stack-poppins
    font-size: 16px
    font-style: normal
    font-weight: 300
    line-height: 160%
    +breakpoint(medium down)
      font-size: 14px

  &__navigation
    display: flex
    justify-content: center
    align-items: center
    gap: 17px
    +breakpoint(medium down)
      width: 100%
      flex-wrap: wrap
    +breakpoint(small only)
      gap: 8px

    &-item
      text-align: center
      padding: 0px 3.5px 0px 3.5px
      font-family: $font-stack-poppins
      font-size: 16px
      font-style: normal
      line-height: 160%
      +breakpoint(medium down)
        font-size: 14px
        letter-spacing: -0.14px

      &--active
        @extend .glossary-refine-by__navigation-item
        color: map-get($iob-color-palette, navy)
        font-weight: 500
        &:hover
          font-weight: 600

      &--inactive
        @extend .glossary-refine-by__navigation-item
        color: #6F7889
        font-weight: 300
        pointer-events: none
        cursor: default

      &--active-tab
        color: map-get($iob-color-palette, orange) !important
        font-weight: 600
        border-bottom: 4px solid map-get($iob-color-palette, orange) !important
