.apply-now
  margin: 0 auto
  max-width: $g8*54

.express-interest
  margin: 0 auto
  padding: $g8-8x $g8-2x $g8-4x $g8-2x

  +breakpoint(large)
    padding: $g8-8x $g8-6x $g8-4x $g8-6x

  &__errors:empty
   display: none

  &__errors
    background: map-get($accent-colors, pale-red)
    border: 1px solid $input-error-color
    color: $input-error-color
    margin-bottom: $g8-4x
    padding: $g8-2x
    width: 100%

.apply-now
  padding: $g8-8x $g8-2x

  +breakpoint(medium)
    text-align: center

  .button
    +breakpoint(small only)
      max-width: $g8*28
