.popular-categories
  padding-top: $g8-10x
  padding-bottom: $g8-10x
  background-color: #F2F3F5
  h4
    text-align: center
    font-style: normal
    font-weight: 600
    font-size: 46px
    line-height: 120%
    letter-spacing: -0.03em
    color: #06243C
    padding-bottom: 30px
  h6
    font-style: normal
    font-weight: 600
    font-size: 16px
    letter-spacing: -0.03em
    color: #06243C
    margin: 0
  p
    margin: 0
    font-size: 12px
    color: #6F7889
    font-weight: 400

  & .cell
    width: 100vw !important

    & .standard-carousel
      display: block

      & .swiper-container

        & .swiper-wrapper

          & .swiper-slide
            display: flex
            flex-direction: column
            align-items: center

      & .swiper-pagination
        bottom: rem-calc(10)
        justify-content: center
        padding: 0
        position: absolute
        top: inherit
