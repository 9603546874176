.back-button
  cursor: pointer
  color: $darkest-gray

  &:hover
    text-decoration: underline

.button[type="submit"]
  width: auto

.button
  +button-default

  &--disabled
    opacity: 0.5
    cursor: not-allowed
    pointer-events: none

  &__primary
    &--small
      +primary-button-small

    &--regular
      +primary-button-regular

  &__secondary
    &--small
      +secondary-button-small

    &--regular
      +secondary-button-regular

  &__text-only
    background: none

    &.blue
      color: $primary-color

      &:hover
        background: none
        color: $secondary-color

  &__filter
    &--small
      position: relative
      border-radius: 100px
      margin-right: 15px
      padding: 10px 20px
      // +button-filter-default

      &.is-active
        background-color: rgba(237, 110, 86, 0.15)
        color: #ED6E56
        // +button-filter-corner

        &:hover,
        &:focus
          background-color: rgba(237, 110, 86, 0.15)
          color: #ED6E56

  &__express-interest
    padding: 18px 27px
    background-color: #ED6E56
    font-size: 18px
    display: inline-block
    color: #ffffff
    letter-spacing: -0.03em
    line-height: 120%
    border-radius: 6.2px
    text-align: center
    letter-spacing: -0.01em
    white-space: nowrap
    height: 60px

    &:hover,
    &:focus
      background: #ED6E56
      color: $white

  &--blue
    background-color: $primary-color
    color: $white

    &:hover,
    &:focus
      background: $primary-color
      color: $white

  &--orange
    background-color: $secondary-color
    color: $white

    &:hover,
    &:focus
      background: $secondary-color
      color: $white

  &--strawberry
    background-color: map-get($iob-color-palette, strawberry)
    color: $white

    &:hover,
    &:focus
      background: map-get($iob-color-palette, strawberry)
      color: $white

  &--alert
    background-color: $alert-color
    color: $white

    &:hover,
    &:focus
      background: $alert-color
      color: $white

  &--gray
    background-color: $light-gray
    color: $primary-color

    &:hover,
    &:focus
      background: $light-gray
      color: $primary-color

  &--blue-gray
    background-color: $light-primary
    color: $white

    &:hover,
    &:focus
      background: $light-primary
      color: $white

  &--light
    background-color: $light-gray
    color: $gray

    &:hover
      background-color: rgba(237, 110, 86, 0.15)
      color: $gray

    &:focus
      background-color: $light-gray
      color: $gray

  &--dark
    background-color: $gray
    color: $dark-gray

    &:hover,
    &:focus
      background-color: $gray
      color: $dark-gray
