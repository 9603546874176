.b-safari
  .searchable-items
    overflow-y: scroll

    &::-webkit-scrollbar
      width: $g8

    &::-webkit-scrollbar-track
      background: $medium-gray

    &::-webkit-scrollbar-corner
      background-color: $medium-gray

    &::-webkit-scrollbar-thumb
      background: $secondary-color

  .paginated-scroller
    &__arrows
      .disabled
        opacity: 0.5
        filter: grayscale(1)

      &--left
        background-image: url('./../../images/icons/paginated-scroller-orange-left.svg')

        +breakpoint(xlarge)
          &:hover:not(.disabled)
            background-image: url('./../../images/icons/paginated-scroller-hover-left.svg')

      &--right
        background-image: url('./../../images/icons/paginated-scroller-orange-left.svg')
        transform: rotate(-180deg)

        +breakpoint(xlarge)
          &:hover:not(.disabled)
            background-image: url('./../../images/icons/paginated-scroller-hover-right.svg')
            transform: rotate(-180deg)
