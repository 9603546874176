.ucd_details
  .ucd-intro
    p
      font-size: $tiny-font-size

      +breakpoint(medium)
        padding-left: $g8-2x
        padding-right: $g8-5x

    img
      max-width: $g8-6x
      height: auto

  &__toggle
    margin: $g8-4x 0 $g8-2x 0
