.search-result-card
  margin: $g8-7x 0
  padding: 0 $g8-2x

  &__title
    +breakpoint(medium down)
      display: inline-block
      padding-bottom: $g8-2x
      margin-bottom: $g8-4x

      &.programmes
        border-bottom: $g8 solid map-get($accent-colors, pink)

      &.info
        border-bottom: $g8 solid map-get($accent-colors, orange)

      &.news
        border-bottom: $g8 solid map-get($accent-colors, green)

      &.events
        border-bottom: $g8 solid map-get($accent-colors, purple)

  +breakpoint(large)
    padding-left: $g8-8x

    &--programmes
      +pseudo-heading-bar(map-get($accent-colors, pink))

    &--info
      +pseudo-heading-bar(map-get($accent-colors, orange))

    &--news
      +pseudo-heading-bar(map-get($accent-colors, green))

    &--events
      +pseudo-heading-bar(map-get($accent-colors, purple))
