.b-ms-edge,
  .paginated-scroller
    &__arrows
      .disabled
        opacity: 0.5
        filter: grayscale(1)

      &--left,
      &--right
        background-size: 0.9rem 1.4rem

        &:hover:not(.disabled)
          transition-delay: 0.1s

      &--left
        background-image: url('./../../images/icons/paginated-scroller-orange-left.svg')

        &:hover:not(.disabled)
          filter: grayscale(1)
          transform: scale3d(1.3, 1.3, 1.3)

      &--right
        background-image: url('./../../images/icons/paginated-scroller-orange-right.svg')
        transform: rotate(-180deg)

        &:hover:not(.disabled)
          filter: grayscale(1)
          transform: scale3d(1.3, 1.3, 1.3) rotate(-180deg)
