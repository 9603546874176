.p-events__show
  .events-show
    &__tabs
      background-color: $black-3
      border: none
      margin-bottom: $g8-6x

      +breakpoint(small only)
        background-color: $black-3
        position: relative
        width: 100vw

      .tabs
        background-color: $gray
        border: none
        display: flex
        justify-content: center
        margin-bottom: $g8-4x

        +breakpoint(large)
          display: initial

      .tabs-title
        outline: none

        +breakpoint(small only)
          padding: 0 5%

        +breakpoint(medium)
          padding: 0 $g8-7x 0 $g8-7x

        +breakpoint(large)
          padding: 0 $g8-7x 0 0

        &.is-active a
          border-top: 3px solid $secondary-color
          color: $white !important

        a
          background: none
          border-top: 3px solid transparent
          color: $darkest-gray
          font-family: $font-stack-poppins
          font-size: $medium-font-size
          font-weight: bold
          outline: none
          padding: $g8-3x 0

          &:hover
            text-decoration: none

          +breakpoint(large)
             color: $gray

      .tabs-content
        background-color: $black-3
        border-bottom: 1px solid $light-gray
        border: none
        color: $white

        +breakpoint(large)
          padding-bottom: $g8-5x
          padding-top: $g8-4x

        .tabs-panel
          padding: $g8-3x

          +breakpoint(large)
            padding: 0 $g8-3x 0 0

        ul
          +unordered-list-orange
          margin-bottom: $g8-5x

        .speaker

          +breakpoint(medium)
            padding-left: $g8-3x

          img
            height: auto
            width: 100%

      .events-about-tab,
      .events-cpdhours-tab,
      .events-speakers-tab
        h1, h2, h3, h4, h6
          font-family: $font-stack-poppins
          font-weight: normal

        h5
          font-family: $font-stack-poppins
          font-weight: normal

        ul
          +unordered-list-orange

        ol
          +ordered-list-orange

      .events-speakers-tab
        &__speaker-title
          font-family: $font-stack-poppins
