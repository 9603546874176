.banner-with-link
  position: relative
  font-family: $font-stack-poppins

  &__wrapper
    padding-top: 80px

  &__header-banner
    height: 20.625rem
    background-image: url('//images.ctfassets.net/19hllh2ssa3x/6IFLeTe85oDKQKfc6CbtnP/77e5c96e62f87c76b6fcadd9a1414339/pattern_orange.svg')
    border-radius: 0 0 100px 0px
    background-position: left 0% bottom 45%
    background-size: cover
    padding: 16rem 10rem 10rem
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    +breakpoint(small only)
      background-size: auto
      background-position: left 47.5% bottom 65%
      padding: 10rem
      height: 34.5rem
      flex-direction: column

    &__no-link
      +breakpoint(small only)
        height: 29.5rem

  &__header-text
    font-size: 58px
    vertical-align: middle
    color: white
    font-weight: 600
    white-space: nowrap
    flex-wrap: nowrap

    +breakpoint(small only)
      font-size: 38px

  &__marketing_box
    background-color: #FFFFFF
    width: 466px
    height: 235px
    flex-wrap: nowrap
    border-radius: 14px 14px 50px 14px
    gap: 20px
    display: flex
    flex-direction: column
    padding: 40px

    +breakpoint(small only)
      width: 400px

    &__no-link
      height: 160px

    &__heading
      font-size: 24px
      font-weight: 600
      line-height: 28.8px
      letter-spacing: -0.03em
      color: #06243C

    &__text
      font-size: $medium-font-size
      font-weight: 300
      line-height: 25.6px
      letter-spacing: -0.01em
      color: #06243C

    &__button
      width: 257.5px
      height: 60px
      display: flex
      flex-direction: row
      align-items: center
      padding: 20px 60px
      left: 0px
      background: #ED6E56 url('./../../images/icons/arrow_right.svg') no-repeat right
      background-position: 70% 53%
      border-radius: 7px
      color: #ffffff
      margin-top: 20px
