.p-programmes-reregistrations,
.p-events__registration
  .sign-up-feature
    height: 100vh
    background: linear-gradient(0deg, rgba(14, 24, 40, 0.5), rgba(14, 24, 40, 0.5)), url('../../images/backgrounds/application-cancellation-background.jpg')
    +background-image(no-repeat, cover, left, center)

    +breakpoint(large)
      &:before
        +background-image(no-repeat, cover, left, center)
        background-image: url('../../images/backgrounds/grid-background-hero.svg')
        bottom: 0
        content: ''
        display: block
        height: 100vh
        left: 0
        opacity: 0.4
        position: absolute
        right: 0
        width: 50%

  .add-attendant
    +breakpoint(large)
      padding-left: $g8-3x

    &__error
      color: $alert-color

  .cancel-attendance
    margin-right: $g8-2x
    background-color: $white
