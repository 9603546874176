.p-confirmations
  .top-bar
    +breakpoint(large up)
      .logo-secondary
        background-image: url('./../../images/logos/logo-ucd-white.svg')!important

  .sign-up-feature
    height: 50vh

    +breakpoint(large)
      height: 100vh

    &.application-success,
    &.enrolment-success,
    &.member-success
      &:before
        +background-image(no-repeat, cover, left, center)
        bottom: 0
        content: ''
        display: block
        left: 0
        position: absolute
        right: 0

        +breakpoint(small)
          background-image: url('../../images/backgrounds/grid-background-hero-small.svg')
          height: 50vh

        +breakpoint(large)
          background-image: url('../../images/backgrounds/grid-background-hero.svg')
          height: 100vh
          width: 50%

    &.application-success,
    &.enrolment-success
      .sign-up-feature__content
        margin: 0 0 15% $g8-2x

        +breakpoint(large)
          margin: 0 0 rem-calc(150) 0

    &.member-success
      .sign-up-feature__content
        margin: 0 0 5% $g8-2x

        +breakpoint(large)
          margin: 0 0 rem-calc(150) 0

    &.sign-up-success
      +background-image(no-repeat, cover, left, center)
      background-image: url('../../images/backgrounds/signup-success.jpg')
      position: relative

      &:after
        +background-image(no-repeat, cover, center, center)
        background-image:  url('../../images/backgrounds/grid-background-hero.svg')
        bottom: 0
        content: ''
        left: 0
        position: absolute
        right: 0
        top: 0

      h1
        padding: 0 0 20% 20%
        z-index: 999

    &.application-success,
    &.enrolment-success
      background:  url('../../images/backgrounds/application-success-background.jpg') left center /cover

    &.member-success
      background:  url('../../images/backgrounds/member-success-background.jpg') left center /cover

    &.application-cancellation
      background:  url('../../images/backgrounds/application-cancellation-background.jpg') left center /cover
      position: relative

      &:after
        @extend .sign-up-success, :after

    &__content
      h1
        hyphens: auto
        line-height: 120%
        margin-bottom: 0

      h3
        margin: $g8-7x 0 $g8-3x 0

    &__decoration
      +background-image(no-repeat, cover, left, center)
      background-image: url('./../../images/backgrounds/modal-decorator-mobile.svg')
      height: $g8-6x
      position: absolute
      top: $g8-10x
      width: 100%

      +breakpoint(large)
        background-image: url('./../../images/backgrounds/success-side-banner.svg')
        background-position: top center
        height: 100%
        right: 0
        top: 0
        width: $g8-6x

  .scrollable
    +breakpoint(large)
      height: 100vh

    .padding-y-nav-height
      padding-top: $g8-8x
      padding-bottom: $g8-4x

    &__content
      margin-top: $g8*15

      h2
        font-weight: normal
        line-height: 120%

      blockquote
        +blockquote-success
        &.application-success,
        &.enrolment-success,
        &.member-success
          &__blockquote
            margin-bottom: $g8-6x
            margin-top: $g8-6x

        &.member-success__blockquote
          margin-top: $g8-4x

        &.application-success
          &__blockquote
            a
              display: inline-block
              font-size: rem-calc(20)

        .application-success__paragraph
          display: inline

    .sign-up-success
      &__title
        margin-bottom: $g8-3x

      &__sub-title
        margin: $g8-4x 0

        +breakpoint(large)
          margin: $g8-8x 0 $g8-6x 0

    .application-success,
      &__title
        margin-bottom: $g8-6x

        +breakpoint(large)
          margin-bottom: $g8-4x

    .enrolment-success
      &__title
        margin-bottom: $g8-8x

    .member-success
      &__title
        margin-bottom: $g8-5x

      &__events-link
        display: block
        font-weight: 600
        margin-top: $g8-5x
        text-decoration: underline

  .footer
    display: none

  .cancel-application
    font-size: $tiny-font-size
