.membership-card
  box-shadow: $card-box-shadow
  box-sizing: border-box
  color: $black-3
  display: inline-flex
  flex-direction: column
  height: $g8*37.5
  padding-top: $g8-6x
  position: relative
  width: 100%

  &__graphic
    +background-image(no-repeat, cover, center, center)
    background-image: url('../../images/backgrounds/module-card-decorator-01.svg')
    height: $g8-6x
    left: 0
    position: absolute
    right: 0
    top: 0

    &--members
      @extend .membership-card__graphic
      background-image: url('../../images/backgrounds/user-membership-decorator.svg')
      +background-image(no-repeat, cover, center, center)

  &__details
    align-items: flex-start
    display: flex
    flex-direction: column
    flex: 1
    justify-content: center
    padding: $g8-3x

    img
      height: auto
      margin: $g8-2x 0
      width: $g8*20

    &-title
      margin: $g8 0

      h4
        margin: 0

    &-member-number
      font-style: normal
      font-weight: 300
      font-size: $small-font-size
      line-height: 120%
      letter-spacing: $letter-spacing
      text-transform: uppercase

    &-expiry
      font-size: $small-font-size

    &-status
      font-size: $tiny-font-size

      .status
        color: $gray
        font-weight: 700

      .unpaid
        @extend .status
        color: $alert-color

  &__actions
    align-items: center
    display: flex
    height: auto
    justify-content: space-between
    padding: $g8-3x
    position: relative

    &:before
      background-color: $light-gray
      content: ''
      height: 1px
      left: 0
      margin: 0 auto
      position: absolute
      right: 0
      top: 0
      width: 84%

    &:empty
      display: none

    a
      margin: 0

    &-category
      color: $gray
      display: flex
      font-size: $small-font-size
      font-weight: normal
      width: 100%

    &--disabled
      font-size: $tiny-font-size

.p-users-dashboard__index
  .membership-card__details
    align-items: center

    +breakpoint(medium)
     align-items: flex-start

  .membership-card__actions-category
    justify-content: center

    +breakpoint(medium)
      justify-content: flex-start
