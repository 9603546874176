.about-card
  padding: $g8-2x 0
  text-align: center
  font-family: $font-stack-poppins

  &:not(:last-child)
    margin-bottom: $g8-5x

  @include breakpoint(medium)
    padding: $g8-4x
    width: 50%

    &:not(:last-child)
     margin-bottom: 0

  @include breakpoint(large)
    width: 25%

  &-wrapper
    +breakpoint(medium)
      width: 100%
      display: flex
      flex-wrap: wrap

  img
    height: 120px
    width: 120px

  &__heading
    color: $black
    font-size: 24px
    font-style: normal
    font-weight: 600
    line-height: 120%
    margin: rem-calc(20) 0
    padding: 0 10px

  &__intro
    font-family: $font-stack-poppins
    font-size: 16px
    font-weight: 400
    line-height: rem-calc(26)
    padding: 0 $g8-2x
