.cpd-record-event
  &__status
    border-top: rem-calc(5) solid $alert-color

    &.success
      border-top: rem-calc(5) solid $success-color

    &.hidden
      display: none

  &__declaration
    padding-bottom: 5px

    &-header
      +orange-heading-underline
      margin-bottom: $g8-4x

    &-text
      color: black
      font-size: 14px
      color: #06243C
      background-color: $lightest-gray
      height: 9rem
      overflow-y: scroll
      padding: 1rem 2.5rem
      margin-bottom: $g8-2x
      scroll-behavior: smooth
      scrollbar-color: $secondary-color $medium-gray
      text-align: left
      &::-webkit-scrollbar
        width: $g8
      &::-webkit-scrollbar-track
        background: $medium-gray
      &::-webkit-scrollbar-thumb
        background: $secondary-color
      &::-webkit-scrollbar-corner
        background-color: $medium-gray

    &-verify
      display: flex
      padding-bottom: $g8-2x
